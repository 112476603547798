import { Component, Inject, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ScheduleMergeService } from 'app/service/schedule-merge.service';
import { throttleTime } from 'rxjs/operators';
import { Color } from 'app/module/custom-component/color-picker/entity/color';

@Component({
  selector: 'app-info-text-highlighting',
  templateUrl: './info-text-highlighting.component.html',
  styleUrls: ['./info-text-highlighting.component.scss']
})
/**
 * Dialog Pick color
 */
export class InfoTextHighlightingSettingComponent implements OnInit {
  titleName = '';
  // data = {titleName: 1}
  @Input() data: any;
  @Input() lastRow: boolean;
  @Output() onUpdate: EventEmitter<any> = new EventEmitter(true);

  dataForm: FormGroup;
  constructor(private fb: FormBuilder, private scheduleMergeService: ScheduleMergeService) {}

  ngOnInit() {
    this.dataForm = this.fb.group({
      titleName: [this.data.titleName],
      name: [this.data.name],
      object: [this.data.object],
      color: [this.data.color],
      opacity: [this.data.opacity],
      blinking: [this.data.blinking],
      isActive: [this.data.isActive],
      readOnlyName: [this.data.readOnlyName]
    });
    this.scheduleMergeService.actionUpdateColor //subscribe from change color by canvas
      .subscribe(data => {
        if (data.titleName == this.data.titleName && this.f.color != data.color) {
          this.dataForm.controls.color.setValue(data.color);
          const color = Color.fromHex(data.color);
          this.dataForm.controls.opacity.setValue(color.a);
          // this.f.opacity = color.a;
          this.onUpdate.emit(this.f);
        }
      });
    this.dataForm.valueChanges.subscribe(value => {
      //if change data to change color in canvas
      this.onChangeData();
    });
  }
  get f() {
    return this.dataForm.value;
  }
  onChangeData() {
    this.scheduleMergeService.updateColor(this.f);
    this.onUpdate.emit(this.f);
  }
}
