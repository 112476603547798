import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CustomTagElement } from 'app/model/entity/custom-tag-element';
import { CustomTag } from '../../model/entity/custom-tag';
import { CustomTagService } from '../../service/custom-tag.service';
import { DialogService } from '../../service/dialog.service';
import { DialogMessageComponent } from '../dialog-message/dialog-message.component';
import _ from 'lodash';
import { APICustomerService } from 'app/service/api-customer.service';
import { Constant } from 'app/config/constants';
import { Device } from 'app/model/entity/device';
import { forkJoin } from 'rxjs';
import { Helper } from 'app/common/helper';

@Component({
  selector: 'app-dialog-edit-group',
  templateUrl: './dialog-edit-group.component.html',
  styleUrls: ['./dialog-edit-group.component.scss']
})
export class DialogEditGroupComponent implements OnInit {
  /**
   * list element
   */
  listElement: Array<CustomTagElement> = new Array<CustomTagElement>();
  /**
   * name of custom tag
   */
  nameCustomTag: string = '';
  /**
   * name of element
   */
  nameElement: string;
  /**
   * true if edit custom tag
   */
  isEdit: boolean = false;
  /**
   * custom tag editing
   */
  customTagEdit: CustomTag;
  /**
   * index of custom tag edit
   */
  indexElementEdit: number;
  /**
   * true if edit element
   */
  isEditElement: boolean = false;

  /**
   * ElementRef element
   */
  @ViewChild('element') private elementRef: ElementRef;
  /**
   * ElementRef custom tag name
   */
  @ViewChild('customTag') private elementRefCustomTag: ElementRef;

  indexCustomTagEdit: number;

  /**
   * list all device
   */
  private devices: Array<Device>;

  /**
   * accountId
   */
  private accountId: any;

  /**
   * constants
   */
  Constant = Constant;

  constructor(
    private dialogRef: MatDialogRef<DialogEditGroupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private customTagService: CustomTagService,
    private dialogService: DialogService,
    private apiCustomerService: APICustomerService
  ) {}

  ngOnInit(): void {
    /**
     * if edit custom tag
     */
    if (this.data?.customTag) {
      this.isEdit = true;
      this.nameCustomTag = this.data.customTag.name;
      this.listElement = _.cloneDeep(this.data.customTag.elements);
      this.customTagEdit = this.data.customTag;
      this.devices = this.data.devices;
      this.accountId = this.data.accountId;
    }
  }

  /**
   * select element custom tag
   * @param index
   */
  public selectElement(index: number): void {
    if (!this.isEditElement) {
      this.indexElementEdit = index;
    } else if (this.indexElementEdit != index) {
      this.elementRef.nativeElement.focus();
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: `Error`,
          text: `Please save element.`
        }
      });
    }
  }

  /**
   * add new element
   */
  public addNewElement(): void {
    if (!this.isEditElement && this.listElement.length < 10) {
      this.listElement.push(new CustomTagElement('', this.customTagEdit?.id));
      this.isEditElement = true;
      this.indexElementEdit = this.listElement.length - 1;
    }
  }

  /**
   * edit element
   */
  public editElement(): void {
    if (this.indexElementEdit == undefined) {
      this.elementRefCustomTag.nativeElement.focus();
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: `Error`,
          text: `Please select element.`
        }
      });
      return;
    }
    if (!this.isEditElement) {
      this.isEditElement = true;
      this.nameElement = this.listElement[this.indexElementEdit].name;
    }
  }
  /**
   * save element
   */
  public saveElement(): void {
    this.elementRef.nativeElement.blur();
    let elementName = this.nameElement?.trim();
    if (elementName.length == 0 || this.nameElement == undefined) {
      this.elementRef.nativeElement.focus();
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: `Error`,
          text: `Element name cannot be empty.`
        }
      });
      return;
    }
    if (elementName.length > 16) {
      this.elementRef.nativeElement.focus();
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: `Error`,
          text: `Element name must contain no more than 16 characters.`
        }
      });
      return;
    }

    let elements = this.listElement.filter((data, index) => index != this.indexElementEdit).map(element => element.name);
    if (elements.includes(elementName)) {
      this.elementRef.nativeElement.focus();
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: `Error`,
          text: `Element name already exists.`
        }
      });
      return;
    }

    // edit custom tag Signage_Group
    if (this.nameCustomTag == Constant.SIGNAGE_GROUP && this.isEdit) {
      // add group
      if (!this.listElement[this.indexElementEdit].groupId) {
        this.apiCustomerService.groupCreate(Helper.createPayloadGroup(elementName, this.accountId)).subscribe(
          data => {
            if (data) {
              this.listElement[this.indexElementEdit].name = this.nameElement.trim();
              this.listElement[this.indexElementEdit].groupId = data['group_id'];
              this.nameElement = '';
              this.isEditElement = false;
            }
          },
          () => {
            this.handleError('Group create failed.');
            return;
          }
        );
        // edit group
      } else {
        let elementSelected = this.listElement[this.indexElementEdit];
        // change name of element
        if (elementSelected.name != elementName) {
          let registrationIds = this.devices
            .filter(device => device?.customTag0?.id == elementSelected?.id && elementSelected?.id)
            .map(data => data.registrationId);

          // have devices set element
          let payloadDelete = Helper.createPayloadGroup(elementSelected.name, this.accountId);
          let payloadCreate = Helper.createPayloadGroup(elementName, this.accountId);
          if (registrationIds.length) {
            let payloadDeviceRemove = Helper.createPayloadGroupDevice(elementSelected.groupId, registrationIds);
            this.apiCustomerService.groupDeviceRemove(payloadDeviceRemove).subscribe(
              () => {
                forkJoin({
                  groupDelete: this.apiCustomerService.groupDelete(payloadDelete),
                  groupCreate: this.apiCustomerService.groupCreate(payloadCreate)
                }).subscribe(
                  data => {
                    if (data) {
                      let groupId = data.groupCreate['group_id'];
                      this.apiCustomerService.groupDeviceAdd(Helper.createPayloadGroupDevice(groupId, registrationIds)).subscribe(
                        () => {
                          this.listElement[this.indexElementEdit].groupId = groupId;
                          this.listElement[this.indexElementEdit].name = this.nameElement.trim();
                          this.nameElement = '';
                          this.isEditElement = false;
                        },
                        () => {
                          this.handleAfterAPICallError(payloadDelete, payloadCreate, payloadDeviceRemove, true);
                          this.handleError('Group device add failed.');
                        }
                      );
                    }
                  },
                  () => {
                    this.handleAfterAPICallError(payloadDelete, payloadCreate, payloadDeviceRemove, true);
                    this.handleError('An error has occurred. Please try again.');
                  }
                );
              },
              () => {
                this.handleError('Group device remove failed.');
                return;
              }
            );
            // no devices set element
          } else {
            forkJoin({
              groupDelete: this.apiCustomerService.groupDelete(payloadDelete),
              groupCreate: this.apiCustomerService.groupCreate(payloadCreate)
            }).subscribe(
              data => {
                if (data) {
                  this.listElement[this.indexElementEdit].groupId = data.groupCreate['group_id'];
                  this.listElement[this.indexElementEdit].name = this.nameElement.trim();
                  this.nameElement = '';
                  this.isEditElement = false;
                }
              },
              () => {
                this.handleAfterAPICallError(payloadDelete, payloadCreate);
                this.handleError('An error has occurred. Please try again.');
              }
            );
          }
        } else {
          this.nameElement = '';
          this.isEditElement = false;
        }
      }
    } else {
      this.listElement[this.indexElementEdit].name = this.nameElement.trim();
      this.nameElement = '';
      this.isEditElement = false;
    }
  }

  /**
   * delete element
   */
  async deleteElement(): Promise<any> {
    if (this.indexElementEdit == undefined) {
      this.elementRefCustomTag.nativeElement.focus();
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: `Error`,
          text: `Please select element.`
        }
      });
      return;
    }
    let elementSelected = this.listElement[this.indexElementEdit];
    if (this.data?.customTag?.name == Constant.SIGNAGE_GROUP && elementSelected.groupId && this.isEdit) {
      let registrationIds = this.devices
        .filter(device => device?.customTag0?.id == elementSelected?.id && elementSelected?.id)
        .map(data => data.registrationId);
      // have devices set element
      if (registrationIds.length) {
        let payloadDeviceRemove = Helper.createPayloadGroupDevice(elementSelected.groupId, registrationIds);
        this.apiCustomerService.groupDeviceRemove(payloadDeviceRemove).subscribe(
          () => {
            this.apiCustomerService.groupDelete(Helper.createPayloadGroup(elementSelected.name, this.accountId)).subscribe(
              () => {
                this.handleAfterDeleteElement();
              },
              () => {
                this.apiCustomerService.groupDeviceAdd(payloadDeviceRemove).toPromise();
                this.handleError('Group delete failed.');
                return;
              }
            );
          },
          () => {
            this.handleError('Group device remove failed.');
            return;
          }
        );
      } else {
        this.apiCustomerService.groupDelete(Helper.createPayloadGroup(elementSelected.name, this.accountId)).subscribe(
          () => {
            this.handleAfterDeleteElement();
          },
          () => {
            this.handleError('Group delete failed.');
            return;
          }
        );
      }
    } else {
      this.handleAfterDeleteElement();
    }
  }

  /**
   * handle after delete element
   */
  private handleAfterDeleteElement(): void {
    this.listElement = this.listElement.filter(data => data != this.listElement[this.indexElementEdit]);
    this.isEditElement = false;
    this.nameElement = '';
    this.elementRefCustomTag.nativeElement.focus();
    this.indexElementEdit = undefined;
  }

  /**
   * save custom tag
   */
  public save(): void {
    if (!this.isEdit && (!this.nameCustomTag || this.nameCustomTag == '') && this.listElement.length == 0) {
      this.dialogRef.close();
      return;
    }
    if (!this.isEdit && (!this.nameCustomTag || this.nameCustomTag == '') && this.listElement.length > 0) {
      this.elementRefCustomTag.nativeElement.focus();
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: `Error`,
          text: `Custom Tag name cannot be empty.`
        }
      });
      return;
    }
    if (this.isEditElement) {
      this.elementRef.nativeElement.focus();
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: `Error`,
          text: `Please save element.`
        }
      });
      return;
    }
    if (this.nameCustomTag?.trim().length == 0 || this.nameCustomTag == undefined) {
      this.elementRefCustomTag.nativeElement.focus();
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: `Error`,
          text: `Custom Tag name cannot be empty.`
        }
      });
      return;
    }
    if (this.nameCustomTag?.trim().length > 16) {
      this.elementRefCustomTag.nativeElement.focus();
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: `Error`,
          text: `Custom Tag name must contain no more than 16 characters.`
        }
      });
      return;
    }
    if (!this.isEdit) {
      let listIndexCustomTag: any = [];
      let indexCustomTag = [];
      this.customTagService.getCustomTag().subscribe(
        data => {
          if (data?.some(customTag => customTag.name === this.nameCustomTag.trim())) {
            this.dialogService.showDialog(DialogMessageComponent, {
              data: {
                title: `Error`,
                text: `Custom Tag name must be unique.`
              }
            });
            return;
          }
          data.forEach(element => {
            listIndexCustomTag.push(element.indexCustom);
          });
          indexCustomTag = [0, 1, 2].filter(x => listIndexCustomTag.indexOf(x) === -1);
          this.customTagService
            .addCustomTag(new CustomTag(this.nameCustomTag.trim(), indexCustomTag[0], this.listElement))
            .subscribe(() => {
              this.dialogRef.close();
            });
        },
        error => {
          this.dialogRef.close();
          this.handleError('An error has occurred. Please try again.');
        }
      );
    } else {
      if (
        this.data?.listCustomTag?.some(customTag => customTag.id !== this.customTagEdit.id && customTag.name === this.nameCustomTag.trim())
      ) {
        this.dialogService.showDialog(DialogMessageComponent, {
          data: {
            title: `Error`,
            text: `Custom Tag name must be unique.`
          }
        });
        return;
      }
      this.customTagEdit.name = this.nameCustomTag.trim();
      this.customTagEdit.elements = this.listElement;
      this.customTagService.editCustomTag(this.customTagEdit).subscribe(
        data => {
          this.dialogRef.close(data);
        },
        error => {
          this.dialogRef.close();
          this.handleError('An error has occurred. Please try again.');
        }
      );
    }
  }

  /**
   * handle error
   * @param msg message error
   */
  private handleError(msg: any): void {
    this.dialogService.showDialog(DialogMessageComponent, {
      data: {
        title: 'Error',
        text: msg
      }
    });
  }

  /**
   * handle after api call error
   * @param payloadCreate
   * @param payloadDelete
   * @param payloadDeviceAdd
   * @param isCallAPIDeviceAdd
   */
  private handleAfterAPICallError(payloadCreate: any, payloadDelete: any, payloadDeviceAdd?: any, isCallAPIDeviceAdd?: boolean): void {
    forkJoin({
      groupDelete: this.apiCustomerService.groupDelete(payloadDelete),
      groupCreate: this.apiCustomerService.groupCreate(payloadCreate)
    }).subscribe(
      () => {
        if (isCallAPIDeviceAdd) {
          this.apiCustomerService.groupDeviceAdd(payloadDeviceAdd).toPromise();
        }
      },
      () => {
        this.handleError('An error has occurred. Please try again.');
        return;
      }
    );
  }
}

/**
 * export dialog data
 */
export interface DialogData {
  customTag: CustomTag;
  listCustomTag: CustomTag[];
  devices: Array<Device>;
  accountId: any;
}
