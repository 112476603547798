<div id="myModal" class="modal modal-bus-stop-detail show"> <div class="modal-dialog modal-dialog-centered"> <div class="modal-content"> <div class="modal-header"> <div> <h4 class="modal-title pull-left">{{ title }}</h4> </div> <div class="pull-right" id="close" (click)="closeDialog()" style="cursor: pointer;"> <a href="javascript:void(0)" id="close-dialog">x</a> </div> </div> <div class="modal-body flex-container"> <div style="margin-right: 20px; width: 518px;"> <div> <span>Publish</span><span style="font-weight: bold;"> Signage Display Editor</span> <span class="pull-right">Total: {{ styles.length }} style(s)</span> </div> <div class="box-style-title" style="overflow-y: auto;
              white-space: nowrap; height: 235px;"> <div class="table-grid"> <div class="table-grid-head"> <div class="table-col w-100"><label>Style No.</label></div> <div class="table-col w-100"><label>Suffix</label></div> <div class="table-col w-120"><label>Style</label></div> <div class="table-col w-120"><label>Display-1</label></div> <div class="table-col w-120"><label>Display-2</label></div> </div> <div class="table-grid-body"> <div class="table-row" *ngFor="let style of styles"> <div class="table-col"> {{ style.styleNo }} </div> <div class="table-col"> {{ style.suffix }} </div> <div class="table-col"> {{ style.name }} </div> <div class="table-col"> <span *ngIf="
                        style?.displayTemplate1 &&
                        style.displayTemplate1?.idMainPage &&
                        (style.displayTemplate1?.idSubPage1 ||
                          style.displayTemplate1?.idSubPage2 ||
                          style.displayTemplate1?.idSubPage3 ||
                          style.displayTemplate1?.idSubPage4)
                      "> <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14.05 10.5" style="width: 19px;"> <g id="Layer_2" data-name="Layer 2"> <g id="Layer_3" data-name="Layer 3"> <rect x="0.5" y="3.67" width="9.4" height="6.33" fill="none" stroke="#000"/> <polygon points="4.15 0.5 4.15 3.67 9.9 3.67 9.9 6.83 13.55 6.83 13.55 0.5 4.15 0.5" fill="none" stroke="#000"/> </g> </g> </svg> </span> <span class="text-fix" *ngIf="style?.display1Templates && style.display1Templates[DestinationEnum.MAIN]">{{ style.display1Templates[DestinationEnum.MAIN].templateGroupName }}_{{ style.display1Templates[DestinationEnum.MAIN].name }} </span> <ng-template #other1> <span class="text-fix"></span> </ng-template> </div> <div class="table-col"> <span *ngIf="
                        style?.displayTemplate2 &&
                        style.displayTemplate2?.idMainPage &&
                        (style.displayTemplate2?.idSubPage1 ||
                          style.displayTemplate2?.idSubPage2 ||
                          style.displayTemplate2?.idSubPage3 ||
                          style.displayTemplate2?.idSubPage4)
                      "> <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14.05 10.5" style="width: 19px;"> <g id="Layer_2" data-name="Layer 2"> <g id="Layer_3" data-name="Layer 3"> <rect x="0.5" y="3.67" width="9.4" height="6.33" fill="none" stroke="#000"/> <polygon points="4.15 0.5 4.15 3.67 9.9 3.67 9.9 6.83 13.55 6.83 13.55 0.5 4.15 0.5" fill="none" stroke="#000"/> </g> </g> </svg> </span> <span class="text-fix" *ngIf="style?.display2Templates && style.display2Templates[DestinationEnum.MAIN]">{{ style.display2Templates[DestinationEnum.MAIN].templateGroupName }}_{{ style.display2Templates[DestinationEnum.MAIN].name }} </span> <ng-template #other> <span class="text-fix"></span> </ng-template> </div> </div> </div> </div> </div> </div> <div style="width: 374px;"> <div> <p style="font-size: 18px;padding-bottom: 30px;">Effective Date</p> </div> <div class="container" style="width: 100%;"> <div class="row"> <div class="col-sm-2" style="margin-left: -15px"> <p>From</p> </div> <div class="col-sm-8 pull-left" style="margin-left: 30px"> <div class="form-group"> <app-custom-date-picker [withTime]="false" [width]="256" [marginLeft]="-200" [currentDateTime]="dateFrom" [inDialog]="true" [hasBorder]="true" (currentDateChange)="this.dateFrom = $event"></app-custom-date-picker> </div> </div> </div> </div> <div> <p>Description</p> <textarea rows="4" cols="50" style="resize: none; width: 100%;" placeholder="Description" [(ngModel)]="description"></textarea> </div> <div class="row"> <div class="col-sm-4"> <p>Timestamp</p> </div> <div class="col-sm-6">YYYY/MM/DD HH:MM:SS</div> </div> <div class="row"> <div class="col-sm-4"> <p>Time Zone</p> </div> <div class="col-sm-8"> <span style="font-weight: bold;">{{ timeZone }}</span> </div> </div> <div> <div class="progress-line" [ngClass]="{ hidden: !loading }"></div> <div class="btn-publish"> <button class="btn btn-active-outline" (click)="publish()">Publish</button> </div> </div> </div> </div> </div> </div> </div> 