import { HttpEvent, HttpEventType } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Helper } from 'app/common/helper';
import {
  ActiveColumnHeader,
  Constant,
  DeviceDetailStatusEnum,
  DeviceStatusEnum,
  PayloadDeviceStatusEnum,
  StatusNumberObject
} from 'app/config/constants';
import { Common } from 'app/model/entity/common';
import { ContentDay } from 'app/model/entity/content-day';
import { DeliveryStatusTimetable } from 'app/model/entity/delivery-status-timetable';
import { DeviceCalendar } from 'app/model/entity/device-calendar';
import { PublishSetting } from 'app/model/entity/publish-setting';
import { GroupDevice } from 'app/model/entity/simple/group-device';
import { Timetable } from 'app/model/entity/timetable';
import { APICustomerService } from 'app/service/api-customer.service';
import { CommonTableService } from 'app/service/common-table.service';
import { CommonService } from 'app/service/common.service';
import { DataService } from 'app/service/data.service';
import { DeliveryStatusTimetableService } from 'app/service/delivery-status-timetable.service';
import { DevicePublishInfoService } from 'app/service/device-publish-info.service';
import { DeviceService } from 'app/service/device.service';
import { DialogService } from 'app/service/dialog.service';
import { ExecutingService } from 'app/service/executing.service';
import { IndexWordService } from 'app/service/index-word.service';
import { PublishTimetableService } from 'app/service/publish-timetable.service';
import { TimetableContentDayService } from 'app/service/timetable-content-day.service';
import { TimetableUpdateTimingService } from 'app/service/timetable-update-timing.service';
import { TimetableService } from 'app/service/timetable.service';
import _ from 'lodash';
import { forkJoin, interval, Subject } from 'rxjs';
import { concatMap, takeUntil } from 'rxjs/operators';
import { DialogConfirmComponent } from '../dialog-confirm/dialog-confirm.component';
import { DialogDownloadDataComponent } from '../dialog-download-data/dialog-download-data.component';
import { DialogMessageComponent } from '../dialog-message/dialog-message.component';
import { DialogSimpleSignageMessageComponent } from '../dialog-simple-signage-message/dialog-simple-signage-message.component';

@Component({
  selector: 'dialog-delivery-timetable',
  templateUrl: './dialog-delivery-timetable.component.html',
  styleUrls: ['./dialog-delivery-timetable.component.scss']
})
export class DialogDeliveryTimetableComponent implements OnInit {
  /**
   * true if all device checked
   */
  isCheckedAll: boolean;
  /**
   * DeviceStatusEnum
   */
  DeviceStatusEnum = DeviceStatusEnum;
  /**
   * interval update status for devices
   */
  intervalUpdateStatusForDevices: any;
  /**
   * constant
   */
  readonly TIME_AUTO_REFRESH_DEFAULT = 1;
  /**
   * subject
   */
  private subject$ = new Subject();
  /**
   * original device ids delivered
   */
  private originalDeviceIdsDelivered: Array<string>;
  /**
   * list timetables
   */
  timetables: Array<Timetable>;
  /**
   * offset value to delay the change over timing of timetable.
   */
  changeoverOffset: number;
  /**
   * time date line
   */
  timeDateLine: any;
  /**
   * switching area time
   */
  switchingAreaTime: number;
  /**
   * group devices
   */
  public groupDevices: Array<GroupDevice>;
  /**
   * Group expanded
   */
  public groupExpanded: GroupDevice;

  /**
   * Group expanded clone
   */
  public groupExpandedClone: GroupDevice;
  /**
   * Device status enum
   */
  public deviceStatusEnum = DeviceStatusEnum;

  /**
   * interval update status for devices
   */
  private intervalUpdateStatusArray: Array<DeliveryGroupIntervalObject> = new Array<DeliveryGroupIntervalObject>();
  /**
   * True when open dialog
   */
  private isUpdateStatusTimetableFirstTime: boolean;
  /**
   * Device delivery objects
   */
  private deviceDeliveryObjects: DeviceDeliveryObject[] = new Array<DeviceDeliveryObject>();

  //#region constants
  public readonly ActiveColumnHeader = ActiveColumnHeader;
  private readonly WAITING_ELEMENT = 'waiting';
  private readonly IN_PROGRESS_ELEMENT = 'inProcess';
  private readonly SUCCESSFUL_ELEMENT = 'successed';
  private readonly FAILED_ELEMENT = 'failed';
  private readonly CANCEL_ELEMENT = 'canceled';
  private readonly REGISTRATION_ID_ELEMENT = 'registrationId';
  private readonly DOWNLOADED_ELEMENT = 'downloaded';
  private readonly TOTAL_ELEMENT = 'total';
  private readonly DEVICE_LIST_ELEMENT = 'deviceList';
  private readonly JOB_ID_SPLIT_KEY = '---';
  private readonly FIRST_ELEMENT = 0;
  private readonly STATE_OF_FILE_STATUS_DDN = 'state';
  private readonly GROUP_DEVICES = 'groupDevicesTimetable';

  //#end region constants
  /**
   * True if had get data from S3
   */
  public isDisableIconEye: boolean;

  /**
   * true if group exists device delivery group
   */
  public isDeliveryGroup: boolean;
  /**
   * common object
   */
  private commonObject: Common;

  constructor(
    public dialogRef: MatDialogRef<DialogDeliveryTimetableComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private dialogService: DialogService,
    private deliveryStatusService: DeliveryStatusTimetableService,
    private timetableContentDayService: TimetableContentDayService,
    private translateService: TranslateService,
    private timetableService: TimetableService,
    private commonService: CommonService,
    private timetableUpdateTimingService: TimetableUpdateTimingService,
    private dataService: DataService,
    private commonTableService: CommonTableService,
    private deviceService: DeviceService,
    private apiCustomerService: APICustomerService,
    private publishTimetableService: PublishTimetableService,
    private devicePublishInfoService: DevicePublishInfoService,
    private executingService: ExecutingService,
    private indexWordService: IndexWordService
  ) {
    this.commonObject = this.commonService.getCommonObject();
    this.dataService.currentData.subscribe(data => {
      if (data[0] == Constant.DELIVERY_SUCCESS) {
        if (!this.groupDevices || !this.dialogRef.componentInstance) {
          return;
        }
        this.clearIntervalForComponent(this.intervalUpdateStatusForDevices);
        this.clearAllIntervalDeliveryGroup();
        let devicesOld = data[1] as DeviceCalendar[];
        this.groupDevices.forEach(group => {
          group.deviceCalendars.forEach(device => {
            let deviceDeliverySuccess = devicesOld.find(deviceOld => deviceOld.registrationId == device.registrationId);
            if (deviceDeliverySuccess) {
              device.status = deviceDeliverySuccess.status;
              device.jobId = deviceDeliverySuccess.jobId;
              if (device.jobId?.indexOf(Constant.DELIVERY_GROUP_KEY) > -1) {
                group.groupJobId = device.jobId;
              }
              if (this.groupExpandedClone) {
                let index = this.groupExpandedClone.deviceCalendars.findIndex(
                  deviceClone => deviceClone.registrationId == device.registrationId
                );
                if (index != -1) {
                  this.groupExpandedClone.deviceCalendars[index].status = device.status;
                  this.groupExpandedClone.deviceCalendars[index].jobId = device.jobId;
                }
                if (device.jobId?.indexOf(Constant.DELIVERY_GROUP_KEY) > -1) {
                  this.groupExpandedClone.groupJobId = device.jobId;
                }
              }
            }
          });
        });
        this.updateStatusNumberForGroups(devicesOld);
        this.handleUpdateStatusForDevices();
      }
    });
  }

  ngOnInit(): void {
    this.groupDevices = this.data.groupDevices;
    if (this.commonObject[this.GROUP_DEVICES]) {
      this.groupDevices.forEach(group => {
        let groupOld = this.commonObject[this.GROUP_DEVICES].find(item => item.name == group.name);
        if (groupOld) {
          group.isChecked = false;
          group.isExpand = false;
          group.activeColumnHeader = ActiveColumnHeader.TOTAL;
          group.statusNumberObject = groupOld.statusNumberObject;
          if (group.groupId) {
            group.deviceCalendars.forEach(data => (data.groupId = group.groupId));
          }
        }
      });
    } else {
      this.groupDevices?.forEach(group => {
        group.isChecked = false;
        group.isExpand = false;
        group.activeColumnHeader = ActiveColumnHeader.TOTAL;
        group.statusNumberObject = new StatusNumberObject();
        group.statusNumberObject.total = group.deviceCalendars.length;
        if (group.groupId) {
          group.deviceCalendars.forEach(data => (data.groupId = group.groupId));
        }
      });
    }
    forkJoin({
      timeDateLine: this.commonTableService.getValueCommonTableByKey(Constant.KEY_TIMETABLE_CHANGE_DATE_LINE_TIMETABLE),
      timetable: this.timetableService.getTimetablesWithSchedule(),
      updateTiming: this.commonTableService.getValueCommonTableByKey(Constant.KEY_UPDATE_TIMING_TIMETABLE),
      switchingAreaTime: this.commonTableService.getValueCommonTableByKey(Constant.KEY_AREA_SWITCHING_TIMING)
    }).subscribe(
      data => {
        this.timeDateLine = data.timeDateLine ? JSON.parse(data.timeDateLine.value) : Constant.TIME_DATE_LINE_DEFAULT;
        this.changeoverOffset = data.updateTiming ? data.updateTiming.value : Constant.UPDATE_TIMING_DEFAULT;
        this.timetables = Helper.convertDataTimetablesForCalendar(data.timetable, this.timeDateLine);
        this.switchingAreaTime = data.switchingAreaTime ? data.switchingAreaTime.value : Constant.TIME_SWITCH_AREA_DEFAULT;
        this.getStatusForAllDeviceFirstTime();
      },
      error => {
        this.dialogRef.close();
        this.handleError();
      }
    );
  }

  /**
   * ngOnDestroy
   */
  ngOnDestroy(): void {
    this.clearIntervalForComponent(this.intervalUpdateStatusForDevices);
    this.clearAllIntervalDeliveryGroup();
  }

  /**
   * Get Status for all device first time
   */
  private getStatusForAllDeviceFirstTime(): void {
    this.deliveryStatusService.getDeliveryStatusForDevices().subscribe(
      async (data: Array<DeliveryStatusTimetable>) => {
        for (let index = 0; index < this.groupDevices.length; index++) {
          let group = this.groupDevices[index];
          group.deviceCalendars.forEach(device => {
            const i = data.findIndex(item => item.deviceId == device.id);
            if (i != -1) {
              device.jobId = data[i].jobId;
              device.status = data[i].status;
            }
          });
          if (group.groupId) {
            await this.callJobProcessDetailsAPI(group, data);
          }
        }
        this.handleUpdateStatusForDevices();
      },
      error => this.handleErrorMessage('get-status-for-devices-failed', error)
    );
  }

  /**
   * Call job process detail API
   *
   * @param group
   * @param deliveryStatusTimetables
   */
  private async callJobProcessDetailsAPI(group: GroupDevice, deliveryStatusTimetables: Array<DeliveryStatusTimetable>): Promise<void> {
    let groupJobId = this.getGroupJobId(
      group.deviceCalendars.map(data => data.id),
      deliveryStatusTimetables
    );
    if (groupJobId) {
      group.groupJobId = groupJobId;
      let payload = {
        jobId: groupJobId
      };
      await new Promise<void>(resolve => {
        this.apiCustomerService.jobProcessDetails(payload).subscribe(
          async data => {
            if (data) {
              this.updateStatusNumberObjectForGroup(data, group);
              if (
                this.checkStatusDevicesGroup(
                  group.deviceCalendars.filter(device => device.jobId && device.jobId.indexOf(Constant.DELIVERY_GROUP_KEY) != -1)
                ) &&
                Helper.checkMappingStatusDevicesGroup(group, this.groupExpandedClone)
              ) {
                group.statusNumberObjectOld = _.cloneDeep(group.statusNumberObject);
                resolve();
                return;
              }
              await this.handleStatusForDeviceOfGroup(group);
              group.statusNumberObjectOld = _.cloneDeep(group.statusNumberObject);
            }
            resolve();
          },
          () => resolve()
        );
      });
    } else if (group.deviceCalendars.some(device => device.jobId?.indexOf(Constant.CMP_DELIVERY_GROUP_KEY) > -1)) {
      this.updateStatusNumberForGroups(group.deviceCalendars);
    }
  }

  /**
   * Get group job id;
   *
   * @param deviceGroupsId
   * @param deliveryStatusTimetables
   * @returns
   */
  private getGroupJobId(deviceGroupsId: Number[], deliveryStatusTimetables: DeliveryStatusTimetable[]): string {
    let jobIdsOfGroup = deliveryStatusTimetables
      .filter(data => deviceGroupsId.includes(data.deviceId))
      .filter(data => data.jobId && data.jobId.indexOf(Constant.DELIVERY_GROUP_KEY) > -1)
      .map(deliveryStatus => deliveryStatus.jobId);
    return jobIdsOfGroup[this.FIRST_ELEMENT];
  }

  /**
   * handle update status for devices
   */
  private async handleUpdateStatusForDevices(): Promise<void> {
    this.groupDevices.forEach(group => {
      if (group.groupId) {
        this.intervalUpdateStatusArray.push(
          new DeliveryGroupIntervalObject(this.handleUpdateStatusForDevicesGroupInterval(group), group.groupId)
        );
      }
    });
    this.intervalUpdateStatusForDevices = this.handleUpdateStatusForDevicesInterval();
  }
  /**
   * Get all group devices
   *
   * @param groupDevice
   * @returns
   */
  private getAllDevicesGroup(groupDevice: GroupDevice): DeviceCalendar[] {
    return groupDevice
      ? groupDevice.deviceCalendars
          .filter(data => data.jobId && data.jobId.indexOf(Constant.DELIVERY_GROUP_KEY) == -1)
          .map(device => {
            return device;
          })
      : new Array<DeviceCalendar>();
  }

  /**
   * get information devices completed
   * @param registrationIds
   */
  private getInformationDevicesCompleted(registrationIds: string[]): Array<DeviceInformation> {
    let deviceInfos = new Array<DeviceInformation>();
    if (!registrationIds) {
      return deviceInfos;
    }
    // get information time date line for device
    registrationIds.forEach(registrationId => {
      this.groupDevices.forEach(group => {
        const index = group.deviceCalendars.findIndex(item => item.registrationId == registrationId);
        if (index != -1) {
          deviceInfos.push(new DeviceInformation(group.deviceCalendars[index].id, group.deviceCalendars[index].timeDateLine));
        }
      });
    });
    return deviceInfos;
  }

  /**
   * Handle update status for devices interval
   */
  private handleUpdateStatusForDevicesInterval(): any {
    return interval(1000)
      .pipe(
        concatMap(() => {
          return this.updateStatusForDevices();
        })
      )
      .subscribe(
        () => {},
        error => console.error(error)
      );
  }

  /**
   * Update status for devices
   *
   * @returns
   */
  private updateStatusForDevices(): Promise<void> {
    const deviceIds = Helper.getSingleDeviceIdsNotYetCompleted(this.groupDevices, this.groupExpandedClone);
    if (!deviceIds || deviceIds.length < 1) {
      this.clearIntervalForComponent(this.intervalUpdateStatusForDevices);
      return;
    }
    return new Promise<void>((resolve, reject) => {
      this.deliveryStatusService
        .updateStatusForDevices(deviceIds)
        .pipe(takeUntil(this.subject$))
        .subscribe(
          deliveryStatusTimetables => {
            deliveryStatusTimetables.forEach(deliveryStatusTimetable => {
              this.groupDevices.forEach(group => {
                const index = group.deviceCalendars.findIndex(item => item.id == deliveryStatusTimetable.deviceId);
                if (index == -1) {
                  return;
                }
                let device = group.deviceCalendars[index];
                device.status = deliveryStatusTimetable.status;
                device.detailStatusDisplay = this.getDetailStatusOfDevice(deliveryStatusTimetable, device.status);
                if (deliveryStatusTimetable.status == DeviceStatusEnum.CANCELLED) {
                  device.detailStatusDisplay = null;
                }
                if (this.groupExpandedClone) {
                  let j = this.groupExpandedClone.deviceCalendars.findIndex(data => data.registrationId == device.registrationId);
                  if (j != -1) {
                    this.groupExpandedClone.deviceCalendars[j].status = deliveryStatusTimetable.status;
                    this.groupExpandedClone.deviceCalendars[j].detailStatusDisplay = this.getDetailStatusOfDevice(
                      deliveryStatusTimetable,
                      this.groupExpandedClone.deviceCalendars[j].status
                    );
                    if (deliveryStatusTimetable.status == DeviceStatusEnum.CANCELLED) {
                      this.groupExpandedClone.deviceCalendars[j].detailStatusDisplay = null;
                    }
                  }
                }
              });
            });
            // list device id delivered
            this.originalDeviceIdsDelivered = deliveryStatusTimetables
              ?.filter(data => data.status == DeviceStatusEnum.COMPLETED)
              ?.map(deliveryStatusTime => deliveryStatusTime.registrationId);
            const deviceInfos = this.getInformationDevicesCompleted(this.originalDeviceIdsDelivered);
            if (deviceInfos?.length) {
              // save data content day by device id
              this.timetableContentDayService
                .saveDataDeliveredContentDay(deviceInfos, this.changeoverOffset, this.switchingAreaTime)
                .subscribe(
                  () => {
                    this.dataService.sendData([Constant.TIMETABLE_DEVICE_COMPLETED_IDS, this.originalDeviceIdsDelivered]);
                  },
                  error => this.handleErrorMessage('update-status-for-completed-devices-failed', error)
                );
            }
            resolve();
          },
          error => {
            reject();
            this.clearIntervalForComponent(this.intervalUpdateStatusForDevices);
            this.handleErrorMessage('get-status-for-single-device-failed', error);
          }
        );
    });
  }
  /**
   * Handle update status for devices group interval
   *
   * @param group
   * @returns
   */
  private handleUpdateStatusForDevicesGroupInterval(group: GroupDevice): any {
    return interval(1000)
      .pipe(
        concatMap(() => {
          return this.updateStatusForDevicesGroup(group);
        })
      )
      .subscribe(
        () => {},
        error => console.error(error)
      );
  }

  /**
   * check status call job detail
   * @param groupDevice
   * @returns
   */
  private checkStatusCallJobDetail(groupDevice: GroupDevice): boolean {
    let groupDeviceCheck = groupDevice;
    if (this.groupExpandedClone && groupDevice.groupId == this.groupExpandedClone.groupId) {
      groupDeviceCheck = _.cloneDeep(this.groupExpandedClone);
    }
    // no devices waiting or in progress | no groupJobId | all device of group is delivery single | (waitingNumber == 0 && inprogressNumber == 0)
    return (
      !groupDeviceCheck.deviceCalendars
        .filter(data => data.jobId && data.jobId.indexOf(Constant.DELIVERY_GROUP_KEY) != -1)
        .some(device => device.status == DeviceStatusEnum.WAITING || device.status == DeviceStatusEnum.IN_PROGRESS) ||
      groupDeviceCheck.deviceCalendars.every(device => device.jobId && device.jobId.indexOf(Constant.DELIVERY_SINGLE_KEY) != -1) ||
      !groupDeviceCheck.groupJobId
    );
  }

  /**
   * Update status for devices group
   *
   * @param groupDevice
   * @returns
   */
  private updateStatusForDevicesGroup(groupDevice: GroupDevice): Promise<void> {
    this.checkGroupExistsDeviceDeliveryGroup();
    if (
      (this.checkStatusCallJobDetail(groupDevice) && Helper.checkMappingStatusDevicesGroup(groupDevice, this.groupExpandedClone)) ||
      groupDevice.deviceCalendars.some(device => device.isDelivering) ||
      !groupDevice.groupJobId ||
      !groupDevice.groupJobId.length
    ) {
      this.clearIntervalForGroup(groupDevice.groupId);
      return;
    }
    if (groupDevice.groupJobId == Constant.CMP_DELIVERY_GROUP_KEY) {
      this.clearIntervalForGroup(groupDevice.groupId);
      this.updateStatusNumberForGroups(groupDevice.deviceCalendars);
    } else {
      const payload = {
        jobId: groupDevice.groupJobId
      };
      return new Promise<void>(resolve => {
        this.apiCustomerService
          .jobProcessDetails(payload)
          .pipe(takeUntil(this.subject$))
          .subscribe(
            async data => {
              if (data) {
                this.updateStatusNumberObjectForGroup(data, groupDevice);
                if (
                  _.isEqual(groupDevice.statusNumberObject, groupDevice.statusNumberObjectOld) &&
                  Helper.checkMappingStatusDevicesGroup(groupDevice, this.groupExpandedClone)
                ) {
                  resolve();
                  return;
                }
                groupDevice.statusNumberObjectOld = _.cloneDeep(groupDevice.statusNumberObject);
                await this.handleStatusForDeviceOfGroup(groupDevice);
                resolve();
              }
            },
            () => {
              this.clearIntervalForGroup(groupDevice.groupId);
              resolve();
            }
          );
      });
    }
  }

  /**
   * update status number object for group
   *
   * @param data
   * @param groupDevice
   * @returns
   */
  private updateStatusNumberObjectForGroup(data: any, groupDevice: GroupDevice): void {
    groupDevice.statusNumberObject.waitingNumber = data[this.WAITING_ELEMENT];
    groupDevice.statusNumberObject.inprogressNumber = data[this.IN_PROGRESS_ELEMENT];
    groupDevice.statusNumberObject.completedNumber = data[this.SUCCESSFUL_ELEMENT];
    groupDevice.statusNumberObject.cancelNumber = data[this.CANCEL_ELEMENT];
    groupDevice.statusNumberObject.failedNumber = data[this.FAILED_ELEMENT];
  }

  /**
   * clear interval
   * @param interval id's interval is running in component
   *
   */
  private clearIntervalForComponent(interval: any): void {
    if (interval) {
      interval.unsubscribe();
    }
  }

  /**
   * Clear interval for group
   *
   * @param groupId
   */
  private clearIntervalForGroup(groupId: string): void {
    const index = this.intervalUpdateStatusArray.findIndex(data => data.groupId == groupId);
    if (index == -1) {
      return;
    }
    this.intervalUpdateStatusArray[index].interval.unsubscribe();
    this.intervalUpdateStatusArray.splice(index, 1);
    this.clearIntervalForGroup(groupId);
  }

  /**
   * cancel http request
   */
  private cancelHttpRequest(): void {
    this.subject$.next();
  }

  /**
   * check or uncheck all device
   */
  public checkAll(): void {
    this.isCheckedAll = !this.isCheckedAll;
    this.groupDevices.forEach(group => {
      group.isChecked = this.isCheckedAll;
      group.deviceCalendars.forEach(device => (device.isChecked = this.isCheckedAll));
      if (this.groupExpandedClone && this.groupExpandedClone?.groupId == group.groupId) {
        this.groupExpandedClone.isChecked = group.isChecked;
        this.groupExpandedClone.deviceCalendars.forEach(device => (device.isChecked = group.isChecked));
      }
    });
  }

  /**
   * close dialog
   */
  public closeDialog(): void {
    this.clearIntervalForComponent(this.intervalUpdateStatusForDevices);
    this.clearAllIntervalDeliveryGroup();
    if (
      this.groupDevices.some(group => {
        return group.deviceCalendars.some(device => device.isDelivering);
      })
    ) {
      this.commonObject[this.GROUP_DEVICES] = _.cloneDeep(this.groupDevices);
    } else {
      this.commonObject[this.GROUP_DEVICES] = null;
    }
    this.dialogRef.close();
  }

  /**
   * Set device delivery objects
   *
   * @param checkedDevices
   */
  private setDeviceDeliveryObjects(checkedDevices: DeviceCalendar[]): void {
    checkedDevices.forEach(device => {
      this.handleDetailStatusForDevice(device, DeviceDetailStatusEnum.CREATE_DATA);
      if (this.deviceDeliveryObjects.findIndex(data => data.deviceId == device.id) == -1) {
        this.deviceDeliveryObjects.push(new DeviceDeliveryObject(device.id, null, this.getGroupIdWhenDelivery(device), device.name));
      }
    });
  }

  /**
   * Set status for checked devices when validation
   *
   * @param checkedDevices
   * @param detailStatusCode
   */
  private setStatusForCheckedDeviceWhenValidation(checkedDevices: DeviceCalendar[], detailStatusCode: string): void {
    checkedDevices.forEach(device => {
      this.handleDetailStatusForDevice(device, detailStatusCode);
      let index = this.deviceDeliveryObjects.findIndex(object => object.deviceId === device.id);
      if (index != -1) {
        this.deviceDeliveryObjects.splice(index, 1);
      }
    });
    this.handleStatusDevicesWhenDeliveryFailed(checkedDevices);
  }

  /**
   * delivery
   */
  public delivery(): void {
    let checkedDevices = this.getDevicesChecked();
    // no device selected
    if (!checkedDevices?.length) {
      this.handleErrorMessage('choose-device');
      return;
    }
    if (checkedDevices.some(device => device.status === DeviceStatusEnum.WAITING || device.status === DeviceStatusEnum.IN_PROGRESS)) {
      this.handleErrorMessage('delivery-process');
      return;
    }
    this.setDeviceDeliveryObjects(checkedDevices);
    this.clearIntervalForComponent(this.intervalUpdateStatusForDevices);
    this.clearAllIntervalDeliveryGroup();
    this.countStatusNumberForGroupsFirstTime(checkedDevices);
    if (this.groupExpandedClone) {
      this.isDeliveryGroup = !this.groupExpandedClone.deviceCalendars.some(deviceCalendar => !deviceCalendar.isChecked);
    }

    const deviceIds = checkedDevices.map(device => device.id);
    // validate exist template main
    this.timetableService.checkExistTemplateMain(deviceIds).subscribe(
      isExist => {
        if (!isExist) {
          this.setStatusForCheckedDeviceWhenValidation(checkedDevices, DeviceDetailStatusEnum.DELIVERY_DATA_ERROR);
          this.handleErrorMessage('no-template');
          return;
        }
        // validate exist data calendar for devices
        this.timetableContentDayService.checkExistDataForDevices(deviceIds).subscribe(
          data1 => {
            if (data1?.some(item => !item)) {
              this.dialogService.showDialog(
                DialogConfirmComponent,
                {
                  data: {
                    text: this.translateService.instant('dialog-delivery-timetable.no-content-day-and-continue'),
                    button1: this.translateService.instant('dialog-delivery-timetable.yes'),
                    button2: this.translateService.instant('dialog-delivery-timetable.no')
                  }
                },
                result => {
                  if (!result) {
                    this.setStatusForCheckedDeviceWhenValidation(checkedDevices, DeviceDetailStatusEnum.DELIVERY_DATA_ERROR);
                    return;
                  }
                  // validate invalid schedule
                  this.validateInvalidSchedule(checkedDevices);
                }
              );
            } else {
              this.timetableContentDayService.checkExistDataAllDateForDevices(deviceIds).subscribe(
                data2 => {
                  if (data2?.some(item => !item)) {
                    this.dialogService.showDialog(
                      DialogConfirmComponent,
                      {
                        data: {
                          text: this.translateService.instant('dialog-delivery-timetable.no-content-some-days-and-continue'),
                          button1: this.translateService.instant('dialog-delivery-timetable.yes'),
                          button2: this.translateService.instant('dialog-delivery-timetable.no')
                        }
                      },
                      result => {
                        if (!result) {
                          this.setStatusForCheckedDeviceWhenValidation(checkedDevices, DeviceDetailStatusEnum.DELIVERY_DATA_ERROR);
                          return;
                        }
                        // validate invalid schedule
                        this.validateInvalidSchedule(checkedDevices);
                      }
                    );
                  } else {
                    this.validateInvalidSchedule(checkedDevices);
                  }
                },
                () => this.setStatusForCheckedDeviceWhenValidation(checkedDevices, DeviceDetailStatusEnum.DELIVERY_DATA_ERROR)
              );
            }
          },
          () => this.setStatusForCheckedDeviceWhenValidation(checkedDevices, DeviceDetailStatusEnum.DELIVERY_DATA_ERROR)
        );
      },
      () => this.setStatusForCheckedDeviceWhenValidation(checkedDevices, DeviceDetailStatusEnum.DELIVERY_DATA_ERROR)
    );
  }

  /**
   * validate invalid schedule
   * @param checkedDevices
   */
  private validateInvalidSchedule(checkedDevices: DeviceCalendar[]): void {
    const deviceIds = checkedDevices?.map(device => device.id);
    this.timetableContentDayService.getTimetableIdsByDeviceIds(deviceIds).subscribe(
      async timetableIds => {
        let allItemDetails = [];
        allItemDetails = timetableIds?.map(timetableId => {
          const index = this.timetables.findIndex(timetable => timetable.id == timetableId);
          if (index != -1) {
            return allItemDetails.concat(this.timetables[index].timetableSchedule?.itemDetails);
          }
        });
        let timetables = this.timetables.filter(timetable => timetableIds.includes(timetable.id));
        if (allItemDetails.some(itemDetails => itemDetails?.some(item => item?.isInValidFormat))) {
          this.setStatusForCheckedDeviceWhenValidation(checkedDevices, DeviceDetailStatusEnum.DELIVERY_DATA_ERROR);
          this.handleErrorMessage('invalid-schedule');
        } else if (await Helper.validateIndexWordInvalid(timetables, this.indexWordService)) {
          this.setStatusForCheckedDeviceWhenValidation(checkedDevices, DeviceDetailStatusEnum.DELIVERY_DATA_ERROR);
          this.handleErrorMessage('index-word-invalid');
        } else {
          this.timetableContentDayService.getContentDaysByDeviceIds(deviceIds).subscribe(
            contentDaysData => {
              let devicesCheckedClone = _.cloneDeep(checkedDevices);
              for (let i = 0; i < devicesCheckedClone.length; i++) {
                devicesCheckedClone[i].contentDays = contentDaysData[i].map(contentDayData => {
                  return Helper.convertDataContentDayForTimetable(contentDayData, this.timetables);
                });
                devicesCheckedClone[i].calendarDays = Helper.getCalendars(devicesCheckedClone[i]);
                devicesCheckedClone[i].contentDays.forEach(contentDay => {
                  let date = Helper.getDateByDay(
                    contentDay.fullDate.getFullYear(),
                    contentDay.fullDate.getMonth() + 1,
                    contentDay.fullDate.getDate()
                  );
                  let index = devicesCheckedClone[i].calendarDays.findIndex(content => content.fullDate.getTime() == date.getTime());
                  if (index != -1) {
                    devicesCheckedClone[i].calendarDays[index] = contentDay;
                  }
                });
              }
              if (devicesCheckedClone.some(device => !this.checkValidTimeNextDay(device.contentDays))) {
                this.dialogService.showDialog(DialogMessageComponent, {
                  data: {
                    title: this.translateService.instant('dialog-error.title'),
                    text: this.translateService.instant('dialog-playlist-recurrence.duplicate-schedule')
                  }
                });
                this.setStatusForCheckedDeviceWhenValidation(checkedDevices, DeviceDetailStatusEnum.DELIVERY_DATA_ERROR);
              } else {
                this.deliveryStatusService.getStatusDeviceSimpleSignageEditor(checkedDevices).subscribe(
                  deviceResult => {
                    const deviceIdsObject = Helper.getDevicesDeliveryObjectId(checkedDevices, this.groupDevices, this.groupExpandedClone);
                    let deviceFaileds = [];
                    let errorMessages = new Array<string>();
                    //device deliverySingle
                    const deliverySingle = [...deviceResult].filter(device => [...deviceIdsObject['singleIds']].includes(device.id));
                    if (deliverySingle.length > 0) {
                      deliverySingle.forEach(device => {
                        if (device.status == DeviceStatusEnum.WAITING || device.status == DeviceStatusEnum.IN_PROGRESS) {
                          let index = checkedDevices.findIndex(deviceChecked => deviceChecked.id == device.id);
                          if (index > -1) {
                            checkedDevices[index].status = DeviceStatusEnum.FAILED;
                            checkedDevices[index].jobId = null;
                            if (this.groupExpandedClone) {
                              let indexClone = this.groupExpandedClone.deviceCalendars.findIndex(
                                deviceClone => deviceClone.id == device.id
                              );
                              if (indexClone) {
                                this.groupExpandedClone.deviceCalendars[indexClone].status = DeviceStatusEnum.FAILED;
                                this.groupExpandedClone.deviceCalendars[indexClone].jobId = null;
                              }
                            }
                            errorMessages.push(
                              Helper.formatString(
                                this.translateService.instant('dialog-playlist-recurrence.device-not-yet-completed'),
                                checkedDevices[index].name
                              )
                            );
                            deviceFaileds.push(checkedDevices.splice(index, 1)[0]);
                          }
                        }
                      });
                    }
                    //device deliveryGroup
                    const deliveryGroup = [...deviceResult].filter(device => [...deviceIdsObject['groupIds']].includes(device.id));
                    if (deliveryGroup.length > 0) {
                      let groupIds = [
                        ...new Set(
                          deliveryGroup
                            .filter(element => element.status == DeviceStatusEnum.WAITING || element.status == DeviceStatusEnum.IN_PROGRESS)
                            .map(data => data.groupId)
                        )
                      ];
                      groupIds.forEach(groupId => {
                        // groups
                        this.groupDevices.forEach(group => {
                          if (group.groupId == groupId) {
                            group.groupJobId = Constant.CMP_DELIVERY_GROUP_KEY;
                            group.deviceCalendars.forEach(device => {
                              device.status = DeviceStatusEnum.FAILED;
                              device.jobId = group.groupJobId;
                              deviceFaileds.push(device);
                            });
                            errorMessages.push(
                              Helper.formatString(
                                this.translateService.instant('dialog-playlist-recurrence.group-not-yet-completed'),
                                group.name
                              )
                            );
                          }
                        });
                        // clone
                        if (this.groupExpandedClone && this.groupExpandedClone.groupId == groupId) {
                          this.groupExpandedClone.groupId = Constant.CMP_DELIVERY_GROUP_KEY;
                          this.groupExpandedClone.deviceCalendars.forEach(device => {
                            device.status = DeviceStatusEnum.FAILED;
                            device.jobId = this.groupExpandedClone.groupId;
                          });
                        }
                        _.remove(checkedDevices, function(deviceRemove) {
                          return deviceRemove.groupId == groupId;
                        });
                      });
                    }
                    if (deviceFaileds.length > 0) {
                      this.dialogService.showDialog(DialogSimpleSignageMessageComponent, {
                        data: {
                          title: this.translateService.instant('dialog-error.title'),
                          texts: errorMessages
                        }
                      });
                      this.handleStatusDevicesWhenDeliveryFailed(deviceFaileds);
                    }
                    if (checkedDevices.length > 0) {
                      this.deliveryUpload(checkedDevices);
                    }
                  },
                  error => this.handleErrorMessage('get-status-for-device-in-simple-failed', error)
                );
              }
            },
            () => this.setStatusForCheckedDeviceWhenValidation(checkedDevices, DeviceDetailStatusEnum.DELIVERY_DATA_ERROR)
          );
        }
      },
      () => this.setStatusForCheckedDeviceWhenValidation(checkedDevices, DeviceDetailStatusEnum.DELIVERY_DATA_ERROR)
    );
  }

  /**
   * check valid time of list contents day
   * @param contentDays
   * @returns true if valid
   */
  private checkValidTimeNextDay(contentDays: ContentDay[]): boolean {
    if (!contentDays?.length) {
      return true;
    }
    for (let i = 0; i < contentDays.length; i++) {
      if (i === contentDays.length - 1) {
        return true;
      }
      if (contentDays[i].timetable?.timetableSchedule?.headers[0] == null) {
        continue;
      }
      if (!this.checkNextDay(contentDays[i].fullDate, contentDays[i + 1].fullDate)) {
        continue;
      }
      let minTimeNextDay = this.getTimeSchedule(contentDays[i + 1].timetable?.id, true);
      let maxTimeCurrentDay = this.getTimeSchedule(contentDays[i].timetable?.id, false);
      if (minTimeNextDay == null || maxTimeCurrentDay == null) {
        continue;
      }
      if (maxTimeCurrentDay > Constant.SECONDS_OF_ONE_DAY + minTimeNextDay) {
        return false;
      }
    }
  }

  /**
   * check next day is tomorrow of current day
   * @param dayFirst
   * @param daySecond
   * @returns true if daySecond is tomorrow of dayFirst
   */
  private checkNextDay(dayFirst: Date, daySecond: Date): boolean {
    dayFirst.setDate(dayFirst.getDate() + 1);
    return (
      dayFirst.getFullYear() === daySecond.getFullYear() &&
      dayFirst.getDate() === daySecond.getDate() &&
      dayFirst.getMonth() === daySecond.getMonth()
    );
  }

  /**
   * get time by minutes
   * @param timetableId
   * @param isGetMin true if get min, else get max
   * @returns time by minutes
   */
  private getTimeSchedule(timetableId: Number, isGetMin): any {
    let timetable = this.timetables.find(timetable => timetable.id === timetableId);
    if (!timetable?.timetableSchedule?.itemDetails?.length) {
      return null;
    }
    let times = Helper.convertTimesToSeconds(timetable.timetableSchedule.itemDetails?.map(detail => detail.items[0]));
    if (isGetMin) {
      return Math.min(...times);
    }
    return Math.max(...times);
  }

  /**
   * Get CMP job id when delivery
   *
   * @param device
   * @param isFailed
   * @returns
   */
  private getCMPJobIDWhenDelivery(device: DeviceCalendar, isFailed?: boolean): string {
    let groupDevice =
      this.groupExpandedClone && this.groupExpandedClone.groupId == device.groupId
        ? this.groupExpandedClone
        : this.groupDevices.find(group =>
            group.deviceCalendars.map(deviceCalendar => deviceCalendar.registrationId).includes(device.registrationId)
          );
    if (!groupDevice) {
      return '';
    }
    return groupDevice.isChecked ? Constant.CMP_DELIVERY_GROUP_KEY : isFailed ? '' : Constant.CMP_DELIVERY_SINGLE_KEY;
  }

  /**
   * delivery upload
   *
   * @param checkedDevices
   */
  private async deliveryUpload(checkedDevices: Array<DeviceCalendar>): Promise<void> {
    checkedDevices.forEach(device => (device.jobId = this.getCMPJobIDWhenDelivery(device)));
    this.uncheckDevices();
    let deliveryStatusTimetables = checkedDevices.map(device => {
      return new DeliveryStatusTimetable(device.id, device.status, device.jobId, device.registrationId);
    });
    this.deliveryStatusService.saveDetailStatusForDevices(deliveryStatusTimetables).toPromise();
    let promises = this.publishDataTimetable(checkedDevices);
    await Promise.all([...promises]).then(async (deviceDeliveryObjects: Array<DeviceDeliveryObject>) => {
      let errorMessages = new Array<String>();
      // handle error for device delivery failed
      let deviceDeliveryFailed = deviceDeliveryObjects.filter(device => device.error);
      if (deviceDeliveryFailed.length > 0) {
        let errors = deviceDeliveryFailed.map(device => {
          return `${device.deviceName}: ${this.translateService.instant('dialog-delivery-timetable.delivery-failed')}`;
        });
        let deviceFailed = deviceDeliveryFailed.map(data => {
          return data.deviceId;
        });
        this.handleStatusDevicesWhenDeliveryFailed(checkedDevices.filter(data => deviceFailed.includes(data.id)));
        if (errors.length > 0) {
          errorMessages = _.concat(errorMessages, errors);
        }
      }
      // Call API for device delivery success
      let deviceDeliverySuccess = deviceDeliveryObjects.filter(device => !device.error);
      if (deviceDeliverySuccess.length > 0) {
        // handle call API for group
        let deviceDeliveryGroups = new Array<DeliveryGroupDevice>();
        this.groupDevices.forEach(group => {
          if (this.isDeliveryGroupChecked(group, _.uniq(deviceDeliverySuccess.map(data => data.groupId)))) {
            let deviceDeliveryGroup = new DeliveryGroupDevice(
              group.groupId,
              deviceDeliverySuccess
                .filter(device => device.groupId == group.groupId)
                .map(data => {
                  return data.deviceId;
                })
            );
            deviceDeliveryGroups.push(deviceDeliveryGroup);
          }
        });
        if (deviceDeliveryGroups.length > 0) {
          let groupContentDeliveryResponse = await this.callAPIForGroupDevices(deviceDeliveryGroups);
          groupContentDeliveryResponse.forEach(data => {
            let jobIdResponse = data.split(this.JOB_ID_SPLIT_KEY);
            let jobId = jobIdResponse[1];
            let group = this.groupDevices.find(group => group.groupId == jobIdResponse[0]);
            if (group) {
              if (jobId.indexOf(Constant.DELIVERY_GROUP_KEY) == -1) {
                group.groupJobId = Constant.CMP_DELIVERY_GROUP_KEY;
                group.deviceCalendars.forEach(device => {
                  device.jobId = group.groupJobId;
                  this.handleDetailStatusForDevice(device, DeviceDetailStatusEnum.DELIVERY_DATA_ERROR);
                });
                // Update status for device clone
                if (this.groupExpandedClone && this.groupExpandedClone.groupId == jobIdResponse[0]) {
                  this.groupExpandedClone.groupJobId = Constant.CMP_DELIVERY_GROUP_KEY;
                  this.groupExpandedClone.deviceCalendars.forEach(device => {
                    device.jobId = this.groupExpandedClone.groupJobId;
                    device.status = DeviceStatusEnum.FAILED;
                    device.detailStatusDisplay = null;
                  });
                }
                errorMessages.push(`${group.name}: ${this.translateService.instant('dialog-delivery-timetable.delivery-failed')}`);
              } else {
                group.groupJobId = jobId;
                group.statusNumberObject.waitingNumber = group.deviceCalendars.length;
                group.statusNumberObject.inprogressNumber = 0;
                group.statusNumberObject.completedNumber = 0;
                group.statusNumberObject.cancelNumber = 0;
                group.statusNumberObject.failedNumber = 0;
                group.deviceCalendars.forEach(element => {
                  element.jobId = jobId;
                });
                if (this.groupExpandedClone && group.groupId == this.groupExpandedClone.groupId) {
                  this.groupExpandedClone.statusNumberObject = group.statusNumberObject;
                  this.groupExpandedClone.deviceCalendars.forEach(data => (data.jobId = jobId));
                  this.groupExpandedClone.groupJobId = group.groupJobId;
                  if (this.groupExpanded.activeColumnHeader != ActiveColumnHeader.TOTAL && this.groupExpanded.isExpand) {
                    this.groupExpanded.deviceCalendars = this.groupExpandedClone.deviceCalendars.filter(
                      device => device.status == Helper.getDeviceStatusEnum(this.groupExpanded.activeColumnHeader)
                    );
                  }
                }
              }
            }
          });
        }
        // handle call API for single device
        let deviceDeliverySingles = deviceDeliverySuccess
          .filter(device => !device.groupId)
          .map(data => {
            return data.deviceId;
          });
        if (deviceDeliverySingles.length > 0) {
          let singleDeliveryResponse = await this.callAPIForSingleDevices(deviceDeliverySingles);
          singleDeliveryResponse.forEach(data => {
            let jobIdResponse = data.split(this.JOB_ID_SPLIT_KEY);
            let jobId = jobIdResponse[1];
            let device = Helper.getDeviceInGroup(+jobIdResponse[0], this.groupDevices);
            if (device) {
              if (jobId.indexOf(Constant.DELIVERY_SINGLE_KEY) == -1) {
                this.handleDetailStatusForDevice(device, DeviceDetailStatusEnum.DELIVERY_DATA_ERROR);
                errorMessages.push(`${device.name}: ${this.translateService.instant('dialog-delivery-timetable.delivery-failed')}`);
              } else {
                device.jobId = jobId;
                if (this.groupExpandedClone) {
                  let index = this.groupExpandedClone.deviceCalendars.findIndex(data => data.registrationId == device.registrationId);
                  if (index != -1) {
                    this.groupExpandedClone.deviceCalendars[index].jobId = jobId;
                  }
                }
              }
            }
          });
        }
      }
      this.groupDevices.forEach(group => {
        group.deviceCalendars.forEach(device => {
          const index = deviceDeliveryObjects.findIndex(deviceDeliveryObjet => deviceDeliveryObjet.deviceId === device.id);
          if (index == -1) {
            return;
          }
          const j = this.deviceDeliveryObjects.findIndex(
            deviceDeliveryObjet => deviceDeliveryObjet.deviceId === deviceDeliveryObjects[index].deviceId
          );
          if (j != -1) {
            this.deviceDeliveryObjects.splice(j, 1);
            device.isDelivering = false;
            Helper.setDataForDeviceGroupClone(Constant.IS_DELIVERY_ELEMENT, device.isDelivering, device.id, this.groupExpandedClone);
          }
        });
      });
      this.groupExpandedClone?.deviceCalendars.forEach(data => (data.isDelivering = false));
      if (!this.dialogRef.componentInstance) {
        this.dataService.sendData([Constant.DELIVERY_SUCCESS, checkedDevices]);
        return;
      }
      // Show message if an error occurs
      if (errorMessages.length > 0) {
        this.checkGroupExistsDeviceDeliveryGroup();
        this.updateStatusNumberForGroups(checkedDevices);
        this.dialogService.showDialog(
          DialogSimpleSignageMessageComponent,
          {
            data: {
              title: this.translateService.instant('dialog-error.title'),
              texts: errorMessages
            }
          },
          () =>
            setTimeout(() => {
              this.handleUpdateStatusForDevices();
            }, 1000)
        );
      } else {
        setTimeout(() => {
          this.handleUpdateStatusForDevices();
        }, 1000);
      }
    });
  }

  /**
   * Is delivery group Checked
   *
   * @param group
   * @param groupIds
   * @returns
   */
  private isDeliveryGroupChecked(group: GroupDevice, groupIds: Array<string>): boolean {
    if (this.groupExpandedClone && this.groupExpandedClone.groupId && this.groupExpandedClone.groupId == group.groupId) {
      return (
        groupIds.includes(this.groupExpandedClone.groupId) && this.groupExpandedClone.deviceCalendars.every(device => device.isDelivering)
      );
    }
    return group.groupId && groupIds.includes(group.groupId) && group.deviceCalendars.every(data => data.isDelivering);
  }

  /**
   * Clear all interval delivery
   *
   * @returns
   */
  private clearAllIntervalDeliveryGroup(): void {
    this.intervalUpdateStatusArray.forEach(data => data.interval.unsubscribe());
    this.intervalUpdateStatusArray = new Array<DeliveryGroupIntervalObject>();
    this.cancelHttpRequest();
  }

  /**
   * Call API group devices
   *
   * @param deviceDeliveryGroups
   * @returns
   */
  private callAPIForGroupDevices(deviceDeliveryGroups: Array<DeliveryGroupDevice>): Promise<Array<string>> {
    return new Promise(resolve => {
      this.devicePublishInfoService.callAPIForGroupDevices(deviceDeliveryGroups).subscribe(
        data => {
          resolve(data);
        },
        error => this.handleErrorMessage('call-group-content-update-failed', error)
      );
    });
  }

  /**
   * Call API for single devices
   *
   * @param singleDevices
   * @returns
   */
  private callAPIForSingleDevices(singleDevices: Array<Number>): Promise<Array<string>> {
    return new Promise(resolve => {
      this.devicePublishInfoService.callAPIForSingleDevices(singleDevices).subscribe(
        data => {
          resolve(data);
        },
        error => this.handleErrorMessage('call-single-content-delivery-failed', error)
      );
    });
  }

  /**
   * Set status delivery for group
   *
   * @param group
   * @param device
   * @returns
   */
  private setStatusDeliveryForGroup(group: GroupDevice, device: DeviceCalendar): void {
    if (!group) {
      return;
    }
    const index = group.deviceCalendars.findIndex(data => data.registrationId === device.registrationId);
    if (index == -1) {
      return;
    }
    group.deviceCalendars[index].isDelivering = device.isDelivering;
  }

  /**
   * publish for timetable editor
   *
   * @param checkedDevices
   */
  private publishDataTimetable(checkedDevices: Array<DeviceCalendar>): Array<Promise<DeviceDeliveryObject>> {
    return checkedDevices.map(device => {
      let index = this.deviceDeliveryObjects.findIndex(object => object.deviceId == device.id);
      if (index == -1) {
        return;
      }
      let deviceDeliveryObject = this.deviceDeliveryObjects[index];
      let dataSetting = new PublishSetting(this.commonService.getCommonObject().userName);
      dataSetting.deviceId = device.id;
      dataSetting.timezone = Helper.getUserTimeZone(this.commonService.getCommonObject().setting);
      dataSetting.changeoverOffset = this.changeoverOffset;
      dataSetting.switchingAreaTime = this.switchingAreaTime;
      return new Promise<DeviceDeliveryObject>(resolve => {
        device.isDelivering = true;
        Helper.setDataForDeviceGroupClone(Constant.IS_DELIVERY_ELEMENT, device.isDelivering, device.id, this.groupExpandedClone);
        this.setStatusDeliveryForGroup(this.groupExpandedClone, device);
        this.publishTimetableService.publishDataTimetable(dataSetting, this.timeDateLine).subscribe(
          (event: HttpEvent<any>) => {
            switch (event.type) {
              case HttpEventType.Sent:
                console.log('Request has been made!');
                break;
              case HttpEventType.ResponseHeader:
                console.log('Response header has been received!');
                break;
              case HttpEventType.UploadProgress:
                break;
              case HttpEventType.Response:
                console.log('Request succeeded!');
                this.handleDetailStatusForDevice(device, DeviceDetailStatusEnum.PREPARING_DATA);
                setTimeout(() => {
                  this.devicePublishInfoService.deliveryForTimetable(event.body).subscribe(
                    dataResponse => {
                      let errors = dataResponse?.filter(data => data);
                      if (errors?.length) {
                        this.handleDetailStatusForDevice(device, DeviceDetailStatusEnum.DELIVERY_DATA_ERROR);
                        deviceDeliveryObject.error = DeviceDetailStatusEnum.DELIVERY_DATA_ERROR;
                        resolve(deviceDeliveryObject);
                      } else {
                        this.deviceService
                          .updateTimeDateLine(Helper.convertTimeDateLineToSeconds(this.timeDateLine), [dataSetting.deviceId])
                          .subscribe(data => {
                            data.forEach(item => {
                              this.groupDevices.forEach(group => {
                                let index = group.deviceCalendars.findIndex(device => device.id == item.id);
                                if (index != -1) {
                                  group.deviceCalendars[index].timeDateLine = item.timeDateLine;
                                }
                              });
                            });
                          });
                        resolve(deviceDeliveryObject);
                      }
                    },
                    () => {
                      this.handleDetailStatusForDevice(device, DeviceDetailStatusEnum.DELIVERY_DATA_ERROR);
                      deviceDeliveryObject.error = DeviceDetailStatusEnum.DELIVERY_DATA_ERROR;
                      resolve(deviceDeliveryObject);
                    }
                  );
                }, 1000);
            }
          },
          () => {
            this.handleDetailStatusForDevice(device, DeviceDetailStatusEnum.DELIVERY_DATA_ERROR);
            deviceDeliveryObject.error = DeviceDetailStatusEnum.DELIVERY_DATA_ERROR;
            resolve(deviceDeliveryObject);
          }
        );
      });
    });
  }

  /**
   * Get group id when delivery
   *
   * @param device
   * @returns
   */
  private getGroupIdWhenDelivery(device: DeviceCalendar): string {
    for (let index = 0; index < this.groupDevices.length; index++) {
      let group = this.groupDevices[index];
      if (group.isChecked && group.groupId == device.groupId) {
        return group.groupId;
      }
    }
    return undefined;
  }

  /**
   * Handle detail status for device
   *
   * @param device
   * @param detailStatusCode
   */
  private handleDetailStatusForDevice(device: DeviceCalendar, detailStatusCode: string): void {
    if (!device) {
      return;
    }
    switch (detailStatusCode) {
      case DeviceDetailStatusEnum.CREATE_DATA:
      case DeviceDetailStatusEnum.PREPARING_DATA:
        device.status = DeviceStatusEnum.WAITING;
        device.detailStatusDisplay = this.translateService.instant(`dialog-delivery-timetable.detail-status.${detailStatusCode}`);
        break;
      case DeviceDetailStatusEnum.DELIVERY_DATA_ERROR:
        device.status = DeviceStatusEnum.FAILED;
        device.detailStatusDisplay = null;
        break;
      default:
        break;
    }
    this.updateStatusForGroupClone(device);
  }

  /**
   * Update status for group clone
   *
   * @param device
   * @returns
   */
  private updateStatusForGroupClone(device: DeviceCalendar): void {
    if (!this.groupExpandedClone) {
      return;
    }
    let index = this.groupExpandedClone.deviceCalendars.findIndex(data => data.registrationId == device.registrationId);
    if (index != -1) {
      this.groupExpandedClone.deviceCalendars[index].status = device.status;
      this.groupExpandedClone.deviceCalendars[index].detailStatusDisplay = device.detailStatusDisplay;
      this.groupExpandedClone.deviceCalendars[index].jobId = device.jobId;
    }
  }

  /**
   * Handle status devices when delivery failed
   *
   * @param checkedDevices
   */
  private handleStatusDevicesWhenDeliveryFailed(checkedDevices: Array<DeviceCalendar>): void {
    let devices: Array<DeviceCalendar> = _.cloneDeep(checkedDevices);
    const deviceCheckedIds = devices.map(device => device.id);
    this.handleDeviceDetailStatus(deviceCheckedIds, DeviceStatusEnum.FAILED, null);
    devices.forEach(device => (device.jobId = this.getCMPJobIDWhenDelivery(device, true)));
    this.uncheckDevices();
    this.deliveryStatusService.updateFailedStatusForDevices(devices).subscribe(
      deliveryStatusList => {
        if (!deliveryStatusList) {
          this.handleError();
          return;
        }
        deliveryStatusList.forEach(deliveryStatus => {
          this.groupDevices.forEach(group => {
            let index = group.deviceCalendars.findIndex(device => device.registrationId == deliveryStatus.registrationId);
            if (index != -1) {
              group.deviceCalendars[index].status = deliveryStatus.status;
              group.deviceCalendars[index].jobId = deliveryStatus.jobId;
              this.updateStatusForGroupClone(group.deviceCalendars[index]);
            }
          });
        });
        this.checkGroupExistsDeviceDeliveryGroup();
        this.updateStatusNumberForGroups(devices);
        this.updateGroupJobIdOfGroup(devices);
        this.handleUpdateStatusForDevices();
      },
      error => {
        if (error.status == Constant.NETWORK_ERROR_CODE) {
          Helper.showMessageErrorNetWork(this.dialogService, this.translateService);
          return;
        }
        this.handleErrorMessage('update-failed-status-for-devices-failed', error);
      }
    );
  }

  /**
   * Handle device detail status
   *
   * @param devicesIdChecked
   * @param deviceStatus
   * @param detailStatus
   */
  private handleDeviceDetailStatus(devicesIdChecked: Array<Number>, deviceStatus: string, detailStatus: string): void {
    devicesIdChecked.forEach(deviceId => {
      this.handleStatusForDevice(deviceId, deviceStatus, detailStatus);
    });
  }

  /**
   * handle status for device
   * @param deviceId
   * @param deviceStatus
   * @param detailStatus
   */
  private handleStatusForDevice(deviceId: any, deviceStatus: string, detailStatus: string): void {
    let status = detailStatus ? this.translateService.instant(`dialog-delivery-timetable.detail-status.${detailStatus}`) : null;
    if (this.groupExpandedClone) {
      let indexOfGroup = this.groupExpandedClone.deviceCalendars?.findIndex(deviceCalendar => deviceCalendar.id == deviceId);
      if (indexOfGroup != -1) {
        this.groupExpandedClone.deviceCalendars[indexOfGroup].detailStatusDisplay = status;
        this.groupExpandedClone.deviceCalendars[indexOfGroup].status = deviceStatus;
      }
    }
    this.groupDevices.forEach(group => {
      group.deviceCalendars.forEach(device => {
        if (device.id == deviceId) {
          device.detailStatusDisplay = status;
          device.status = deviceStatus;
        }
      });
    });
  }

  /**
   * Update group job id of group
   *
   * @param checkedDevices
   */
  private updateGroupJobIdOfGroup(checkedDevices: DeviceCalendar[]): void {
    const groupIdSet = [...new Set(checkedDevices.map(device => device.groupId))];
    this.groupDevices.forEach(group => {
      if (!group.groupId || !groupIdSet.includes(group.groupId)) {
        return;
      }
      group.groupJobId = group.deviceCalendars.some(device => device.jobId?.indexOf(Constant.DELIVERY_GROUP_KEY) > -1)
        ? group.groupJobId
        : Constant.CMP_DELIVERY_GROUP_KEY;
    });
  }

  /**
   * Handle error message
   *
   * @param message
   * @param error
   */
  private handleErrorMessage(message: string, error?: any): void {
    this.dialogService.showDialog(DialogMessageComponent, {
      data: {
        title: this.translateService.instant('dialog-error.title'),
        text:
          error?.status == Constant.NETWORK_ERROR_CODE
            ? this.translateService.instant('dialog-error.error-network')
            : this.translateService.instant(`dialog-delivery-timetable.${message}`)
      }
    });
  }

  /**
   * Handle error devices list job
   *
   * @param error
   * @param value
   */
  private handleErrorAPIDevicesListJob(error: any, value: string): void {
    this.dialogService.showDialog(DialogMessageComponent, {
      data: {
        title: this.translateService.instant('dialog-error.title'),
        text:
          error.status == Constant.NETWORK_ERROR_CODE
            ? this.translateService.instant('dialog-error.error-network')
            : Helper.formatString(this.translateService.instant(`dialog-delivery-timetable.get-device-list-for-job-failed`), value)
      }
    });
  }

  /**
   * Get devices checked
   *
   * @returns
   */
  private getDevicesChecked(): Array<DeviceCalendar> {
    let checkedDevices = [];
    this.groupDevices.forEach(group => {
      let devices = group.deviceCalendars.filter(data => data.isChecked);
      if (devices.length > 0) {
        checkedDevices = _.concat(checkedDevices, devices);
      }
    });
    return checkedDevices;
  }

  /**
   * cancel checked job
   */
  public cancelDelivery(): void {
    let checkedDevices: Array<DeviceCalendar> = this.getDevicesChecked();
    if (!checkedDevices?.length) {
      this.handleErrorMessage('choose-device');
      return;
    }
    if (checkedDevices.some(device => device.isDelivering)) {
      this.handleErrorMessage('include-device-is-delivering');
      return;
    }
    this.dialogService.showDialog(
      DialogConfirmComponent,
      {
        data: {
          text:
            checkedDevices.length > 1
              ? this.translateService.instant('dialog-delivery-timetable.cancel-devices')
              : this.translateService.instant('dialog-delivery-timetable.cancel-device'),
          button1: this.translateService.instant('dialog-delivery-timetable.yes'),
          button2: this.translateService.instant('dialog-delivery-timetable.no')
        }
      },
      async result => {
        if (result) {
          this.clearIntervalForComponent(this.intervalUpdateStatusForDevices);
          this.clearAllIntervalDeliveryGroup();
          this.executingService.executing();
          this.deliveryStatusService.getDeviceInfo(checkedDevices.map(device => device.id).join(' ,')).subscribe(
            async deviceResult => {
              if (!deviceResult.length) {
                return;
              }
              // check diff data
              if (
                !Helper.isEqualDeviceCalendar(checkedDevices, deviceResult) ||
                [...deviceResult].some(
                  data => data.jobId == Constant.CMP_DELIVERY_GROUP_KEY || data.jobId == Constant.CMP_DELIVERY_SINGLE_KEY
                )
              ) {
                // set jobId for device
                checkedDevices.forEach(checkedDevice => {
                  let index = deviceResult.findIndex(device => device.deviceId == checkedDevice.id);
                  if (index != -1) {
                    checkedDevice.jobId = deviceResult[index].jobId;
                  }
                });
                // get GroupSet
                const groupSet = Helper.getGroupSet(checkedDevices);
                // set groupJobId for group
                groupSet.forEach(group => {
                  const index = this.groupDevices.findIndex(data => data.groupId == group.groupId);
                  if (index != -1 && group.jobId?.indexOf(Constant.DELIVERY_GROUP_KEY) > -1) {
                    this.groupDevices[index].groupJobId = group.jobId;
                  }
                });
                // set data for groupClone
                if (this.groupExpandedClone) {
                  let i = groupSet.findIndex(group => group.groupId == this.groupExpandedClone.groupId);
                  if (i != -1) {
                    this.groupExpandedClone.groupJobId = groupSet[i].jobId;
                  }
                  this.groupExpandedClone.deviceCalendars.forEach(device => {
                    let j = deviceResult.findIndex(data => data.deviceId == device.id);
                    if (j != -1) {
                      device.jobId = deviceResult[j].jobId;
                    }
                  });
                }
                if (
                  [...deviceResult].some(
                    device =>
                      (device.jobId == Constant.CMP_DELIVERY_GROUP_KEY || device.jobId == Constant.CMP_DELIVERY_SINGLE_KEY) &&
                      device.status == DeviceStatusEnum.WAITING
                  )
                ) {
                  this.handleErrorMessage('include-device-is-delivering');
                  this.executingService.executed();
                  return;
                }
              }
              // cancel status of device: waiting or in progress
              const devices = await this.updateLatestStatusForCheckedDevices(_.cloneDeep(checkedDevices));
              this.checkGroupExistsDeviceDeliveryGroup();
              if (!devices?.length) {
                this.uncheckDevices();
                this.handleUpdateStatusForDevices();
                this.executingService.executed();
                return;
              }
              this.deliveryStatusService.cancelDelivery(devices.map(device => device.id)).subscribe(
                dataResponse => {
                  if (dataResponse.length > 0) {
                    this.executingService.executed();
                    this.dialogService.showDialog(
                      DialogSimpleSignageMessageComponent,
                      {
                        data: {
                          title: this.translateService.instant('dialog-error.title'),
                          texts: this.getErrorMessageForDeviceCancelFailed(dataResponse)
                        }
                      },
                      () => {
                        this.handleUpdateStatusForDevices();
                      }
                    );
                  } else {
                    this.handleUpdateStatusForDevices();
                  }
                  this.executingService.executed();
                  this.uncheckDevices();
                },
                error => {
                  this.executingService.executed();
                  this.handleErrorMessage('cancel-delivery-failed', error);
                }
              );
            },
            error => this.handleErrorMessage('get-latest-device-information-failed', error)
          );
        }
      }
    );
  }

  /**
   * update latest status for checked devices
   * @param checkedDevices
   * @returns
   */
  private updateLatestStatusForCheckedDevices(checkedDevices: DeviceCalendar[]): Promise<DeviceCalendar[]> {
    return new Promise<DeviceCalendar[]>(async resolve => {
      let devicesDeliverySingle = new Array<DeviceCalendar>();
      let devicesDeliveryGroup = new Array<DeviceCalendar>();

      checkedDevices.forEach(device => {
        if (!device.jobId) {
          return;
        }
        if (device.jobId.indexOf(Constant.DELIVERY_SINGLE_KEY) != -1) {
          devicesDeliverySingle.push(device);
        } else if (device.jobId.indexOf(Constant.DELIVERY_GROUP_KEY) > -1) {
          devicesDeliveryGroup.push(device);
        }
      });

      // update status for devices delivery single
      let deliveryStatusTimetable = await this.updateLatestStatusForDevices(devicesDeliverySingle);
      if (deliveryStatusTimetable) {
        this.groupDevices.forEach(group => {
          group.deviceCalendars.forEach(deviceCalendar => {
            const dataResponse = deliveryStatusTimetable.find(item => item.registrationId == deviceCalendar.registrationId);
            if (dataResponse) {
              deviceCalendar.status = dataResponse.status;
              deviceCalendar.detailStatusDisplay = this.getDetailStatusOfDevice(dataResponse, deviceCalendar.status);
            }
          });
        });
        this.groupExpandedClone?.deviceCalendars.forEach(dv => {
          if (dv) {
            const deliveryStatus = deliveryStatusTimetable.find(item => item.registrationId == dv.registrationId);
            if (deliveryStatus) {
              dv.status = deliveryStatus.status;
              dv.detailStatusDisplay = this.getDetailStatusOfDevice(deliveryStatus, dv.status);
            }
          }
        });

        this.originalDeviceIdsDelivered = deliveryStatusTimetable
          ?.filter(data => data.status == DeviceStatusEnum.COMPLETED && this.checkStatusOldDevices(checkedDevices, data.registrationId))
          ?.map(deliveryStatusTime => deliveryStatusTime.registrationId);
        const deviceInfos = this.getInformationDevicesCompleted(this.originalDeviceIdsDelivered);
        if (deviceInfos?.length) {
          // save data content day by device id
          this.timetableContentDayService.saveDataDeliveredContentDay(deviceInfos, this.changeoverOffset, this.switchingAreaTime).subscribe(
            () => {
              this.dataService.sendData([Constant.TIMETABLE_DEVICE_COMPLETED_IDS, this.originalDeviceIdsDelivered]);
            },
            error => this.handleErrorMessage('update-status-for-completed-devices-failed', error)
          );
        }
      }

      if (!devicesDeliveryGroup.length) {
        resolve(this.getDevicesCancelDelivery());
        return;
      }
      let promises = this.updateLatestStatusForDevicesDeliveryGroup(_.cloneDeep(devicesDeliveryGroup));
      await Promise.all([...promises]).then((jobIds: string[]) => {
        let cancelDevices = [];
        this.groupDevices.forEach(group => {
          group.deviceCalendars.forEach(deviceCalendar => {
            if (jobIds.includes(group.groupJobId) && deviceCalendar.jobId?.indexOf(Constant.DELIVERY_GROUP_KEY) > -1) {
              cancelDevices = _.concat(cancelDevices, deviceCalendar);
            }
            if (devicesDeliverySingle?.findIndex(device => device.id == deviceCalendar.id) != -1) {
              cancelDevices = _.concat(cancelDevices, deviceCalendar);
            }
          });
        });
        if (cancelDevices.length > 0) {
          resolve(cancelDevices.filter(dv => dv.status == DeviceStatusEnum.WAITING || dv.status == DeviceStatusEnum.IN_PROGRESS));
        } else {
          resolve(this.getDevicesCancelDelivery());
        }
      });
    });
  }

  /**
   * get devices cancel delivery
   * @returns
   */
  private getDevicesCancelDelivery(): DeviceCalendar[] {
    return this.getDevicesChecked()?.filter(dv => dv.status == DeviceStatusEnum.WAITING || dv.status == DeviceStatusEnum.IN_PROGRESS);
  }

  /**
   * check status old devices
   * @param devices
   * @param registrationId
   * @returns
   */
  private checkStatusOldDevices(devices: DeviceCalendar[], registrationId: string): boolean {
    let device = devices.find(device => device.registrationId == registrationId);
    return device?.status && device.status != DeviceStatusEnum.COMPLETED;
  }

  /**
   * update latest status for devices
   *
   * @param devices
   * @returns
   */
  private updateLatestStatusForDevices(devices: DeviceCalendar[]): Promise<DeliveryStatusTimetable[]> {
    return new Promise<DeliveryStatusTimetable[]>(resolve => {
      if (!devices.length) {
        resolve(null);
        return;
      }
      this.deliveryStatusService.updateStatusForDevices(devices.map(device => device.id)).subscribe(
        data => {
          resolve(data);
        },
        error => {
          this.handleErrorMessage('update-latest-status-for-devices-failed', error);
          resolve(null);
        }
      );
    });
  }

  /**
   * update latest status for devices delivery group
   *
   * @param devices
   * @returns
   */
  private updateLatestStatusForDevicesDeliveryGroup(devices: DeviceCalendar[]): Array<Promise<string>> {
    let activeColumnHeader = [
      ActiveColumnHeader.WAITING,
      ActiveColumnHeader.IN_PROGRESS,
      ActiveColumnHeader.COMPLETED,
      ActiveColumnHeader.CANCEL,
      ActiveColumnHeader.FAILED
    ];
    let jobIds: Array<string> = new Array<string>();
    devices.forEach(device => {
      if (device.jobId && !jobIds.includes(device.jobId)) {
        jobIds.push(device.jobId);
      }
    });
    return jobIds.map(jobId => {
      let devicesForJobId = this.groupDevices
        .find(group => group.groupJobId == jobId)
        ?.deviceCalendars?.filter(data => data?.jobId == jobId);
      let deviceCalendarsForJobId = _.cloneDeep(devicesForJobId);
      return new Promise<string>(async resolve => {
        for (let i = 0; i < activeColumnHeader.length; i++) {
          if (!devicesForJobId?.length) {
            this.updateStatusNumberObject(jobId);
            resolve(jobId);
            return;
          }
          let status = activeColumnHeader[i];
          let payload = {
            jobId: jobId,
            status: Helper.getPayloadStatusEnum(status)
          };
          let deviceList = await this.callDeviceListForJobAPIFilter(
            payload,
            this.groupDevices.find(group => group.groupJobId == jobId)?.name
          );
          let registrationIds: string[] = deviceList ? deviceList[this.DEVICE_LIST_ELEMENT] : null;

          if (registrationIds) {
            let deviceStatus = Helper.getDeviceStatusEnum(status);
            this.groupDevices.forEach(group => {
              group.deviceCalendars.forEach(device => {
                let index = devicesForJobId.findIndex(deviceCalendar => deviceCalendar.registrationId == device.registrationId);
                if (registrationIds.find(registrationId => registrationId == device.registrationId) && index != -1) {
                  device.status = deviceStatus;
                  if (
                    device.status == DeviceStatusEnum.WAITING ||
                    device.status == DeviceStatusEnum.FAILED ||
                    device.status == DeviceStatusEnum.CANCELLED
                  ) {
                    device.detailStatusDisplay = Helper.handleShowDetailStatusDisplay(
                      this.translateService,
                      'dialog-delivery-timetable.detail-status',
                      device.status
                    );
                  }
                  devicesForJobId.splice(index, 1);
                }
              });
            });
            this.groupExpandedClone?.deviceCalendars.forEach(dv => {
              if (dv && registrationIds.find(item => item == dv.registrationId)) {
                dv.status = deviceStatus;
                if (
                  dv.status == DeviceStatusEnum.WAITING ||
                  dv.status == DeviceStatusEnum.FAILED ||
                  dv.status == DeviceStatusEnum.CANCELLED
                ) {
                  dv.detailStatusDisplay = Helper.handleShowDetailStatusDisplay(
                    this.translateService,
                    'dialog-delivery-timetable.detail-status',
                    dv.status
                  );
                }
              }
            });

            if (deviceStatus == DeviceStatusEnum.COMPLETED || deviceStatus == DeviceStatusEnum.IN_PROGRESS) {
              if (this.isDisableIconEye) {
                let deviceCalendars = deviceCalendarsForJobId.filter(
                  deviceCalendar => deviceCalendar.jobId == jobId && registrationIds.includes(deviceCalendar.registrationId)
                );
                let timetableDetailStatus = await this.updateLatestStatusForDevices(deviceCalendars);
                if (timetableDetailStatus) {
                  this.updateDetailStatusForDevices(timetableDetailStatus);
                }
              }

              if (deviceStatus == DeviceStatusEnum.COMPLETED) {
                // list device id delivered
                this.originalDeviceIdsDelivered = registrationIds.filter(registrationId =>
                  this.checkStatusOldDevices(devices, registrationId)
                );

                const deviceInfos = this.getInformationDevicesCompleted(this.originalDeviceIdsDelivered);
                if (deviceInfos?.length) {
                  // save data content day by device id
                  this.timetableContentDayService
                    .saveDataDeliveredContentDay(deviceInfos, this.changeoverOffset, this.switchingAreaTime)
                    .subscribe(
                      () => {
                        this.dataService.sendData([Constant.TIMETABLE_DEVICE_COMPLETED_IDS, this.originalDeviceIdsDelivered]);
                      },
                      error => this.handleErrorMessage('update-status-for-completed-devices-failed', error)
                    );
                }
              }
            }
          }

          if (i == activeColumnHeader.length - 1) {
            this.updateStatusNumberObject(jobId);
            resolve(jobId);
            return;
          }
        }
      });
    });
  }

  /**
   * update status number object
   * @param jobId
   */
  private updateStatusNumberObject(jobId: string): void {
    this.groupDevices.forEach(group => {
      if (group.groupJobId && group.groupJobId == jobId) {
        group.statusNumberObject.waitingNumber = this.getStatusNumberObject(DeviceStatusEnum.WAITING, group);
        group.statusNumberObject.inprogressNumber = this.getStatusNumberObject(DeviceStatusEnum.IN_PROGRESS, group);
        group.statusNumberObject.completedNumber = this.getStatusNumberObject(DeviceStatusEnum.COMPLETED, group);
        group.statusNumberObject.cancelNumber = this.getStatusNumberObject(DeviceStatusEnum.CANCELLED, group);
        group.statusNumberObject.failedNumber = this.getStatusNumberObject(DeviceStatusEnum.FAILED, group);
      }
    });
  }

  /**
   * get status number object
   * @param status
   * @param group
   * @returns
   */
  private getStatusNumberObject(status: any, group: GroupDevice): number {
    return group.deviceCalendars.filter(
      device => device.status == status && device.jobId && device.jobId.indexOf(Constant.DELIVERY_GROUP_KEY) != -1
    ).length;
  }

  /**
   * Get error message for device cancel failed
   *
   * @param registrationIds
   * @returns
   */
  private getErrorMessageForDeviceCancelFailed(registrationIds: Array<String>): Array<String> {
    let errors = [];
    registrationIds.forEach(registrationId => {
      this.groupDevices.forEach(group => {
        let index = group.deviceCalendars.findIndex(device => device.registrationId == registrationId);
        if (index != -1) {
          errors.push(`${group.deviceCalendars[index].name} : ${this.translateService.instant('dialog-delivery-timetable.cancel-failed')}`);
        }
      });
    });
    return errors;
  }

  /**
   * Uncheck device
   */
  private uncheckDevices(): void {
    this.groupDevices.forEach(group => {
      group.isChecked = false;
      group.deviceCalendars.forEach(device => (device.isChecked = false));
      if (this.groupExpandedClone && this.groupExpandedClone.groupId == group.groupId) {
        this.groupExpandedClone.isChecked = group.isChecked;
        this.groupExpandedClone.deviceCalendars.forEach(device => (device.isChecked = group.isChecked));
      }
    });
    this.isCheckedAll = this.groupDevices.every(group => group.isChecked);
  }

  /**
   * handle error
   *
   */
  private handleError(): void {
    this.dialogService.showDialog(DialogMessageComponent, {
      data: {
        title: this.translateService.instant('dialog-error.title'),
        text: this.translateService.instant('dialog-error.msg')
      }
    });
  }

  /**
   * Check group opened
   *
   * @returns
   */
  public checkGroupOpened(): any {
    return this.groupDevices.some(group => group?.isExpand);
  }

  /**
   * check or uncheck a group
   * @param index index of check-changed device
   * @param e
   */
  public changeCheckedGroup(index: number, e: any): void {
    e.stopPropagation();
    let groupDevice = this.groupDevices[index];
    groupDevice.isChecked = !groupDevice.isChecked;
    groupDevice.deviceCalendars.forEach(device => (device.isChecked = groupDevice.isChecked));
    this.isCheckedAll = this.groupDevices?.every(group => group.isChecked);
    if (this.groupExpandedClone && this.groupExpandedClone.groupId == groupDevice.groupId) {
      this.groupExpandedClone.isChecked = groupDevice.isChecked;
      this.groupExpandedClone.deviceCalendars.forEach(device => (device.isChecked = groupDevice.isChecked));
    }
  }

  /**
   * open group
   *
   * @param groupDevice
   */
  public openGroup(groupDevice: GroupDevice): void {
    groupDevice.activeColumnHeader = ActiveColumnHeader.TOTAL;
    this.isDisableIconEye = false;
    groupDevice.isChecked = groupDevice.deviceCalendars.every(device => device.isChecked) && groupDevice.deviceCalendars.length > 0;
    this.checkGroupExistsDeviceDeliveryGroup();
    this.groupDevices.forEach(group => {
      group.name == groupDevice.name ? (group.isExpand = !group.isExpand) : (group.isExpand = false);
      if (this.groupExpandedClone && this.groupExpandedClone.groupId == group.groupId) {
        group.deviceCalendars = this.groupExpandedClone.deviceCalendars;
        group.activeColumnHeader = ActiveColumnHeader.TOTAL;
      }
      group.deviceCalendars.forEach(device => (device.detailStatusDisplay = null));
    });
    this.groupExpanded = groupDevice.isExpand ? groupDevice : undefined;
    this.groupExpandedClone = _.cloneDeep(this.groupExpanded);
  }

  /**
   * Check status devices group
   *
   * @param deviceCalendars
   * @returns
   */
  private checkStatusDevicesGroup(deviceCalendars: DeviceCalendar[]): boolean {
    if (!deviceCalendars) {
      return true;
    }
    return !deviceCalendars.some(device => device.status == DeviceStatusEnum.WAITING || device.status == DeviceStatusEnum.IN_PROGRESS);
  }

  /**
   * Handle status for device of group
   *
   * @param groupDevice
   */
  private async handleStatusForDeviceOfGroup(groupDevice: GroupDevice): Promise<void> {
    let payLoadWaiting = {
      jobId: groupDevice.groupJobId,
      status: PayloadDeviceStatusEnum.WAITING
    };
    let payLoadInProgress = {
      jobId: groupDevice.groupJobId,
      status: PayloadDeviceStatusEnum.IN_PROGRESS
    };
    let payLoadCompleted = {
      jobId: groupDevice.groupJobId,
      status: PayloadDeviceStatusEnum.COMPLETED
    };
    let payLoadCanceled = {
      jobId: groupDevice.groupJobId,
      status: PayloadDeviceStatusEnum.CANCELLED
    };
    let payLoadFailed = {
      jobId: groupDevice.groupJobId,
      status: PayloadDeviceStatusEnum.FAILED
    };
    // call device list by status
    await new Promise<void>(resolve => {
      forkJoin({
        waitingResponse: this.callDeviceListForJobAPI(payLoadWaiting),
        inProgressResponse: this.callDeviceListForJobAPI(payLoadInProgress),
        completedResponse: this.callDeviceListForJobAPI(payLoadCompleted),
        cancelledResponse: this.callDeviceListForJobAPI(payLoadCanceled),
        failedResponse: this.callDeviceListForJobAPI(payLoadFailed)
      })
        .pipe(takeUntil(this.subject$))
        .subscribe(
          async data => {
            if (data.waitingResponse.length > 0) {
              this.setStatusForDeviceCalendars(data.waitingResponse, groupDevice, DeviceStatusEnum.WAITING);
            }
            if (data.inProgressResponse.length > 0) {
              this.setStatusForDeviceCalendars(data.inProgressResponse, groupDevice, DeviceStatusEnum.IN_PROGRESS);
            }
            if (data.completedResponse.length > 0) {
              this.setStatusForDeviceCalendars(data.completedResponse, groupDevice, DeviceStatusEnum.COMPLETED);
            }
            if (data.cancelledResponse.length > 0) {
              this.setStatusForDeviceCalendars(data.cancelledResponse, groupDevice, DeviceStatusEnum.CANCELLED);
            }
            if (data.failedResponse.length > 0) {
              this.setStatusForDeviceCalendars(data.failedResponse, groupDevice, DeviceStatusEnum.FAILED);
            }
            await this.updateStatusForGroupDevices(groupDevice);
            if (groupDevice.isExpand) {
              // clear detail status
              groupDevice.deviceCalendars.forEach(device => {
                if (device.status == DeviceStatusEnum.CANCELLED || device.status == DeviceStatusEnum.FAILED) {
                  device.detailStatusDisplay = null;
                  if (this.groupExpandedClone) {
                    let index = this.groupExpandedClone.deviceCalendars.findIndex(data => data.registrationId == device.registrationId);
                    if (index != -1) {
                      this.groupExpandedClone.deviceCalendars[index].detailStatusDisplay = null;
                    }
                  }
                }
              });
            }
            resolve();
          },
          error => {
            this.handleErrorAPIDevicesListJob(error, groupDevice.name);
            this.clearIntervalForGroup(groupDevice.groupId);
            resolve();
          }
        );
    });
  }

  /**
   * Update status for group devices
   *
   * @param groupDevice
   * @returns
   */
  private updateStatusForGroupDevices(groupDevice: GroupDevice): Promise<void> {
    const deliveryStatuses = groupDevice.deviceCalendars.map(data => {
      let deliveryStatus = new DeliveryStatusTimetable(null, null, null, null);
      deliveryStatus.deviceId = data.id;
      deliveryStatus.jobId = data.jobId;
      deliveryStatus.registrationId = data.registrationId;
      deliveryStatus.status = data.status;
      return deliveryStatus;
    });
    return new Promise<void>(resolve => {
      this.deliveryStatusService
        .updateStatusForGroupDevices(deliveryStatuses.filter(data => data.jobId && data.jobId.indexOf(Constant.DELIVERY_GROUP_KEY) != -1))
        .subscribe(
          () => {
            resolve();
          },
          error => {
            resolve();
            this.handleErrorMessage('update-status-for-group-devices-failed', error);
            this.clearIntervalForGroup(groupDevice.groupId);
          }
        );
    });
  }

  /**
   * set Status For Device Calendars
   *
   * @param registrationIds
   * @param groupDevice
   * @param status
   */
  private async setStatusForDeviceCalendars(registrationIds: string[], groupDevice: GroupDevice, status: DeviceStatusEnum): Promise<void> {
    registrationIds.forEach(async (registrationId: string) => {
      let index = groupDevice.deviceCalendars.findIndex(data => data.registrationId == registrationId);
      if (
        index != -1 &&
        groupDevice.deviceCalendars[index].jobId?.indexOf(Constant.DELIVERY_GROUP_KEY) > -1 &&
        groupDevice.deviceCalendars[index].status != status
      ) {
        if (status == DeviceStatusEnum.COMPLETED) {
          // list device id delivered
          this.originalDeviceIdsDelivered = registrationIds;
          const deviceInfos = this.getInformationDevicesCompleted(this.originalDeviceIdsDelivered);
          if (deviceInfos?.length) {
            // save data content day by device id
            this.timetableContentDayService
              .saveDataDeliveredContentDay(deviceInfos, this.changeoverOffset, this.switchingAreaTime)
              .subscribe(
                () => {
                  this.dataService.sendData([Constant.TIMETABLE_DEVICE_COMPLETED_IDS, this.originalDeviceIdsDelivered]);
                },
                error => this.handleErrorMessage('update-status-for-completed-devices-failed', error)
              );
          }
        }
        groupDevice.deviceCalendars[index].status = status;
      }
      // set status for device of group clone
      if (this.groupExpandedClone && groupDevice.groupId == this.groupExpandedClone.groupId) {
        let index = this.groupExpandedClone.deviceCalendars.findIndex(device => device.registrationId == registrationId);
        if (index != -1) {
          this.groupExpandedClone.deviceCalendars[index].status = status;
        }
      }
    });
    // filter devices of group
    if (
      this.groupExpanded &&
      this.groupExpanded.groupId == groupDevice.groupId &&
      this.groupExpanded.activeColumnHeader != ActiveColumnHeader.TOTAL
    ) {
      Helper.filterStatusGroupBlank(this.groupExpanded, this.groupExpanded.activeColumnHeader, this.groupExpandedClone);
    }
    // show detail status if device is completing or completed
    if (groupDevice.isExpand) {
      if (status == DeviceStatusEnum.COMPLETED || status == DeviceStatusEnum.IN_PROGRESS) {
        if (this.isDisableIconEye) {
          let deviceCalendars = this.groupExpandedClone.deviceCalendars.filter(deviceCalendar =>
            registrationIds.includes(deviceCalendar.registrationId)
          );
          let timetableDetailStatus = await this.updateLatestStatusForDevices(deviceCalendars);
          if (timetableDetailStatus) {
            this.updateDetailStatusForDevices(timetableDetailStatus);
          }
        }
      }
    }
    Helper.updateActiveStatusNumberRealtime(
      this.groupExpandedClone && this.groupExpandedClone.groupId == groupDevice.groupId ? this.groupExpandedClone : groupDevice,
      status,
      registrationIds.length
    );
  }

  /**
   * Call device list for job API
   *
   * @param payload
   * @returns
   */
  private callDeviceListForJobAPI(payload: any): Promise<any[]> {
    return new Promise(resolve => {
      this.apiCustomerService.deviceListForJob(payload).subscribe(
        async data => {
          resolve(data[this.DEVICE_LIST_ELEMENT]);
        },
        () => {
          resolve([]);
        }
      );
    });
  }

  /**
   * Call device list for job API filter
   *
   * @param payload.
   * @param groupName
   * @returns
   */
  private callDeviceListForJobAPIFilter(payload: any, groupName: string): Promise<string[]> {
    return new Promise<string[]>(resolve => {
      this.apiCustomerService.deviceListForJob(payload).subscribe(
        data => {
          resolve(data);
        },
        error => {
          resolve(null);
          this.handleErrorAPIDevicesListJob(error, groupName);
        }
      );
    });
  }

  /**
   * check or uncheck a device of group
   * @param device
   * @param e
   * @param group
   */
  public changeCheckedDeviceGroup(device: DeviceCalendar, e: any, group: GroupDevice): void {
    e.stopPropagation();
    device.isChecked = !device.isChecked;
    group.isChecked = group.deviceCalendars.every(device => device.isChecked);
    this.isCheckedAll = this.groupDevices?.every(group => group.isChecked);
    if (this.groupExpandedClone?.groupId == group.groupId) {
      this.groupExpandedClone.isChecked = group.isChecked;
      let index = this.groupExpandedClone.deviceCalendars.findIndex(data => data.registrationId == device.registrationId);
      if (index != -1) {
        this.groupExpandedClone.deviceCalendars[index].isChecked = device.isChecked;
      }
    }
  }

  /**
   * Get detail status of group devices
   *
   * @returns
   */
  public getDetailStatusOfGroupDevices(): void {
    if (this.isDisableIconEye || !this.groupExpanded || !this.groupExpanded.deviceCalendars?.length) {
      return;
    }
    this.isDisableIconEye = true;
    this.groupExpanded.deviceCalendars.forEach(device => (device.detailStatusDisplay = this.getDetailStatusByStatusOfDevice(device)));
    this.groupExpandedClone.deviceCalendars.forEach(device => (device.detailStatusDisplay = this.getDetailStatusByStatusOfDevice(device)));
    this.deliveryStatusService
      .getDevicesStatusFromS3(
        this.groupExpandedClone.deviceCalendars.map(device => {
          return device.registrationId;
        })
      )
      .subscribe(
        data => {
          [...data].forEach(deliveryStatusTimetable => {
            let index = this.groupExpandedClone.deviceCalendars.findIndex(
              deviceCalendar => deviceCalendar.registrationId == deliveryStatusTimetable[this.REGISTRATION_ID_ELEMENT]
            );
            if (index != -1) {
              let status = this.groupExpandedClone.deviceCalendars[index].status;
              if (status == DeviceStatusEnum.CANCELLED || status == DeviceStatusEnum.FAILED) {
                this.groupExpandedClone.deviceCalendars[index].detailStatusDisplay = null;
              } else if (status == DeviceStatusEnum.WAITING) {
                this.groupExpandedClone.deviceCalendars[index].detailStatusDisplay = this.translateService.instant(
                  `dialog-delivery-timetable.detail-status.${DeviceDetailStatusEnum.PREPARING_DATA}`
                );
              } else if (deliveryStatusTimetable[this.STATE_OF_FILE_STATUS_DDN]) {
                this.groupExpandedClone.deviceCalendars[index].detailStatusDisplay = Helper.formatString(
                  status == DeviceStatusEnum.IN_PROGRESS
                    ? this.translateService.instant('dialog-delivery-timetable.detail-status.delivery-completing')
                    : this.translateService.instant('dialog-delivery-timetable.detail-status.delivery-completed'),
                  `${deliveryStatusTimetable[this.DOWNLOADED_ELEMENT] ?? 0}`,
                  `${deliveryStatusTimetable[this.TOTAL_ELEMENT] ?? 0}`
                );
              }

              let indexDevice = this.groupExpanded.deviceCalendars.findIndex(
                device => this.groupExpanded.deviceCalendars[index].registrationId == device.registrationId
              );
              if (indexDevice != -1) {
                this.groupExpanded.deviceCalendars[indexDevice].detailStatusDisplay = this.groupExpandedClone.deviceCalendars[
                  index
                ].detailStatusDisplay;
              }
            }
          });
        },
        error => this.handleErrorMessage('get-detailed-status-of-device-failed', error)
      );
  }

  /**
   * Filter status device
   *
   * @param group
   * @param activeColumn
   * @returns
   */
  public async filterStatusDevice(group: GroupDevice, activeColumn: ActiveColumnHeader): Promise<void> {
    if (group.isExpand || !group.groupId) {
      return;
    }

    // openGroup
    this.isDisableIconEye = false;
    group.isChecked = group.deviceCalendars.every(device => device.isChecked) && group.deviceCalendars.length > 0;
    this.groupDevices.forEach(groupDevice => {
      if (this.groupExpandedClone && this.groupExpandedClone.groupId == groupDevice.groupId) {
        groupDevice.isExpand = false;
        groupDevice.deviceCalendars = this.groupExpandedClone.deviceCalendars;
        groupDevice.activeColumnHeader = ActiveColumnHeader.TOTAL;
      }
      groupDevice.deviceCalendars.forEach(device => {
        device.detailStatusDisplay = null;
      });
    });
    this.groupExpanded = group;
    this.groupExpandedClone = _.cloneDeep(this.groupExpanded);
    this.checkGroupExistsDeviceDeliveryGroup();

    // filter device of group
    group.activeColumnHeader = activeColumn;
    // Case validate data failed
    if (
      activeColumn == ActiveColumnHeader.FAILED &&
      this.groupExpandedClone.deviceCalendars.some(data => data.jobId == Constant.CMP_DELIVERY_GROUP_KEY)
    ) {
      this.clearIntervalForComponent(this.intervalUpdateStatusForDevices);
      this.clearAllIntervalDeliveryGroup();
      group.deviceCalendars = this.groupExpandedClone.deviceCalendars.filter(
        data => data.status == DeviceStatusEnum.FAILED && data.jobId == Constant.CMP_DELIVERY_GROUP_KEY
      );
      group.isChecked = group.deviceCalendars.every(device => device.isChecked) && group.deviceCalendars.length > 0;
      this.setIsExpandedForGroup(group);
      return;
    }
    // Case delivering data
    if (activeColumn == ActiveColumnHeader.WAITING && this.isDeliveryGroup) {
      group.deviceCalendars = this.groupExpandedClone.deviceCalendars.filter(data => data.status == DeviceStatusEnum.WAITING);
      group.isChecked = group.deviceCalendars.every(device => device.isChecked) && group.deviceCalendars.length > 0;
      this.setIsExpandedForGroup(group);
      return;
    }
    if (group.groupId) {
      this.executingService.executing();
      await this.filterStatusForGroup(group);
      this.executingService.executed();
    }
    group.isChecked = group.deviceCalendars.every(device => device.isChecked) && group.deviceCalendars.length > 0;
    this.setIsExpandedForGroup(group);
  }

  /**
   * set is expanded for group
   * @param group
   */
  private setIsExpandedForGroup(group: GroupDevice): void {
    this.groupDevices.forEach(groupDevice => {
      groupDevice.name == group.name ? (groupDevice.isExpand = !groupDevice.isExpand) : (groupDevice.isExpand = false);
    });
  }

  /**
   * Filter status for group
   *
   * @param group
   */
  private async filterStatusForGroup(group: GroupDevice): Promise<void> {
    if (!group.groupJobId || group.groupJobId == Constant.CMP_DELIVERY_GROUP_KEY) {
      group.deviceCalendars = [];
      return;
    }
    let payload = {
      jobId: group.groupJobId,
      status: Helper.getPayloadStatusEnum(group.activeColumnHeader)
    };
    let deviceList = await this.callDeviceListForJobAPIFilter(payload, group.name);
    if (!deviceList) {
      group.deviceCalendars = [];
      return;
    }
    group.deviceCalendars = this.groupExpandedClone.deviceCalendars.filter(device =>
      deviceList[this.DEVICE_LIST_ELEMENT].includes(device.registrationId)
    );
  }

  /**
   * get detail status of device
   * @param timetableDetailStatus
   * @param status
   * @returns
   */
  private getDetailStatusOfDevice(timetableDetailStatus: DeliveryStatusTimetable, status: string): any {
    switch (status) {
      case DeviceStatusEnum.FAILED:
      case DeviceStatusEnum.CANCELLED:
        return null;
      case DeviceStatusEnum.COMPLETED:
      case DeviceStatusEnum.IN_PROGRESS:
        return timetableDetailStatus.detailStatus
          ? Helper.formatString(
              status == DeviceStatusEnum.IN_PROGRESS
                ? this.translateService.instant(`dialog-delivery-simple.detail-status.delivery-completing`)
                : this.translateService.instant(`dialog-delivery-simple.detail-status.delivery-completed`),
              timetableDetailStatus.downloadedFiles,
              timetableDetailStatus.totalFile
            )
          : null;
      case DeviceStatusEnum.WAITING:
        return this.translateService.instant(`dialog-delivery-timetable.detail-status.${DeviceDetailStatusEnum.PREPARING_DATA}`);
      default:
        return null;
    }
  }

  /**
   * update detail status for devices
   * @param timetableDetailStatus
   */
  private updateDetailStatusForDevices(timetableDetailStatus: DeliveryStatusTimetable[]): void {
    this.groupDevices.forEach(groupDevice => {
      groupDevice.deviceCalendars.forEach(deviceCalendar => {
        const dataResponse = timetableDetailStatus.find(item => item.registrationId == deviceCalendar.registrationId);
        if (dataResponse) {
          deviceCalendar.detailStatusDisplay = this.getDetailStatusOfDevice(dataResponse, deviceCalendar.status);
        }
      });
    });
    this.groupExpandedClone?.deviceCalendars.forEach(device => {
      const deliveryStatus = timetableDetailStatus.find(data => device && data.registrationId == device.registrationId);
      if (deliveryStatus) {
        device.detailStatusDisplay = this.getDetailStatusOfDevice(deliveryStatus, device.status);
      }
    });
  }

  /**
   * get detail status by status of device
   * @param device
   * @returns
   */
  private getDetailStatusByStatusOfDevice(device: DeviceCalendar): any {
    if (device?.status == DeviceStatusEnum.WAITING) {
      return this.translateService.instant(`dialog-delivery-timetable.detail-status.${DeviceDetailStatusEnum.PREPARING_DATA}`);
    }
    return null;
  }

  /**
   * update status number for groups: Case status of device is Failed
   *
   * @param checkedDevices
   */
  private updateStatusNumberForGroups(checkedDevices: DeviceCalendar[]): void {
    const groupIdSet = new Set(checkedDevices.map(device => device.groupId));
    this.groupDevices.forEach(group => {
      if ([...groupIdSet].includes(group.groupId)) {
        let data = this.groupExpandedClone && this.groupExpandedClone.groupId == group.groupId ? this.groupExpandedClone : group;
        group.statusNumberObject.waitingNumber = this.getStatusNumberForGroup(data, DeviceStatusEnum.WAITING);
        group.statusNumberObject.failedNumber = this.getStatusNumberForGroup(data, DeviceStatusEnum.FAILED);
        if (this.groupExpandedClone && this.groupExpandedClone.groupJobId == group.groupJobId) {
          this.groupExpandedClone.statusNumberObject = group.statusNumberObject;
        }
      }
    });
  }

  /**
   * Get status number for device
   * @param group
   * @param status
   * @returns
   */
  private getStatusNumberForGroup(group: GroupDevice, status: DeviceStatusEnum): number {
    return group.deviceCalendars.filter(
      device =>
        device.status == status &&
        (device.jobId == Constant.CMP_DELIVERY_GROUP_KEY || device.jobId?.indexOf(Constant.DELIVERY_GROUP_KEY) > -1)
    ).length;
  }

  /**
   * Count status number for groups first time when starting delivery
   *
   * @param checkedDevices
   */
  private countStatusNumberForGroupsFirstTime(checkedDevices: DeviceCalendar[]): void {
    const groupIdSet = new Set(checkedDevices.map(device => device.groupId));
    this.groupDevices.forEach(group => {
      let data = this.groupExpandedClone && this.groupExpandedClone.groupId == group.groupId ? this.groupExpandedClone : group;
      if ([...groupIdSet].includes(group.groupId) && data.deviceCalendars.every(deviceCalendar => deviceCalendar.isChecked)) {
        group.statusNumberObject.waitingNumber = this.getStatusNumberOfGroup(DeviceStatusEnum.WAITING, data);
        group.statusNumberObject.inprogressNumber = this.getStatusNumberOfGroup(DeviceStatusEnum.IN_PROGRESS, data);
        group.statusNumberObject.completedNumber = this.getStatusNumberOfGroup(DeviceStatusEnum.COMPLETED, data);
        group.statusNumberObject.cancelNumber = this.getStatusNumberOfGroup(DeviceStatusEnum.CANCELLED, data);
        group.statusNumberObject.failedNumber = this.getStatusNumberOfGroup(DeviceStatusEnum.FAILED, data);
        if (this.groupExpandedClone && this.groupExpandedClone.groupJobId == group.groupJobId) {
          this.groupExpandedClone.statusNumberObject = group.statusNumberObject;
        }
      }
    });
  }

  /**
   * get status number of group
   * @param status
   * @param group
   * @returns
   */
  private getStatusNumberOfGroup(status: any, group: GroupDevice): number {
    return group.deviceCalendars.filter(device => device.status == status).length;
  }

  /**
   * check group exists device delivery group
   */
  private checkGroupExistsDeviceDeliveryGroup(): void {
    this.isDeliveryGroup = !this.groupExpandedClone
      ? false
      : this.groupExpandedClone.deviceCalendars.some(
          device =>
            (device.status == DeviceStatusEnum.WAITING || device.status == DeviceStatusEnum.IN_PROGRESS) &&
            device.jobId &&
            device.jobId.indexOf(Constant.DELIVERY_GROUP_KEY) != -1
        ) ||
        this.groupExpandedClone.deviceCalendars.every(
          device =>
            device.isDelivering &&
            (device.jobId?.indexOf(Constant.DELIVERY_GROUP_KEY) > -1 || device.jobId == Constant.CMP_DELIVERY_GROUP_KEY)
        );
  }

  /**
   * download data publish
   */
  public downloadDataPublish(): void {
    // validate
    let checkedDevices = this.getDevicesChecked();
    // no device selected
    if (!checkedDevices?.length) {
      this.handleErrorMessage('choose-device');
      return;
    }
    const deviceIds = checkedDevices.map(device => device.id);
    // validate exist template main
    this.timetableService.checkExistTemplateMain(deviceIds).subscribe(
      isExist => {
        if (!isExist) {
          this.handleErrorMessage('no-template');
          return;
        }
        // validate exist data calendar for devices
        this.timetableContentDayService.checkExistDataForDevices(deviceIds).subscribe(
          data1 => {
            if (data1?.some(item => !item)) {
              this.dialogService.showDialog(
                DialogConfirmComponent,
                {
                  data: {
                    text: this.translateService.instant('dialog-delivery-timetable.no-content-day-and-continue-download'),
                    button1: this.translateService.instant('dialog-delivery-timetable.yes'),
                    button2: this.translateService.instant('dialog-delivery-timetable.no')
                  }
                },
                result => {
                  if (!result) {
                    return;
                  }
                  // handle download data publish
                  this.validateInvalidScheduleWhenDownloadPublish(checkedDevices);
                }
              );
            } else {
              this.timetableContentDayService.checkExistDataAllDateForDevices(deviceIds).subscribe(
                data2 => {
                  if (data2?.some(item => !item)) {
                    this.dialogService.showDialog(
                      DialogConfirmComponent,
                      {
                        data: {
                          text: this.translateService.instant('dialog-delivery-timetable.no-content-some-days-and-continue-download'),
                          button1: this.translateService.instant('dialog-delivery-timetable.yes'),
                          button2: this.translateService.instant('dialog-delivery-timetable.no')
                        }
                      },
                      result => {
                        if (!result) {
                          return;
                        }
                        // handle download data publish
                        this.validateInvalidScheduleWhenDownloadPublish(checkedDevices);
                      }
                    );
                  } else {
                    // handle download data publish
                    this.validateInvalidScheduleWhenDownloadPublish(checkedDevices);
                  }
                },
                error => Helper.handleError(error, this.translateService, this.dialogService)
              );
            }
          },
          error => Helper.handleError(error, this.translateService, this.dialogService)
        );
      },
      error => Helper.handleError(error, this.translateService, this.dialogService)
    );
  }

  /**
   * validate invalid schedule when download publish
   * @param checkedDevices
   */
  private validateInvalidScheduleWhenDownloadPublish(checkedDevices: DeviceCalendar[]): void {
    const deviceIds = checkedDevices?.map(device => device.id);
    this.timetableContentDayService.getTimetableIdsByDeviceIds(deviceIds).subscribe(
      async timetableIds => {
        let allItemDetails = [];
        allItemDetails = timetableIds?.map(timetableId => {
          const index = this.timetables.findIndex(timetable => timetable.id == timetableId);
          if (index != -1) {
            return allItemDetails.concat(this.timetables[index].timetableSchedule?.itemDetails);
          }
        });
        let timetables = this.timetables.filter(timetable => timetableIds.includes(timetable.id));
        if (allItemDetails.some(itemDetails => itemDetails?.some(item => item?.isInValidFormat))) {
          this.handleErrorMessage('invalid-schedule');
          return;
        }
        if (await Helper.validateIndexWordInvalid(timetables, this.indexWordService)) {
          this.handleErrorMessage('index-word-invalid');
          return;
        }
        this.timetableContentDayService.getContentDaysByDeviceIds(deviceIds).subscribe(
          contentDaysData => {
            let devicesCheckedClone = _.cloneDeep(checkedDevices);
            for (let i = 0; i < devicesCheckedClone.length; i++) {
              devicesCheckedClone[i].contentDays = contentDaysData[i].map(contentDayData => {
                return Helper.convertDataContentDayForTimetable(contentDayData, this.timetables);
              });
              devicesCheckedClone[i].calendarDays = Helper.getCalendars(devicesCheckedClone[i]);
              devicesCheckedClone[i].contentDays.forEach(contentDay => {
                let date = Helper.getDateByDay(
                  contentDay.fullDate.getFullYear(),
                  contentDay.fullDate.getMonth() + 1,
                  contentDay.fullDate.getDate()
                );
                let index = devicesCheckedClone[i].calendarDays.findIndex(content => content.fullDate.getTime() == date.getTime());
                if (index != -1) {
                  devicesCheckedClone[i].calendarDays[index] = contentDay;
                }
              });
            }
            if (devicesCheckedClone.some(device => !this.checkValidTimeNextDay(device.contentDays))) {
              this.dialogService.showDialog(DialogMessageComponent, {
                data: {
                  title: this.translateService.instant('dialog-error.title'),
                  text: this.translateService.instant('dialog-playlist-recurrence.duplicate-schedule')
                }
              });
            } else {
              this.handleDownloadPublish(checkedDevices);
            }
          },
          error => Helper.handleError(error, this.translateService, this.dialogService)
        );
      },
      error => Helper.handleError(error, this.translateService, this.dialogService)
    );
  }

  /**
   * handle download publish
   * @param checkedDevices
   */
  public handleDownloadPublish(checkedDevices: DeviceCalendar[]): void {
    let dataSettings = checkedDevices.map(device => {
      return this.getDataPublishSettingForDevice(device);
    });
    this.dialogService.showDialog(
      DialogDownloadDataComponent,
      {
        data: {
          dataSettings: dataSettings,
          timeDateLine: this.timeDateLine,
          isSimple: false
        }
      },
      () => this.uncheckDevices()
    );
  }

  /**
   * get data publish setting for device
   * @param device
   */
  private getDataPublishSettingForDevice(device: DeviceCalendar): PublishSetting {
    let dataSetting = new PublishSetting(this.commonService.getCommonObject().userName);
    dataSetting.deviceId = device.id;
    dataSetting.timezone = Helper.getUserTimeZone(this.commonService.getCommonObject().setting);
    dataSetting.changeoverOffset = this.changeoverOffset;
    dataSetting.switchingAreaTime = this.switchingAreaTime;
    dataSetting.registrationId = device.registrationId;
    return dataSetting;
  }
}

/**
 * export dialog data
 */
export interface DialogData {
  /**
   * Group devices
   */
  groupDevices: Array<GroupDevice>;
}

/**
 * class DeviceInformation
 */
export class DeviceInformation {
  /**
   * Device Id
   */
  deviceId: Number;
  /**
   * Time date line
   */
  timeDateLine: number;

  constructor(deviceId?: Number, timeDateLine?: number) {
    this.deviceId = deviceId;
    this.timeDateLine = timeDateLine;
  }
}

/**
 * Device delivery object
 */
export class DeviceDeliveryObject {
  /**
   * Device name
   */
  deviceName: string;
  /**
   * Device Id
   */
  deviceId: Number;
  /**
   * Error
   */
  error: string;
  /**
   * Group Id
   */
  groupId: string;

  constructor(deviceId: Number, error: string, groupId: string, deviceName: string) {
    this.deviceId = deviceId;
    this.error = error;
    this.groupId = groupId;
    this.deviceName = deviceName;
  }
}

/**
 * Delivery Group Device
 */
export class DeliveryGroupDevice {
  /**
   * Group Id
   */
  groupId: string;
  /**
   * Device Ids
   */
  deviceIds: Array<Number>;
  constructor(groupId: string, deviceIds: Array<Number>) {
    this.groupId = groupId;
    this.deviceIds = deviceIds;
  }
}

/**
 * Delivery group interval object
 */
export class DeliveryGroupIntervalObject {
  /**
   * Interval
   */
  interval: any;
  /**
   * Group Id
   */
  groupId: string;
  constructor(interval: any, groupId: string) {
    this.interval = interval;
    this.groupId = groupId;
  }
}
