import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Constant, ScreenNameEnum, TemplateModeEnum, TemplateTypeEnum } from 'app/config/constants';
import { DataExternalSetting } from 'app/model/entity/data-external-setting';
import { DisplayTemplate } from 'app/model/entity/display-template';
import { CommonService } from 'app/service/common.service';
import { DialogService } from 'app/service/dialog.service';
import { PictureAreaService } from 'app/service/picture-area-service';
import { Helper } from '../../common/helper';
import { PictureArea } from '../../model/entity/picture-area';
import { Template } from '../../model/entity/template';
import { TemplateGroup } from '../../model/entity/template-group';
import { TemplateService } from '../../service/template.service';
import { DialogConfirmComponent } from '../dialog-confirm/dialog-confirm.component';
import { DialogMessageComponent } from '../dialog-message/dialog-message.component';
import { DialogSettingExternalContentComponent } from '../dialog-setting-external-content/dialog-setting-external-content.component';

@Component({
  selector: 'app-dialog-change-template',
  templateUrl: './dialog-change-template.component.html',
  styleUrls: ['./dialog-change-template.component.scss']
})
export class DialogChangeTemplateComponent implements OnInit {
  /**
   * title popup
   */
  title: string = 'Template Setting';

  ScreenNameEnum = ScreenNameEnum;

  //===========  Change Template for On-bus Display Editor Screen  ==============
  /**
   * display 1 for BID
   */
  display1: Template;
  /**
   * display 2 for BID
   */
  display2: Template;
  /**
   * template groups
   */
  templateGroups: Array<TemplateGroup>;
  /**
   * template list
   */
  templatesForBID: Array<Template>;

  //===========  Change Template for Station Display Editor/ Signage Display/ Timetable Editor Screen  ==============
  templates: Array<Template>;

  templatesMainPage: Array<Template>;

  templatesSubPage1: Array<Template>;

  templatesSubPage2: Array<Template>;

  templatesSubPage3: Array<Template>;

  templatesSubPage4: Array<Template>;

  templatesEmergencyPage: Array<Template>;

  displayTemplate1: DisplayTemplate;

  displayTemplate2: DisplayTemplate;

  templateMode: TemplateModeEnum;

  TemplateTypeEnum = TemplateTypeEnum;
  /**
   * true if hidden popup
   */
  isHidden: boolean;
  /**
   * same external content of display 1
   */
  samesExternalContentOfDisplay1: Array<any>;
  /**
   * same external content of display 2
   */
  samesExternalContentOfDisplay2: Array<any>;
  /**
   * dataSettingExternalContent of display 1
   */
  dataSettingExternalContentOfDisplay1: Array<any> = new Array<any>();
  /**
   * dataSettingExternalContent of display 2
   */
  dataSettingExternalContentOfDisplay2: Array<any> = new Array<any>();
  /**
   * all true if all button set is clicked
   */
  checkClickSetDisplay1s: Array<boolean>;
  /**
   * all true if all button set is clicked
   */
  checkClickSetDisplay2s: Array<boolean>;
  /**
   * main title display for change display inline
   */
  isChangeInlineDisplay2: boolean;
  /**
   * list buttons Set disabled display 1
   */
  disabledButtonSet1s: Array<boolean>;
  /**
   * list buttons Set disabled display 2
   */
  disabledButtonSet2s: Array<boolean>;

  constructor(
    private dialogService: DialogService,
    @Inject(MAT_DIALOG_DATA) public dataBID: DialogDataBID,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public dialogRef: MatDialogRef<DialogChangeTemplateComponent>,
    private templateService: TemplateService,
    private pictureAreaService: PictureAreaService,
    private translateService: TranslateService,
    private commonService: CommonService
  ) {
    // On-bus
    if (dataBID.screen == ScreenNameEnum.ON_BUS_DISPLAY) {
      this.templateMode = TemplateModeEnum.ON_BUS_DISPLAY;
      this.display1 = this.dataBID.display1;
      this.display2 = this.dataBID.display2;
    } else {
      // station/ signage display/ timetable editor
      this.templateMode =
        data.screen == ScreenNameEnum.STATION_DISPLAY
          ? TemplateModeEnum.STATION_DISPLAY
          : data.screen == ScreenNameEnum.SIGNAGE_DISPLAY
          ? TemplateModeEnum.SIGNAGE_DISPLAY
          : TemplateModeEnum.TIMETABLE;
      this.displayTemplate1 = data.displayTemplate1 ?? new DisplayTemplate();
      this.displayTemplate2 = data.displayTemplate2 ?? new DisplayTemplate();
      this.samesExternalContentOfDisplay1 = data.dataExternalContent['display1'] ?? [];
      this.checkClickSetDisplay1s = [false, false, false, false, false, false];
      this.isChangeInlineDisplay2 = data.isChangeInlineDisplay2;

      if (data.displayTemplate2) {
        this.samesExternalContentOfDisplay2 = data.dataExternalContent['display2'] ?? [];
        this.checkClickSetDisplay2s = [false, false, false, false, false, false];
      }
    }
  }

  ngOnInit(): void {
    if (this.dataBID.screen == ScreenNameEnum.ON_BUS_DISPLAY) {
      this.getAllTemplateForBID();
    } else {
      this.getAllTemplate();
      this.disabledButtonSet1s = new Array<boolean>(true, true, true, true, true, true);
      this.disabledButtonSet2s = new Array<boolean>(true, true, true, true, true, true);
      // get external content when on screen
      this.getDataSettingExternalContentAtFirstTime(
        this.displayTemplate1,
        this.dataSettingExternalContentOfDisplay1,
        this.samesExternalContentOfDisplay1,
        false,
        this.checkClickSetDisplay1s
      );
      if (this.displayTemplate2) {
        // get external content when on screen
        this.getDataSettingExternalContentAtFirstTime(
          this.displayTemplate2,
          this.dataSettingExternalContentOfDisplay2,
          this.samesExternalContentOfDisplay2,
          true,
          this.checkClickSetDisplay2s
        );
      }
    }
  }

  /**
   * get all template
   */
  private getAllTemplateForBID(): void {
    this.templateService.getTemplatesByGroupMode(this.templateMode).subscribe(
      (data: Array<Template>) => {
        this.templatesForBID = Helper.convertDataTemplatesBackward(data);
        this.templatesForBID = this.templatesForBID.sort(function(template1, template2) {
          return <number>template1.templateGroupId - <number>template2.templateGroupId || <number>template1.id - <number>template2.id;
        });
      },
      error => {
        this.dialogRef.close();
        this.dialogService.showDialog(DialogMessageComponent, {
          data: {
            title: `Error`,
            text: `An error has occurred. Please try again.`
          }
        });
      }
    );
  }

  /**
   * change template display 1
   * @param templateIdChoose value template id
   */
  public changeTemplateDisplay1(templateIdChoose: number): void {
    if (!templateIdChoose) {
      this.display1 = new Template();
      this.display1.id = -1;
    } else {
      this.display1 = this.templatesForBID.find(template => template.id == templateIdChoose);
    }
  }

  /**
   * change template display 2
   * @param templateIdChoose value template id
   */
  public changeTemplateDisplay2(templateIdChoose: number): void {
    if (!templateIdChoose) {
      this.display2 = new Template();
      this.display2.id = -1;
    } else {
      this.display2 = this.templatesForBID.find(template => template.id == templateIdChoose);
    }
  }

  /**
   * save change display
   */
  public saveChange(): void {
    if (this.dataBID.isDisplay2) {
      this.dialogRef.close([this.display1, this.display2]);
    } else {
      this.dialogRef.close(this.display1);
    }
  }

  //===========  Change Template for Station/Signage Display Editor/Timetable Editor Screen  ==============
  /**
   * get templates by template group mode
   */
  private getAllTemplate(): void {
    this.templateService.getTemplatesByGroupMode(this.templateMode).subscribe(data => {
      this.templates = Helper.convertDataTemplatesBackward(data);
      this.templatesMainPage = this.sortTemplateByTemplateGroupIdAndId(TemplateTypeEnum.MAIN);
      this.templatesSubPage1 = this.sortTemplateByTemplateGroupIdAndId(TemplateTypeEnum.SUB_PAGE_1);
      this.templatesSubPage2 = this.sortTemplateByTemplateGroupIdAndId(TemplateTypeEnum.SUB_PAGE_2);
      this.templatesSubPage3 = this.sortTemplateByTemplateGroupIdAndId(TemplateTypeEnum.SUB_PAGE_3);
      this.templatesSubPage4 = this.sortTemplateByTemplateGroupIdAndId(TemplateTypeEnum.SUB_PAGE_4);
      this.templatesEmergencyPage = this.sortTemplateByTemplateGroupIdAndId(TemplateTypeEnum.EMERGENCY);
    });
  }

  /**
   * Sort template by template group id and template id
   *
   * @param templateTypeEnum
   * @returns
   */
  private sortTemplateByTemplateGroupIdAndId(templateTypeEnum: TemplateTypeEnum): Template[] {
    let templatesFiltered = this.templates.filter(template => template.templateType == templateTypeEnum);
    return templatesFiltered.sort((template1, template2) => {
      return <number>template1.templateGroupId - <number>template2.templateGroupId || <number>template1.id - <number>template2.id;
    });
  }

  /**
   * get external content at first time
   * @param displayTemplate
   * @param dataSettingExternalContentOfStyle
   * @param samesExternalContentOfStyle
   * @param isCheckDisplay2
   * @param checkClickSetDisplays
   * @returns
   */
  private async getDataSettingExternalContentAtFirstTime(
    displayTemplate: DisplayTemplate,
    dataSettingExternalContentOfStyle: any,
    samesExternalContentOfStyle: any,
    isCheckDisplay2: boolean,
    checkClickSetDisplays: Array<boolean>
  ): Promise<any> {
    if (this.data.screen == ScreenNameEnum.STATION_DISPLAY) {
      return;
    }
    if (!displayTemplate) {
      return;
    }
    if (displayTemplate.idMainPage) {
      let areaNew = await this.getPictureAreasExternalContentByTemplateId(displayTemplate.idMainPage as number);
      dataSettingExternalContentOfStyle[TemplateTypeEnum.MAIN] = this.generateDataExternalSetting(
        areaNew,
        samesExternalContentOfStyle[TemplateTypeEnum.MAIN]
      );
      checkClickSetDisplays[TemplateTypeEnum.MAIN] = dataSettingExternalContentOfStyle[TemplateTypeEnum.MAIN].every(
        item => item.idExternalContent != -1
      );
      this.checkExistAreaSetExternalContent(
        dataSettingExternalContentOfStyle[TemplateTypeEnum.MAIN],
        TemplateTypeEnum.MAIN,
        isCheckDisplay2
      );
    }
    if (displayTemplate.idSubPage1) {
      let areaNew = await this.getPictureAreasExternalContentByTemplateId(displayTemplate.idSubPage1 as number);
      dataSettingExternalContentOfStyle[TemplateTypeEnum.SUB_PAGE_1] = this.generateDataExternalSetting(
        areaNew,
        samesExternalContentOfStyle[TemplateTypeEnum.SUB_PAGE_1]
      );
      checkClickSetDisplays[TemplateTypeEnum.SUB_PAGE_1] = dataSettingExternalContentOfStyle[TemplateTypeEnum.SUB_PAGE_1].every(
        item => item.idExternalContent != -1
      );
      this.checkExistAreaSetExternalContent(
        dataSettingExternalContentOfStyle[TemplateTypeEnum.SUB_PAGE_1],
        TemplateTypeEnum.SUB_PAGE_1,
        isCheckDisplay2
      );
    }
    if (displayTemplate.idSubPage2) {
      let areaNew = await this.getPictureAreasExternalContentByTemplateId(displayTemplate.idSubPage2 as number);
      dataSettingExternalContentOfStyle[TemplateTypeEnum.SUB_PAGE_2] = this.generateDataExternalSetting(
        areaNew,
        samesExternalContentOfStyle[TemplateTypeEnum.SUB_PAGE_2]
      );
      checkClickSetDisplays[TemplateTypeEnum.SUB_PAGE_2] = dataSettingExternalContentOfStyle[TemplateTypeEnum.SUB_PAGE_2].every(
        item => item.idExternalContent != -1
      );
      this.checkExistAreaSetExternalContent(
        dataSettingExternalContentOfStyle[TemplateTypeEnum.SUB_PAGE_2],
        TemplateTypeEnum.SUB_PAGE_2,
        isCheckDisplay2
      );
    }
    if (displayTemplate.idSubPage3) {
      let areaNew = await this.getPictureAreasExternalContentByTemplateId(displayTemplate.idSubPage3 as number);
      dataSettingExternalContentOfStyle[TemplateTypeEnum.SUB_PAGE_3] = this.generateDataExternalSetting(
        areaNew,
        samesExternalContentOfStyle[TemplateTypeEnum.SUB_PAGE_3]
      );
      checkClickSetDisplays[TemplateTypeEnum.SUB_PAGE_3] = dataSettingExternalContentOfStyle[TemplateTypeEnum.SUB_PAGE_3].every(
        item => item.idExternalContent != -1
      );
      this.checkExistAreaSetExternalContent(
        dataSettingExternalContentOfStyle[TemplateTypeEnum.SUB_PAGE_3],
        TemplateTypeEnum.SUB_PAGE_3,
        isCheckDisplay2
      );
    }
    if (displayTemplate.idSubPage4) { 
      let areaNew = await this.getPictureAreasExternalContentByTemplateId(displayTemplate.idSubPage4 as number);
      dataSettingExternalContentOfStyle[TemplateTypeEnum.SUB_PAGE_4] = this.generateDataExternalSetting(
        areaNew,
        samesExternalContentOfStyle[TemplateTypeEnum.SUB_PAGE_4]
      );
      checkClickSetDisplays[TemplateTypeEnum.SUB_PAGE_4] = dataSettingExternalContentOfStyle[TemplateTypeEnum.SUB_PAGE_4].every(
        item => item.idExternalContent != -1
      );
      this.checkExistAreaSetExternalContent(
        dataSettingExternalContentOfStyle[TemplateTypeEnum.SUB_PAGE_4],
        TemplateTypeEnum.SUB_PAGE_4,
        isCheckDisplay2
      );
    }
    if (displayTemplate.idEmergencyPage) {
      let areaNew = await this.getPictureAreasExternalContentByTemplateId(displayTemplate.idEmergencyPage as number);
      dataSettingExternalContentOfStyle[TemplateTypeEnum.EMERGENCY] = this.generateDataExternalSetting(
        areaNew,
        samesExternalContentOfStyle[TemplateTypeEnum.EMERGENCY]
      );
      checkClickSetDisplays[TemplateTypeEnum.EMERGENCY] = dataSettingExternalContentOfStyle[TemplateTypeEnum.EMERGENCY].every(
        item => item.idExternalContent != -1
      );
      this.checkExistAreaSetExternalContent(
        dataSettingExternalContentOfStyle[TemplateTypeEnum.EMERGENCY],
        TemplateTypeEnum.EMERGENCY,
        isCheckDisplay2
      );
    }
    return dataSettingExternalContentOfStyle;
  }

  /**
   * check exist area set external content display 1/2
   * @param dataSettingExternalContent
   * @param type
   * @param isCheckDisplay2
   */
  private checkExistAreaSetExternalContent(dataSettingExternalContent: any, type: TemplateTypeEnum, isCheckDisplay2: boolean): void {
    const isNotExist = !dataSettingExternalContent || !dataSettingExternalContent.length;
    if (isCheckDisplay2) {
      this.disabledButtonSet2s[type] = isNotExist;
    } else {
      this.disabledButtonSet1s[type] = isNotExist;
    }
  }

  /**
   * get data setting external content when change temple on drop down
   * @param id id template
   * @param type type of template
   * @param isDisplay2 true if display 2
   */
  private async getDataSettingExternalContentOfStyle(id: number, type: TemplateTypeEnum, isDisplay2: boolean) {
    if (this.data.screen == ScreenNameEnum.STATION_DISPLAY) {
      return;
    }
    let areaNew = await this.getPictureAreasExternalContentByTemplateId(id);
    if (!isDisplay2) {
      // change button set is not clicked
      if (this.checkClickSetDisplay1s[type]) {
        this.checkClickSetDisplay1s[type] = false;
      }
      // get data setting for template
      this.dataSettingExternalContentOfDisplay1[type] = this.generateDataExternalSetting(
        areaNew,
        this.samesExternalContentOfDisplay1[type]
      );
      this.checkExistAreaSetExternalContent(this.dataSettingExternalContentOfDisplay1[type], type, false);
    } else {
      // change button set is not clicked
      if (this.checkClickSetDisplay2s[type]) {
        this.checkClickSetDisplay2s[type] = false;
      }
      // get data setting for template
      this.dataSettingExternalContentOfDisplay2[type] = this.generateDataExternalSetting(
        areaNew,
        this.samesExternalContentOfDisplay2[type]
      );
      this.checkExistAreaSetExternalContent(this.dataSettingExternalContentOfDisplay2[type], type, true);
    }
  }

  /**
   * change template display1
   * @param id
   * @param value
   */
  async changeTemplatesDisplay1(id: Number, value) {
    switch (value) {
      case TemplateTypeEnum.MAIN:
        this.displayTemplate1.idMainPage = id == -1 ? undefined : id;
        this.getDataSettingExternalContentOfStyle(id as number, TemplateTypeEnum.MAIN, false);
        break;
      case TemplateTypeEnum.SUB_PAGE_1:
        this.displayTemplate1.idSubPage1 = id == -1 ? undefined : id;
        this.getDataSettingExternalContentOfStyle(id as number, TemplateTypeEnum.SUB_PAGE_1, false);
        break;
      case TemplateTypeEnum.SUB_PAGE_2:
        this.displayTemplate1.idSubPage2 = id == -1 ? undefined : id;
        this.getDataSettingExternalContentOfStyle(id as number, TemplateTypeEnum.SUB_PAGE_2, false);
        break;
      case TemplateTypeEnum.SUB_PAGE_3:
        this.displayTemplate1.idSubPage3 = id == -1 ? undefined : id;
        this.getDataSettingExternalContentOfStyle(id as number, TemplateTypeEnum.SUB_PAGE_3, false);
        break;
      case TemplateTypeEnum.SUB_PAGE_4:
        this.displayTemplate1.idSubPage4 = id == -1 ? undefined : id;
        this.getDataSettingExternalContentOfStyle(id as number, TemplateTypeEnum.SUB_PAGE_4, false);
        break;
      case TemplateTypeEnum.EMERGENCY:
        this.displayTemplate1.idEmergencyPage = id == -1 ? undefined : id;
        this.getDataSettingExternalContentOfStyle(id as number, TemplateTypeEnum.EMERGENCY, false);
        break;
      default:
        break;
    }
  }

  /**
   * change template display2
   * @param id
   * @param value
   */
  async changeTemplatesDisplay2(id: Number, value) {
    switch (value) {
      case TemplateTypeEnum.MAIN:
        this.displayTemplate2.idMainPage = id == -1 ? undefined : id;
        this.getDataSettingExternalContentOfStyle(id as number, TemplateTypeEnum.MAIN, true);
        break;
      case TemplateTypeEnum.SUB_PAGE_1:
        this.displayTemplate2.idSubPage1 = id == -1 ? undefined : id;
        this.getDataSettingExternalContentOfStyle(id as number, TemplateTypeEnum.SUB_PAGE_1, true);
        break;
      case TemplateTypeEnum.SUB_PAGE_2:
        this.displayTemplate2.idSubPage2 = id == -1 ? undefined : id;
        this.getDataSettingExternalContentOfStyle(id as number, TemplateTypeEnum.SUB_PAGE_2, true);
        break;
      case TemplateTypeEnum.SUB_PAGE_3:
        this.displayTemplate2.idSubPage3 = id == -1 ? undefined : id;
        this.getDataSettingExternalContentOfStyle(id as number, TemplateTypeEnum.SUB_PAGE_3, true);
        break;
      case TemplateTypeEnum.SUB_PAGE_4:
        this.displayTemplate2.idSubPage4 = id == -1 ? undefined : id;
        this.getDataSettingExternalContentOfStyle(id as number, TemplateTypeEnum.SUB_PAGE_4, true);
        break;
      case TemplateTypeEnum.EMERGENCY:
        this.displayTemplate2.idEmergencyPage = id == -1 ? undefined : id;
        this.getDataSettingExternalContentOfStyle(id as number, TemplateTypeEnum.EMERGENCY, true);
        break;
      default:
        break;
    }
  }

  /**
   * get picture area external content by id
   * @param id template id
   * @returns list picture area
   */
  private async getPictureAreasExternalContentByTemplateId(id: number): Promise<any> {
    if (id == -1) {
      return undefined;
    }
    let results;
    await this.pictureAreaService
      .getPictureAreasExternalContentByTemplateId(id)
      .toPromise()
      .then(data => {
        results = data;
      });
    return results;
  }

  /**
   * save change templates
   */
  public saveChangeTemplates(): void {
    if (!this.displayTemplate1.idMainPage || (this.data.isDisplay2 && !this.displayTemplate2.idMainPage)) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-error.title'),
          text: this.translateService.instant('dialog-change-template.select-main-page')
        }
      });
      // if change template for multiple style
    } else {
      // if all button set is clicked
      if (this.checkSetAllExternalContent()) {
        this.sendDataChangeTemplates();
        // if some button set is not clicked
      } else {
        this.dialogService.showDialog(
          DialogConfirmComponent,
          {
            data: {
              text: this.translateService.instant('dialog-change-template.has-save'),
              button1: this.translateService.instant('dialog-change-template.yes'),
              button2: this.translateService.instant('dialog-change-template.no')
            }
          },
          result => {
            if (!result) {
              return;
            }
            this.sendDataChangeTemplates();
          }
        );
      }
    }
  }

  /**
   * close popup and send data back to save
   */
  private async sendDataChangeTemplates(): Promise<void> {
    // check network
    let errorText = await this.commonService
      .checkNetWorkBeforeSave()
      .toPromise()
      .catch(error => {
        if (error.status == Constant.NETWORK_ERROR_CODE) {
          return Constant.NETWORK_ERROR_CODE;
        }
      });
    if (errorText == Constant.NETWORK_ERROR_CODE) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-error.title'),
          text: this.translateService.instant('dialog-error.error-network-api')
        }
      });
      return;
    }
    // if two display
    if (this.data.isDisplay2) {
      this.settingExternalContentForTemplateDisplay(this.dataSettingExternalContentOfDisplay1, this.displayTemplate1);
      this.settingExternalContentForTemplateDisplay(this.dataSettingExternalContentOfDisplay2, this.displayTemplate2);
      this.dialogRef.close([this.displayTemplate1, this.displayTemplate2]);
    } else {
      this.settingExternalContentForTemplateDisplay(this.dataSettingExternalContentOfDisplay1, this.displayTemplate1);
      this.dialogRef.close(this.displayTemplate1);
    }
  }

  /**
   * check clicked set button of all display
   * @returns true if all button set is clicked
   */
  private checkSetAllExternalContent(): boolean {
    let result = this.checkSetAllExternalContentDisplay(this.displayTemplate1, this.checkClickSetDisplay1s, this.disabledButtonSet1s);
    if (!this.data.isDisplay2) {
      return result;
    }
    return result && this.checkSetAllExternalContentDisplay(this.displayTemplate2, this.checkClickSetDisplay2s, this.disabledButtonSet2s);
  }

  /**
   * check clicked for one button set
   * @param display
   * @param checks
   * @param disabledButtonSet
   * @returns true if button clicked
   */
  private checkSetAllExternalContentDisplay(display: DisplayTemplate, checks: Array<boolean>, disabledButtonSet: Array<boolean>): boolean {
    if (this.data.screen == ScreenNameEnum.STATION_DISPLAY) {
      return true;
    }
    if (!display) {
      return true;
    }

    if (display.idMainPage && !disabledButtonSet[TemplateTypeEnum.MAIN] && !checks[TemplateTypeEnum.MAIN]) {
      return false;
    }

    if (display.idSubPage1 && !disabledButtonSet[TemplateTypeEnum.SUB_PAGE_1] && !checks[TemplateTypeEnum.SUB_PAGE_1]) {
      return false;
    }

    if (display.idSubPage2 && !disabledButtonSet[TemplateTypeEnum.SUB_PAGE_2] && !checks[TemplateTypeEnum.SUB_PAGE_2]) {
      return false;
    }

    if (display.idSubPage3 && !disabledButtonSet[TemplateTypeEnum.SUB_PAGE_3] && !checks[TemplateTypeEnum.SUB_PAGE_3]) {
      return false;
    }

    if (display.idSubPage4 && !disabledButtonSet[TemplateTypeEnum.SUB_PAGE_4] && !checks[TemplateTypeEnum.SUB_PAGE_4]) {
      return false;
    }

    if (display.idEmergencyPage && !disabledButtonSet[TemplateTypeEnum.EMERGENCY] && !checks[TemplateTypeEnum.EMERGENCY]) {
      return false;
    }

    return true;
  }

  /**
   * set up external content
   * @param typeOfTemplate
   * @param isDisplay2
   */
  public settingExternalContent(typeOfTemplate: TemplateTypeEnum, isDisplay2: boolean): void {
    if (this.data.screen == ScreenNameEnum.STATION_DISPLAY) {
      return;
    }
    let dataSettingExternalContent;
    // set clicked for button set
    if (isDisplay2) {
      dataSettingExternalContent = this.dataSettingExternalContentOfDisplay2[typeOfTemplate];
    } else {
      dataSettingExternalContent = this.dataSettingExternalContentOfDisplay1[typeOfTemplate];
    }
    // show dialog set up external content
    this.showDialogSettingExternalContent(dataSettingExternalContent, typeOfTemplate, isDisplay2);
  }

  /**
   * setting external content for template display
   * @param dataExternalSettings
   * @param displayTemplate
   */
  private settingExternalContentForTemplateDisplay(dataExternalSettings: any, displayTemplate: DisplayTemplate): void {
    displayTemplate.externalContentMainPage = this.getDataExternalSettingDisplayTemplate(dataExternalSettings[0]);
    displayTemplate.externalContentPage1 = this.getDataExternalSettingDisplayTemplate(dataExternalSettings[1]);
    displayTemplate.externalContentPage2 = this.getDataExternalSettingDisplayTemplate(dataExternalSettings[2]);
    displayTemplate.externalContentPage3 = this.getDataExternalSettingDisplayTemplate(dataExternalSettings[3]);
    displayTemplate.externalContentPage4 = this.getDataExternalSettingDisplayTemplate(dataExternalSettings[4]);
    displayTemplate.externalContentEmergencyPage = this.getDataExternalSettingDisplayTemplate(dataExternalSettings[5]);
  }

  /**
   * get data external setting to save
   * @param dataExternalSettings
   * @returns list data external setting to save
   */
  private getDataExternalSettingDisplayTemplate(dataExternalSettings: DataExternalSetting[]): DataExternalSetting[] {
    dataExternalSettings = dataExternalSettings?.filter(data => data.idExternalContent != -1);
    if (!dataExternalSettings?.length) {
      return undefined;
    }
    dataExternalSettings?.forEach(data => {
      delete data['nameArea'];
      delete data['width'];
      delete data['height'];
      delete data['objectFit'];
    });
    return dataExternalSettings;
  }

  /**
   * show dialog setting external content
   * @param areas list area external content
   * @param typeOfTemplate
   * @param isDisplay2
   */
  private showDialogSettingExternalContent(dataExternalSetting: any, typeOfTemplate: TemplateTypeEnum, isDisplay2: boolean): void {
    this.isHidden = true;
    this.dialogService.showDialog(DialogSettingExternalContentComponent, { data: dataExternalSetting }, results => {
      this.isHidden = false;
      if (!results) {
        return;
      }
      let isSettingExternalContent = results.every(item => item.idExternalContent != -1);
      if (isDisplay2) {
        this.dataSettingExternalContentOfDisplay2[typeOfTemplate] = results;
        this.checkClickSetDisplay2s[typeOfTemplate] = isSettingExternalContent;
      } else {
        this.dataSettingExternalContentOfDisplay1[typeOfTemplate] = results;
        this.checkClickSetDisplay1s[typeOfTemplate] = isSettingExternalContent;
      }
    });
  }

  /**
   * generate data external setting
   * @param pictureAreas
   * @param oldDataExternal
   * @returns data external setting
   */
  private generateDataExternalSetting(pictureAreas: PictureArea[], oldDataExternal: any): Array<any> {
    let dataExternalSettings: Array<any> = new Array<any>();
    pictureAreas?.forEach(area => {
      let dataExternalOld = oldDataExternal?.find(data => data.nameArea == area.name);
      let dataExternalSetting;
      if (dataExternalOld) {
        dataExternalSetting = new DataExternalSetting(area.id, dataExternalOld.idExternalContent);
      } else {
        dataExternalSetting = new DataExternalSetting(area.id, -1);
      }
      dataExternalSetting['nameArea'] = area.name;
      dataExternalSetting['width'] = area.width;
      dataExternalSetting['height'] = area.height;
      dataExternalSetting['objectFit'] = area.objectFit;
      dataExternalSettings.push(dataExternalSetting);
    });
    return dataExternalSettings;
  }
}

export interface DialogDataBID {
  display1: Template;
  display2: Template;
  isDisplay2: boolean;
  projectId: Number;
  screen: string;
}

export interface DialogData {
  displayTemplate1: DisplayTemplate;
  displayTemplate2: DisplayTemplate;
  isDisplay2: boolean;
  screen: string;
  dataExternalContent: Array<any>;
  isChangeInlineDisplay2: boolean;
}
