import { Helper } from 'app/common/helper';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommonTable } from 'app/model/entity/commonTable';
import { CommonTableService } from 'app/service/common-table.service';
import { Constant } from 'app/config/constants';
import { DialogService } from 'app/service/dialog.service';
import { DialogMessageComponent } from '../dialog-message/dialog-message.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'dialog-timetable-switch-timing-area',
  templateUrl: './dialog-timetable-switch-timing-area.component.html',
  styleUrls: ['./dialog-timetable-switch-timing-area.component.scss']
})
export class DialogTimetableSwitchTimingAreaComponent implements OnInit {
  /**
   * commonTable
   */
  commonTable: CommonTable;
  /**
   * constant
   */
  Constant = Constant;

  /**
   * timetable Switch Timing Area Form
   */
  timetableSwitchTimingAreaForm: FormGroup;

  constructor(
    private dialogRef: MatDialogRef<DialogTimetableSwitchTimingAreaComponent>,
    private commonTableService: CommonTableService,
    @Inject(MAT_DIALOG_DATA) public data: DialogSwitchAreaData,
    private formBuilder: FormBuilder,
    private dialogService: DialogService,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.commonTable = this.data.commonTable;
    this.timetableSwitchTimingAreaForm = this.formBuilder.group({
      seconds: [+this.commonTable.value, [Validators.required, Validators.max(Constant.MAX_TIME_SECONDS)]]
    });
  }

  /**
   * save
   */
  public save(): void {
    if (this.timetableSwitchTimingAreaForm.invalid) {
      return;
    }
    this.commonTable.value = this.timetableSwitchTimingAreaForm.value.seconds;
    this.commonTableService.save(this.commonTable).subscribe(
      () => {
        this.dialogRef.close(this.commonTable.value);
      },
      error => Helper.handleError(error, this.translateService, this.dialogService)
    );
  }

  /**
   * close dialog
   */
  public closeDialog(): void {
    this.dialogRef.close(undefined);
  }
}

export interface DialogSwitchAreaData {
  commonTable: CommonTable;
}
