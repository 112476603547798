import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Helper } from 'app/common/helper';
import { Constant, TypeMediaInDashBoard } from 'app/config/constants';
import { ConditionFilterDeviceLog } from 'app/model/entity/condition-filter-device-log';
import { ConditionFilterMediaLog } from 'app/model/entity/condition-filter-medialog';
import { ConditionMedia } from 'app/model/entity/condition-media';
import { CustomTag } from 'app/model/entity/custom-tag';
import { Device } from 'app/model/entity/device';
import { SimplePlaylist } from 'app/model/entity/simple/simple-playlist';
import { ConditionFilterDeviceLogService } from 'app/service/condition-filter-device-log.service';
import { ConditionFilterMediaLogService } from 'app/service/condition-filter-media-log.service';
import { DataService } from 'app/service/data.service';
import { DeviceService } from 'app/service/device.service';
import { DialogService } from 'app/service/dialog.service';
import { SimplePlaylistService } from 'app/service/simple/simple-playlist.service';
import _ from 'lodash';
import { Subject } from 'rxjs';
import { DialogMessageComponent } from '../dialog-message/dialog-message.component';

@Component({
  selector: 'dialog-extract-setting',
  templateUrl: './dialog-extract-setting.component.html',
  styleUrls: ['./dialog-extract-setting.component.scss']
})
export class DialogExtractSettingComponent implements OnInit {
  /**
   * Constant
   */
  private readonly ERROR_TITLE = 'error';
  private readonly COMMON_ERROR = 'common-error';
  private readonly IS_CHECKED = 'isChecked';
  /**
   * list custom tag
   */
  customTags: Array<CustomTag> = new Array<CustomTag>();
  /**
   * list device
   */
  devices: Array<Device>;
  /**
   * list playlists
   */
  playlists: Array<SimplePlaylist>;
  /**
   * list media owner
   */
  mediaOwners: Array<Object> = new Array<Object>();
  /**
   * condition filter media
   */
  conditionFilterMediaLog: ConditionFilterMediaLog;
  /**
   * condition filter device
   */
  conditionFilterDeviceLog: ConditionFilterDeviceLog;
  /**
   * subject load data
   */
  loadedData: Subject<boolean> = new Subject();
  /**
   * subject reset label content
   */
  resetLabelContentSubject: Subject<string> = new Subject();
  /**
   * subject load data device
   */
  loadedDataDevice: Subject<boolean> = new Subject();
  /**
   * Subject load data playlist
   */
  loadedDataPlaylists: Subject<boolean> = new Subject();
  /**
   * subject load data media owner
   */
  loadedDataOwner: Subject<boolean> = new Subject();
  /**
   * subject load data data type
   */
  loadedDataType: Subject<boolean> = new Subject();
  /**
   * subject for emit data output select box
   */
  getDataSubject: Subject<void> = new Subject();
  /**
   * list device selected
   */
  selectedDevices: Array<Device>;
  /**
   * list playlist selected
   */
  selectedPlaylists: Array<SimplePlaylist>;
  /**
   * list media type selected
   */
  selectedMediaTypes: Array<Object> = new Array<Object>();
  /**
   * Dialog title
   */
  public dialogTitle: string = 'Extraction Condition Setting';
  /**
   * Media types object
   */
  public mediaTypes: Array<Object> = new Array<Object>();
  @ViewChild('deviceID', { static: false })
  deviceID?: ElementRef;

  @ViewChild('fileName', { static: false })
  fileName?: ElementRef;

  /**
   * OptionTypeEnum
   */
  OptionTypeEnum = OptionTypeEnum;
  Constant = Constant;
  private readonly CUSTOM_TAG_TYPE = 'customTagType';

  constructor(
    public dialogRef: MatDialogRef<DialogExtractSettingComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private dialogService: DialogService,
    private deviceService: DeviceService,
    private conditionFilterMediaLogService: ConditionFilterMediaLogService,
    private translateService: TranslateService,
    private simplePlaylistService: SimplePlaylistService,
    private dataService: DataService,
    private conditionFilterDeviceLogService: ConditionFilterDeviceLogService
  ) {}

  ngOnInit(): void {
    this.dialogTitle = this.translateService.instant(
      `dialog-extraction-condition-setting.${this.data.isDeviceMonitor ? 'device-title' : 'report-title'}`
    );
    if (this.data.isDeviceMonitor) {
      this.conditionFilterDeviceLog = this.data.conditionFilterLog;
      this.customTags = this.data.customTags;
      this.changeCustomTag(this.conditionFilterDeviceLog['customTag01']?.id, Constant.CUSTOM_TAG_01_KEY, true);
      this.changeCustomTag(this.conditionFilterDeviceLog['customTag02']?.id, Constant.CUSTOM_TAG_02_KEY, true);
      this.changeCustomTag(this.conditionFilterDeviceLog['customTag03']?.id, Constant.CUSTOM_TAG_03_KEY, true);
      this.setCustomTag1Default();
      setTimeout(() => {
        this.loadedData.next();
      });
    } else {
      this.dataService.currentData.subscribe(() => {
        this.getDataSubject.next();
        this.loadedDataType.next();
      });
      this.conditionFilterMediaLog = this.data.conditionFilterLog;
      if (!this.conditionFilterMediaLog?.condition) {
        this.conditionFilterMediaLog.condition = new ConditionMedia();
      }
      this.fetchDevices();
      this.fetchPlaylists();
      this.fetchMediaTypes();
      setTimeout(() => {
        this.loadedDataType.next();
      });
    }
  }
  //================= TAB DEVICE MONITOR ====================

  /**
   * Set custom tag 1 default
   */
  private setCustomTag1Default(): void {
    if (!this.conditionFilterDeviceLog.userId) {
      // select option not blank
      let customTag = this.customTags?.find(data => data.name == Constant.SIGNAGE_GROUP);
      this.conditionFilterDeviceLog['customTag01'] = customTag;
      this.changeCustomTag(this.conditionFilterDeviceLog['customTag01']?.id, Constant.CUSTOM_TAG_01_KEY, true);
    }
  }
  /**
   * change custom tag
   *
   * @param value
   * @param customTagType
   * @param isFirstTime
   */
  public changeCustomTag(value: number, customTagType: string, isFirstTime?: boolean): void {
    let customTagOldSelected = this.customTags?.find(customTag => customTag[this.CUSTOM_TAG_TYPE] == customTagType);
    // reset disable option
    if (customTagOldSelected) {
      delete customTagOldSelected[Constant.IS_SELECTED];
      delete customTagOldSelected[this.CUSTOM_TAG_TYPE];
    }
    // first time not reset
    if (!isFirstTime) {
      this.resetOptionDefaultCustomTag(customTagType);
    }
    // if select option blank
    if (!value) {
      this.conditionFilterDeviceLog[customTagType] = undefined;
      setTimeout(() => {
        this.resetLabelContentSubject.next(customTagType);
      });
      return;
    }
    // select option not blank
    let customTag = this.customTags?.find(customTag => customTag.id == value);
    customTag[Constant.IS_SELECTED] = true;
    customTag[this.CUSTOM_TAG_TYPE] = customTagType;
    this.conditionFilterDeviceLog[customTagType] = customTag;
    setTimeout(() => {
      this.resetLabelContentSubject.next(customTagType);
    });
  }

  /**
   * reset option default custom tag element
   *
   * @param customTagType
   */
  private resetOptionDefaultCustomTag(customTagType: string): void {
    switch (customTagType) {
      case Constant.CUSTOM_TAG_01_KEY:
        this.conditionFilterDeviceLog[Constant.LIST_CUSTOM_TAG_ELEMENT_1] = [];
        break;
      case Constant.CUSTOM_TAG_02_KEY:
        this.conditionFilterDeviceLog[Constant.LIST_CUSTOM_TAG_ELEMENT_2] = [];
        break;
      case Constant.CUSTOM_TAG_03_KEY:
        this.conditionFilterDeviceLog[Constant.LIST_CUSTOM_TAG_ELEMENT_3] = [];
        break;
      default:
        break;
    }
  }

  //================= TAB CONTENT REPORT ====================

  /**
   * get list devices
   */
  private fetchDevices(): void {
    this.deviceService.getDevice().subscribe(
      data => {
        if (!data) {
          return;
        }
        this.devices = _.sortBy(data, ['id']);
        this.selectedDevices = this.devices.filter(device => this.conditionFilterMediaLog?.condition?.deviceIds?.includes(device.id));
        if (this.selectedDevices.length == 0) {
          this.devices.forEach(device => (device.isChecked = true));
          this.selectedDevices = this.devices;
        }
        setTimeout(() => {
          this.loadedDataDevice.next();
        });
      },
      () => this.handleShowMessage(this.ERROR_TITLE, this.COMMON_ERROR)
    );
  }

  /**
   * Fetch playlist
   */
  private fetchPlaylists(): void {
    this.simplePlaylistService.getAllPlaylists().subscribe(
      playlistsResponse => {
        if (!playlistsResponse) {
          return;
        }
        this.playlists = Helper.convertDataSimplePlaylists(playlistsResponse);
        this.selectedPlaylists = this.playlists.filter(ply => this.conditionFilterMediaLog?.condition?.playlistIds?.includes(ply.id));
        if (this.selectedPlaylists.length == 0) {
          this.playlists.forEach(data => (data[this.IS_CHECKED] = true));
          this.selectedPlaylists = this.playlists;
        }
        setTimeout(() => {
          this.loadedDataPlaylists.next();
        });
      },
      () => this.handleShowMessage(this.ERROR_TITLE, this.COMMON_ERROR)
    );
  }

  /**
   * Fetch media types
   */
  private fetchMediaTypes(): void {
    this.mediaTypes = [
      new Object(TypeMediaInDashBoard.VIDEO, this.translateService.instant('dialog-extraction-condition-setting.video-type')),
      new Object(TypeMediaInDashBoard.IMAGE, this.translateService.instant('dialog-extraction-condition-setting.image-type'))
    ];
    this.selectedMediaTypes = this.mediaTypes.filter(data => this.conditionFilterMediaLog?.condition?.mediaTypes?.includes(data.id));
    if (this.selectedMediaTypes.length == 0) {
      this.mediaTypes.forEach(data => (data[this.IS_CHECKED] = true));
      this.selectedMediaTypes = this.mediaTypes;
    }
    setTimeout(() => {
      this.loadedDataType.next();
    });
  }

  /**
   * handle button enable
   */
  public enable(): void {
    this.getDataSubject.next();
    if (!this.validateData()) {
      return;
    }
    if (this.data.isDeviceMonitor) {
      this.conditionFilterDeviceLog['userId'] = this.data.userId;
      // save and get devices with new condition
      this.conditionFilterDeviceLogService
        .saveConditionFilterDeviceLog(Helper.convertDataFilterDeviceLogToSave(this.conditionFilterDeviceLog))
        .subscribe(
          dataResponse => {
            this.dialogRef.close(dataResponse);
          },
          error => Helper.handleError(error, this.translateService, this.dialogService)
        );
    } else {
      if (this.validatePeriod()) {
        this.dialogService.showDialog(DialogMessageComponent, {
          data: {
            title: this.translateService.instant(`dashboard.message.${this.ERROR_TITLE}`),
            text: this.translateService.instant(`dashboard.message.${Constant.INVALID_DATE}`)
          }
        });
        this.dialogRef.close();
        return;
      }
      if (!this.conditionFilterMediaLog.condition.deviceIds) {
        this.conditionFilterMediaLog.condition.deviceIds = this.devices.map(device => device.id);
      }
      if (!this.conditionFilterMediaLog.condition.playlistIds) {
        this.conditionFilterMediaLog.condition.playlistIds = this.playlists.map(playlist => playlist.id);
      }
      if (!this.conditionFilterMediaLog.condition.mediaTypes) {
        this.conditionFilterMediaLog.condition.mediaTypes = this.mediaTypes.map(media => media.id);
      }
      this.conditionFilterMediaLog.condition.fileName =
        this.conditionFilterMediaLog.condition.fileName.trim().length == 0 ? undefined : this.conditionFilterMediaLog.condition.fileName;
      this.conditionFilterMediaLogService.saveConditionFilterMediaLog(this.conditionFilterMediaLog).subscribe(
        data => {
          this.dialogRef.close(Helper.convertDataFilterMediaLogBackward(data));
        },
        error => Helper.handleError(error, this.translateService, this.dialogService)
      );
    }
  }

  /**
   * get list custom tag element id selected
   * @param value
   * @param index
   */
  public getCustomTagElementId(value: any, key: string): void {
    switch (key) {
      case Constant.CUSTOM_TAG_01_KEY:
        this.conditionFilterDeviceLog.customTagElement1Ids = value?.map(data => data.id);
        break;
      case Constant.CUSTOM_TAG_02_KEY:
        this.conditionFilterDeviceLog.customTagElement2Ids = value?.map(data => data.id);
        break;
      case Constant.CUSTOM_TAG_03_KEY:
        this.conditionFilterDeviceLog.customTagElement3Ids = value?.map(data => data.id);
        break;
      default:
        break;
    }
  }
  /**
   * get data condition after click enable
   * @param value
   * @param key
   */
  public getDataCondition(value: any, key: string): void {
    const data = value?.map(item => item?.id);
    switch (key) {
      case OptionTypeEnum.DEVICE:
        this.conditionFilterMediaLog.condition.deviceIds = data?.length > 0 ? data : null;
        break;
      case OptionTypeEnum.MEDIA_TYPE:
        this.conditionFilterMediaLog.condition.mediaTypes = data?.length > 0 ? data : null;
        break;
      case OptionTypeEnum.PLAYLIST:
        this.conditionFilterMediaLog.condition.playlistIds = data?.length > 0 ? data : null;
        break;
      default:
        break;
    }
  }

  /**
   * validate data
   */
  validateData(): boolean {
    if (this.data.isDeviceMonitor) {
      this.conditionFilterDeviceLog.deviceId = this.conditionFilterDeviceLog.deviceId ?? '';
      if (
        this.conditionFilterDeviceLog.deviceId.trim().length > 0 &&
        this.conditionFilterDeviceLog.deviceId.length > Constant.MAX_LENGTH_DEVICE_ID
      ) {
        this.deviceID.nativeElement.focus();
        this.dialogService.showDialog(DialogMessageComponent, {
          data: {
            title: this.translateService.instant('dialog-extraction-condition-setting.message.error'),
            text: Helper.formatString(
              this.translateService.instant('dialog-extraction-condition-setting.message.device-id-max-length'),
              `${Constant.MAX_LENGTH_DEVICE_ID}`
            )
          }
        });
        return false;
      }
    } else {
      this.conditionFilterMediaLog.condition.fileName = this.conditionFilterMediaLog.condition.fileName ?? '';
      if (
        this.conditionFilterMediaLog.condition.fileName.trim().length > 0 &&
        this.conditionFilterMediaLog.condition.fileName.length > Constant.MAX_LENGTH_FULL_NAME
      ) {
        this.fileName.nativeElement.focus();
        this.dialogService.showDialog(DialogMessageComponent, {
          data: {
            title: this.translateService.instant('dialog-extraction-condition-setting.message.error'),
            text: Helper.formatString(
              this.translateService.instant('dialog-extraction-condition-setting.message.full-name-max-length'),
              `${Constant.MAX_LENGTH_FULL_NAME}`
            )
          }
        });
        return false;
      }
    }
    return true;
  }
  /**
   * Handle show message
   * @param title
   * @param messageCode
   */
  private handleShowMessage(title: string, messageCode: string): void {
    this.dialogService.showDialog(DialogMessageComponent, {
      data: {
        title: this.translateService.instant(`dialog-extraction-condition-setting.message.${title}`),
        text: this.translateService.instant(`dialog-extraction-condition-setting.message.${messageCode}`)
      }
    });
  }

  /**
   * validate period
   * @returns
   */
  private validatePeriod(): boolean {
    if (!this.conditionFilterMediaLog.dateTo || !this.conditionFilterMediaLog.dateFrom) {
      return true;
    }
    return new Date(this.conditionFilterMediaLog.dateFrom).getTime() > new Date(this.conditionFilterMediaLog.dateTo).getTime();
  }
}

export interface DialogData {
  isDeviceMonitor: boolean;
  conditionFilterLog: any;
  customTags: Array<CustomTag>;
  userId: Number;
}

export enum OptionTypeEnum {
  DEVICE = 'device',
  PLAYLIST = 'playlist',
  MEDIA_TYPE = 'mediaType'
}

export class Object {
  id: string;
  name: string;
  isChecked: boolean;
  constructor(id?: string, name?: string) {
    this.id = id;
    this.name = name;
  }
}
