import { ScheduleRegistrationService } from './../../service/schedule-registration.service';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { DeviceCalendar } from 'app/model/entity/device-calendar';
import { SimplePlaylist } from 'app/model/entity/simple/simple-playlist';
import { Timetable } from 'app/model/entity/timetable';
import { CommonService } from 'app/service/common.service';
import { IndexWordService } from 'app/service/index-word.service';
import { SimplePlaylistService } from 'app/service/simple/simple-playlist.service';
import { TimetableService } from 'app/service/timetable.service';
import _ from 'lodash';
import { Helper } from '../../common/helper';
import { Constant, RepeatModeEnum, ScreenNameEnum, TypeDataSettingContentDayEnum } from '../../config/constants';
import { Channel } from '../../model/entity/channel';
import { ContentDay } from '../../model/entity/content-day';
import { DailySchedule } from '../../model/entity/playlist';
import { DialogService } from '../../service/dialog.service';
import { SignageDailyScheduleService } from '../../service/signage-playlist.service';
import { DialogConfirmComponent } from '../dialog-confirm/dialog-confirm.component';
import { DialogMessageComponent } from '../dialog-message/dialog-message.component';
import { ScheduleRegistrationDetail } from 'app/model/entity/schedule-registration-detail';
import { TimetableSchedule } from 'app/model/entity/timetable-schedule';
import { ScheduleRegistration } from 'app/model/entity/schedule-registration';
@Component({
  selector: 'app-dialog-playlist-recurrence',
  templateUrl: './dialog-playlist-recurrence.component.html',
  styleUrls: ['./dialog-playlist-recurrence.component.scss']
})
/**
 * Component class for dialog setting playlist recurrence
 */
export class DialogPlaylistRecurrenceComponent implements OnInit {
  /**
   * repeat mode enum
   */
  RepeatModeEnum = RepeatModeEnum;
  /**
   * list of playlists
   */
  playlists: Array<DailySchedule>;
  /**
   * list date
   */
  listDate: Array<Date> = new Array<Date>();
  /**
   * list Day
   */
  listDay: Array<[string, boolean, string, number]> = [
    ['Sun', false, '日', 0],
    ['Mon', false, '月', 1],
    ['Tue', false, '火', 2],
    ['Web', false, '水', 3],
    ['Thu', false, '木', 4],
    ['Fri', false, '金', 5],
    ['Sat', false, '土', 6]
  ];
  /**
   * projectId
   */
  projectId: Number;
  /**
   * true if error (data daily schedule unsaved)
   */
  isError: boolean;
  /**
   * timetables
   */
  timetables: Array<Timetable>;
  /**
   * ScreenNameEnum
   */
  ScreenNameEnum = ScreenNameEnum;
  /**
   * langKey
   */
  langKey: string;
  /**
   * list simple play list in simple signage display
   */
  playlistsSimple: Array<SimplePlaylist>;
  /**
   * type data setting for content day
   */
  typeDataSettingContentDay: string;
  /**
   * old calendars
   */
  oldCalendars: Array<ContentDay>;

  /**
   * schedules
   */
  timetablesScheduleRegistration: Array<ScheduleRegistrationDetail>;

  /**
   * Is unlimited
   */
  public isUnlimited: boolean = false;

  constructor(
    private playlistService: SignageDailyScheduleService,
    private dialogService: DialogService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public dialogRef: MatDialogRef<DialogPlaylistRecurrenceComponent>,
    private timetableService: TimetableService,
    private translateService: TranslateService,
    private simplePlaylistService: SimplePlaylistService,
    private commonService: CommonService,
    private indexWordService: IndexWordService,
    private scheduleRegistrationService: ScheduleRegistrationService
  ) {
    this.data.contentDay.startDate = this.data.contentDay.fullDate;
    let startDate = _.cloneDeep(this.data.contentDay.startDate);
    startDate.setMonth(startDate.getMonth() + 1);
    this.data.contentDay.finishDate = Helper.getDateByDay(startDate.getFullYear(), startDate.getMonth() + 1, startDate.getDate());
    this.data.contentDay.isRepeated = false;
  }

  ngOnInit(): void {
    this.langKey = this.commonService.getCommonObject().setting?.language;
    this.projectId = this.commonService.getCommonObject().projectId;
    if (this.data?.screen == ScreenNameEnum.SIMPLE_SIGNAGE) {
      this.typeDataSettingContentDay = TypeDataSettingContentDayEnum.SS;
      // simple signage screen
      this.simplePlaylistService.getAllPlaylists().subscribe(
        data => {
          if (!data || !data?.length) {
            return;
          }
          this.playlistsSimple = Helper.convertDataSimplePlaylistsCalendar(data);
        },
        error => {
          this.dialogRef.close();
          this.handleError();
        }
      );
    } else if (this.data?.screen == ScreenNameEnum.SCHEDULE_REGISTRATION) {
      // SR screen
      this.typeDataSettingContentDay = TypeDataSettingContentDayEnum.SR;
      this.oldCalendars = _.cloneDeep(this.data.calendars);
      this.scheduleRegistrationService.getAllScheduleRegistrations().subscribe(
        data => {
          this.timetablesScheduleRegistration = Helper.convertScheduleRegistrationAfterImport(data, this.data.timeDateLine);
        },
        error => {
          this.dialogRef.close();
          this.handleError();
        }
      );
    } else if (this.data?.screen != ScreenNameEnum.TIME_TABLE) {
      // DSC screen
      this.typeDataSettingContentDay = TypeDataSettingContentDayEnum.DSC;
      this.playlistService.getDailySchedulesByProjectId(this.projectId).subscribe(
        data => {
          this.playlists = data;
        },
        error => {
          this.dialogRef.close();
          this.handleError();
        }
      );
    } else {
      // TT screen
      this.typeDataSettingContentDay = TypeDataSettingContentDayEnum.TT;
      this.oldCalendars = _.cloneDeep(this.data.calendars);
      this.timetableService.getTimetablesWithSchedule().subscribe(
        data => {
          this.timetables = Helper.convertDataTimetablesForCalendar(data, this.data.timeDateLine);
        },
        error => {
          this.dialogRef.close();
          this.handleError();
        }
      );
    }
  }

  /**
   * change playlist
   * @param {number} playlistId id of selected playlist
   */
  public changePlaylist(playlistId: number): void {
    this.data.contentDay.playlist = this.playlists.find(playlist => playlist.id == playlistId);
    this.isError = this.data.isChangeDailySchedule && this.data.contentDay.playlist.id === this.data.playlist.id;
  }

  /**
   * change playlist
   * @param {number} playlistId id of selected playlist
   */
  public changeSimplePlaylist(playlistId: number): void {
    this.data.contentDay.playlistSimple = this.playlistsSimple.find(playlist => playlist.id == playlistId);
    this.data.contentDay.playlistSimpleId = playlistId;
  }

  /**
   * change repeat mode of current playlist
   * @param mode repeat mode
   */
  public changeRepeatMode(mode: string): void {
    this.data.contentDay.repeatMode = mode;
  }

  /**
   * change start date of contentDay
   * @param event change event
   */
  public setStartDate(event): void {
    let stringDate = (event as string).split('-');
    this.data.contentDay.startDate = Helper.getDateByDay(+stringDate[0], +stringDate[1], +stringDate[2]);
  }

  /**
   * change checked day
   * @param index index
   */
  public changeChecked(index: number): void {
    this.listDay[index][1] = !this.listDay[index][1];
  }

  /**
   * change end date of contentDay
   * @param event change event
   */
  public setEndDate(event): void {
    if (this.isUnlimited) {
      return;
    }
    let stringDate = (event as string).split('-');
    this.data.contentDay.finishDate = Helper.getDateByDay(+stringDate[0], +stringDate[1], +stringDate[2]);
  }

  /**
   * get date repeat daily schedule repeat mode: Every day
   */
  public getDatesRepeatDailyScheduleEveryDay(): number {
    this.listDate = new Array<Date>();
    let startDate = this.data.contentDay.startDate;
    let endDate = this.data.contentDay.finishDate;
    let calendars: ContentDay[];
    if (this.data?.screen == ScreenNameEnum.SCHEDULE_REGISTRATION) {
      calendars = this.data.calendars;
    } else {
      calendars = this.data?.channel ? this.data.channel.calendarDays : this.data.device.calendarDays;
    }
    let dateContains = Helper.getDateContainContentDayCurrentMonth(calendars, this.data.contentDay, this.typeDataSettingContentDay);

    if (endDate.getMonth() == startDate.getMonth()) {
      let dayInsMonth = Helper.daysInMonth(endDate);
      if (endDate.getDate() <= dayInsMonth) {
        dateContains.forEach(date => {
          if (date.getTime() <= endDate.getTime()) {
            this.listDate.push(date);
          }
        });
      }
    } else {
      this.listDate = [...dateContains];
    }

    // get data repeat next month same year
    if (startDate.getFullYear() == endDate.getFullYear()) {
      for (let month = startDate.getMonth() + 2; month <= endDate.getMonth() + 1; month++) {
        let dateContains = Helper.getDateContainContentDayOtherMonth(
          calendars,
          month,
          startDate.getFullYear(),
          this.typeDataSettingContentDay
        );
        dateContains.forEach(date => {
          if (date.getTime() <= endDate.getTime()) {
            this.listDate.push(date);
          }
        });
      }
    } else if (startDate.getFullYear() < endDate.getFullYear()) {
      // get data repeat next month -> 12 current year
      for (let month = startDate.getMonth() + 2; month <= 12; month++) {
        let dateContains = Helper.getDateContainContentDayOtherMonth(
          calendars,
          month,
          startDate.getFullYear(),
          this.typeDataSettingContentDay
        );
        dateContains.forEach(date => {
          this.listDate.push(date);
        });
      }

      // get data repeat month = 1 -> current month of next year
      for (let month = 1; month <= endDate.getMonth() + 1; month++) {
        let dateContains = Helper.getDateContainContentDayOtherMonth(
          calendars,
          month,
          endDate.getFullYear(),
          this.typeDataSettingContentDay
        );
        dateContains.forEach(date => {
          if (date.getTime() <= endDate.getTime()) {
            this.listDate.push(date);
          }
        });
      }
    }

    return this.listDate.length;
  }

  /**
   * get date repeat daily schedule repeat mode: Every week
   */
  public getDatesRepeatDailyScheduleEveryWeek(): number {
    this.listDate = new Array<Date>();
    let startDate = this.data.contentDay.startDate;
    let endDate = this.data.contentDay.finishDate;
    let daysSelected: Array<number> = new Array<number>();
    let calendars;
    this.listDay.forEach((day, index) => {
      if (day[1]) {
        daysSelected.push(index);
      }
    });
    if (this.data?.screen == ScreenNameEnum.SCHEDULE_REGISTRATION) {
      calendars = this.data.calendars;
    } else {
      calendars = this.data?.channel ? this.data.channel.calendarDays : this.data.device.calendarDays;
    }
    let dateContains = Helper.getDateContainContentDayCurrentMonth(calendars, this.data.contentDay, this.typeDataSettingContentDay);
    if (endDate.getMonth() == startDate.getMonth()) {
      let dayInsMonth = Helper.daysInMonth(endDate);
      if (endDate.getDate() <= dayInsMonth) {
        dateContains.forEach(date => {
          if (date.getTime() <= endDate.getTime() && daysSelected.findIndex(dayIndex => dayIndex == date.getDay()) != -1) {
            this.listDate.push(date);
          }
        });
      }
    } else {
      this.listDate = [...dateContains].filter(date => daysSelected.findIndex(dayIndex => dayIndex == date.getDay()) != -1);
    }

    // get data repeat next month
    if (startDate.getFullYear() == endDate.getFullYear()) {
      for (let month = startDate.getMonth() + 2; month <= endDate.getMonth() + 1; month++) {
        let dateContains = Helper.getDateContainContentDayOtherMonth(
          calendars,
          month,
          startDate.getFullYear(),
          this.typeDataSettingContentDay
        );
        dateContains.forEach(date => {
          if (date.getTime() <= endDate.getTime() && daysSelected.findIndex(dayIndex => dayIndex == date.getDay()) != -1) {
            this.listDate.push(date);
          }
        });
      }
    } else if (startDate.getFullYear() < endDate.getFullYear()) {
      // get data repeat next month -> 12 current year
      for (let month = startDate.getMonth() + 2; month <= 12; month++) {
        let dateContains = Helper.getDateContainContentDayOtherMonth(
          calendars,
          month,
          startDate.getFullYear(),
          this.typeDataSettingContentDay
        );
        dateContains.forEach(date => {
          if (daysSelected.findIndex(dayIndex => dayIndex == date.getDay()) != -1) {
            this.listDate.push(date);
          }
        });
      }

      // get data repeat month = 1 -> current month of next year
      for (let month = 1; month <= endDate.getMonth() + 1; month++) {
        let dateContains = Helper.getDateContainContentDayOtherMonth(
          calendars,
          month,
          endDate.getFullYear(),
          this.typeDataSettingContentDay
        );
        dateContains.forEach(date => {
          if (date.getTime() <= endDate.getTime() && daysSelected.findIndex(dayIndex => dayIndex == date.getDay()) != -1) {
            this.listDate.push(date);
          }
        });
      }
    }
    return this.listDate.length;
  }

  /**
   * validate for DSC screen
   *
   * @returns
   */
  private async validateDSC(): Promise<boolean> {
    if (!this.data.contentDay.playlist) {
      this.dialogService.showDialog(DialogMessageComponent, { data: { title: 'Error', text: 'Please choose a daily schedule.' } });
      return false;
    }
    this.isError = this.data.isChangeDailySchedule && this.data.contentDay.playlist.id === this.data.playlist.id;
    if (this.isError) {
      return false;
    }
    return true;
  }

  /**
   * validate for Timetable screen
   *
   * @returns
   */
  private async validateTimetable(): Promise<boolean> {
    if (!this.data.contentDay.timetable) {
      return true;
    }
    this.data.contentDay.timetable = this.timetables.find(timetable => timetable.id == this.data.contentDay.timetable.id);
    // validate timetable no main page display 1
    if (!this.data.contentDay.timetable?.displayTemplate1?.idMainPage) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-error.title'),
          text: this.translateService.instant('dialog-playlist-recurrence.no-template')
        }
      });
      return false;
    }
    // validate schedule include time invalid
    if (this.data.contentDay.timetable?.timetableSchedule?.itemDetails?.some(itemDetail => itemDetail?.isInValidFormat)) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-error.title'),
          text: this.translateService.instant('dialog-playlist-recurrence.time-invalid')
        }
      });
      this.dialogRef.close(false);
      return false;
    }
    // check network
    let errorText = await this.commonService
      .checkNetWorkBeforeSave()
      .toPromise()
      .catch(error => {
        if (error.status == Constant.NETWORK_ERROR_CODE) {
          return Constant.NETWORK_ERROR_CODE;
        }
      });
    if (errorText == Constant.NETWORK_ERROR_CODE) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-error.title'),
          text: this.translateService.instant('dialog-error.error-network-api')
        }
      });
      return false;
    }
    // validate schedule include index word invalid
    if (await Helper.validateIndexWordInvalid([this.data.contentDay.timetable], this.indexWordService)) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-error.title'),
          text: this.translateService.instant('dialog-playlist-recurrence.index-word-invalid')
        }
      });
      this.dialogRef.close(false);
      return false;
    }
    return true;
  }

  /**
   * validate for Simple Signage screen
   *
   * @returns
   */
  private async validateSimpleSignage(): Promise<boolean> {
    // check network
    let errorText = await this.commonService
      .checkNetWorkBeforeSave()
      .toPromise()
      .catch(error => {
        if (error.status == Constant.NETWORK_ERROR_CODE) {
          return Constant.NETWORK_ERROR_CODE;
        }
      });
    if (errorText == Constant.NETWORK_ERROR_CODE) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-error.title'),
          text: this.translateService.instant('dialog-error.error-network-api')
        }
      });
      return false;
    }
    return true;
  }
  /**
   * save change
   */
  public async saveChange(): Promise<void> {
    if (this.data?.screen === ScreenNameEnum.TIME_TABLE) {
      if (!(await this.validateTimetable())) {
        return;
      }
    } else if (this.data?.screen === ScreenNameEnum.DIGITAL_SIGNAGE_CHANNEL_DISPLAY) {
      // validate if DSC screen
      if (!this.validateDSC()) {
        return;
      }
    } else if (this.data?.screen === ScreenNameEnum.SIMPLE_SIGNAGE) {
      if (!(await this.validateSimpleSignage())) {
        return;
      }
    } else if (this.data?.screen === ScreenNameEnum.SCHEDULE_REGISTRATION) {
      if (!(await this.validateScheduleRegistration())) {
        return;
      }
    }
    let date = new Date();
    date.setFullYear(date.getFullYear() + Constant.MAX_YEAR);
    date.setDate(date.getDate() - 1);
    this.data.contentDay.finishDate = this.isUnlimited
      ? Helper.getDateByDay(date.getFullYear(), date.getMonth() + 1, date.getDate())
      : this.data.contentDay.finishDate;
    // validate start date/ end date (mode repeat)
    if (this.data.contentDay.isRepeated) {
      // validate start date
      const currentDate = Helper.getDateByDay(new Date().getFullYear(), new Date().getMonth() + 1, new Date().getDate());
      if (this.data.contentDay.startDate < currentDate || this.data.contentDay.startDate > this.data.contentDay.finishDate) {
        this.dialogService.showDialog(DialogMessageComponent, {
          data: {
            title: this.translateService.instant('dialog-error.title'),
            text: this.translateService.instant('dialog-playlist-recurrence.start-date-invalid')
          }
        });
        return;
      }
      // validate repeat mode
      if (!this.data.contentDay.repeatMode) {
        this.dialogService.showDialog(DialogMessageComponent, {
          data: {
            title: this.translateService.instant('dialog-error.title'),
            text: this.translateService.instant('dialog-playlist-recurrence.choose-repeat-mode')
          }
        });
        return;
      }
      // validate end date

      let dateEnd = _.cloneDeep(currentDate);
      dateEnd.setFullYear(dateEnd.getFullYear() + Constant.MAX_YEAR);
      dateEnd = Helper.getDateByDay(dateEnd.getFullYear(), dateEnd.getMonth() + 1, dateEnd.getDate());
      if (this.data.contentDay.finishDate >= dateEnd || this.data.contentDay.finishDate < this.data.contentDay.startDate) {
        this.dialogService.showDialog(DialogMessageComponent, {
          data: {
            title: this.translateService.instant('dialog-error.title'),
            text: this.translateService.instant('dialog-playlist-recurrence.finish-date-invalid')
          }
        });
        return;
      }
    }

    // validate duplicate schedule
    if (this.data.screen == ScreenNameEnum.TIME_TABLE) {
      if (!this.validateDuplicateSchedule()) {
        this.oldCalendars = _.cloneDeep(this.data.calendars);
        this.dialogService.showDialog(DialogMessageComponent, {
          data: {
            title: this.translateService.instant('dialog-error.title'),
            text: this.translateService.instant('dialog-playlist-recurrence.duplicate-schedule')
          }
        });
        return;
      }
    }

    if (this.data.screen == ScreenNameEnum.SCHEDULE_REGISTRATION) {
      if (!this.validateDuplicateScheduleRegistration()) {
        this.oldCalendars = _.cloneDeep(this.data.calendars);
        this.dialogService.showDialog(DialogMessageComponent, {
          data: {
            title: this.translateService.instant('dialog-error.title'),
            text: this.translateService.instant('dialog-playlist-recurrence.duplicate-schedule')
          }
        });
        return;
      }
    }
    let index = 0;
    let timetableScheduleScreen = null;
    // validate duplicate data content day
    if (this.data.contentDay.isRepeated) {
      let number = 0;
      if (this.data.contentDay.repeatMode == RepeatModeEnum.EVERY_DAY) {
        number = this.getDatesRepeatDailyScheduleEveryDay();
      } else if (!this.listDay.some(day => day[1])) {
        this.dialogService.showDialog(DialogMessageComponent, {
          data: {
            title: this.translateService.instant('dialog-error.title'),
            text: this.translateService.instant('dialog-playlist-recurrence.no-day-repeat')
          }
        });
        return;
      } else {
        number = this.getDatesRepeatDailyScheduleEveryWeek();
      }
      if (number > 0) {
        const screenDSCMessage = 'A daily schedule has already been registered on some days. Do you want to overwrite?';
        this.dialogService.showDialog(
          DialogConfirmComponent,
          {
            data: {
              text:
                this.data.screen === ScreenNameEnum.TIME_TABLE || this.data.screen === ScreenNameEnum.SCHEDULE_REGISTRATION
                  ? this.translateService.instant('dialog-playlist-recurrence.overwrite-timetable')
                  : this.data.screen === ScreenNameEnum.SIMPLE_SIGNAGE
                  ? this.translateService.instant('dialog-playlist-recurrence.overwrite-simple-playlist')
                  : screenDSCMessage,
              button1: this.translateService.instant('dialog-playlist-recurrence.yes'),
              button2: this.translateService.instant('dialog-playlist-recurrence.no')
            }
          },
          result => {
            if (result) {
              if (this.data?.screen == ScreenNameEnum.TIME_TABLE || this.data?.screen == ScreenNameEnum.SCHEDULE_REGISTRATION) {
                index =
                  this.data?.screen == ScreenNameEnum.TIME_TABLE
                    ? this.timetables.findIndex(timetable => timetable.id == this.data.contentDay?.timetable?.id)
                    : this.timetablesScheduleRegistration.findIndex(
                        schedule => schedule.id == this.data.contentDay?.scheduleRegistration?.id
                      );
                if (index != -1) {
                  timetableScheduleScreen =
                    this.data?.screen == ScreenNameEnum.TIME_TABLE
                      ? this.timetables[index].timetableSchedule
                      : this.timetablesScheduleRegistration[index].schedules != null &&
                        this.timetablesScheduleRegistration[index].schedules.length > 0
                      ? true
                      : false;
                  if (!timetableScheduleScreen) {
                    this.dialogService.showDialog(
                      DialogConfirmComponent,
                      {
                        data: {
                          text: this.translateService.instant('dialog-playlist-recurrence.no-schedule'),
                          button1: this.translateService.instant('dialog-playlist-recurrence.yes'),
                          button2: this.translateService.instant('dialog-playlist-recurrence.no')
                        }
                      },
                      result => {
                        if (result) {
                          this.dialogRef.close([this.data.contentDay, this.listDay, this.isUnlimited, this.data?.screen]);
                        }
                      }
                    );
                    return;
                  }
                }
              }
              this.dialogRef.close([this.data.contentDay, this.listDay, this.isUnlimited, this.data?.screen]);
            } else {
              return;
            }
          }
        );
      } else {
        if (this.data?.screen == ScreenNameEnum.TIME_TABLE || this.data?.screen == ScreenNameEnum.SCHEDULE_REGISTRATION) {
          index =
            this.data?.screen == ScreenNameEnum.TIME_TABLE
              ? this.timetables.findIndex(timetable => timetable.id == this.data.contentDay?.timetable?.id)
              : this.timetablesScheduleRegistration.findIndex(schedule => schedule.id == this.data.contentDay?.scheduleRegistration?.id);

          if (index != -1) {
            timetableScheduleScreen =
              this.data?.screen == ScreenNameEnum.TIME_TABLE
                ? this.timetables[index].timetableSchedule
                : this.timetablesScheduleRegistration[index].schedules != null &&
                  this.timetablesScheduleRegistration[index].schedules.length > 0
                ? true
                : false;
            if (!timetableScheduleScreen) {
              this.dialogService.showDialog(
                DialogConfirmComponent,
                {
                  data: {
                    text: this.translateService.instant('dialog-playlist-recurrence.no-schedule'),
                    button1: this.translateService.instant('dialog-playlist-recurrence.yes'),
                    button2: this.translateService.instant('dialog-playlist-recurrence.no')
                  }
                },
                result => {
                  if (result) {
                    this.dialogRef.close([this.data.contentDay, this.listDay, this.isUnlimited, this.data?.screen]);
                  }
                }
              );
              return;
            }
          }
        }
        this.dialogRef.close([this.data.contentDay, this.listDay, this.isUnlimited, this.data?.screen]);
      }
    } else {
      if (this.data?.screen == ScreenNameEnum.TIME_TABLE || this.data?.screen == ScreenNameEnum.SCHEDULE_REGISTRATION) {
        index =
          this.data?.screen == ScreenNameEnum.TIME_TABLE
            ? this.timetables.findIndex(timetable => timetable.id == this.data.contentDay?.timetable?.id)
            : this.timetablesScheduleRegistration.findIndex(schedule => schedule.id == this.data.contentDay?.scheduleRegistration?.id);
        if (index != -1) {
          timetableScheduleScreen =
            this.data?.screen == ScreenNameEnum.TIME_TABLE
              ? this.timetables[index].timetableSchedule
              : this.timetablesScheduleRegistration[index].schedules != null &&
                this.timetablesScheduleRegistration[index].schedules.length > 0
              ? true
              : false;
          if (!timetableScheduleScreen) {
            this.dialogService.showDialog(
              DialogConfirmComponent,
              {
                data: {
                  text: this.translateService.instant('dialog-playlist-recurrence.no-schedule'),
                  button1: this.translateService.instant('dialog-playlist-recurrence.yes'),
                  button2: this.translateService.instant('dialog-playlist-recurrence.no')
                }
              },
              result => {
                if (result) {
                  this.dialogRef.close([this.data.contentDay, this.listDay, this.isUnlimited, this.data?.screen]);
                }
              }
            );
            return;
          }
        }
      }
      this.dialogRef.close([this.data.contentDay, this.listDay, this.isUnlimited, this.data?.screen]);
    }
  }

  /**
   * validate duplicate schedule
   *
   * @returns
   */
  private validateDuplicateSchedule(): boolean {
    // content day no data
    if (!this.data.contentDay.isRepeated) {
      return this.validateDuplicateScheduleByContentDay(this.data.contentDay);
    } else {
      // validate every day
      if (this.data.contentDay.repeatMode == RepeatModeEnum.EVERY_DAY) {
        return this.validateDuplicateEveryDay(this.data.contentDay);
      }
      return this.validateDuplicateEveryWeek(this.data.contentDay);
    }
  }

  /**
   * validate duplicate schedule every day
   * @param contentDay
   */
  private validateDuplicateEveryDay(contentDay: ContentDay): boolean {
    const indexStart = this.oldCalendars.findIndex(item => item.fullDate.getTime() == contentDay.startDate.getTime());
    // TIMETABLE
    // check validate content day at index start
    this.oldCalendars[indexStart].timetable = _.cloneDeep(contentDay.timetable);
    if (!this.validateDuplicateScheduleByContentDay(this.oldCalendars[indexStart])) {
      return false;
    }
    // check validate content day at index start + 1
    this.oldCalendars[indexStart + 1].timetable = _.cloneDeep(contentDay.timetable);
    if (!this.validateDuplicateScheduleByContentDay(this.oldCalendars[indexStart + 1])) {
      return false;
    }
    // check validate content day at index end
    const indexEnd = this.oldCalendars.findIndex(item => item.fullDate.getTime() == contentDay.finishDate.getTime());
    this.oldCalendars[indexEnd].timetable = _.cloneDeep(contentDay.timetable);
    return this.validateDuplicateScheduleByContentDay(this.oldCalendars[indexEnd]);
    // SCHEDULE REGISTRATION
  }

  /**
   * validate duplicate schedule every week
   * @param contentDay
   */
  private validateDuplicateEveryWeek(contentDay: ContentDay): boolean {
    let days = this.listDay?.filter(day => day[1])?.map(item => item[3]);
    const indexStart = this.oldCalendars.findIndex(item => item.fullDate.getTime() == contentDay.startDate.getTime());
    const indexEnd = this.oldCalendars.findIndex(item => item.fullDate.getTime() == contentDay.finishDate.getTime());

    let isDuplicatedSchedule = false;
    for (let i = indexStart; i <= indexEnd; i++) {
      if (days.includes(this.oldCalendars[i].day)) {
        this.oldCalendars[i].timetable = _.cloneDeep(contentDay.timetable);
        isDuplicatedSchedule = !this.validateDuplicateScheduleByContentDay(this.oldCalendars[i]);
        if (isDuplicatedSchedule) {
          break;
        }
      }
    }
    return !isDuplicatedSchedule;
  }

  /**
   * validate duplicate schedule by content day
   *
   * @param contentDay
   * @returns
   */
  private validateDuplicateScheduleByContentDay(contentDay: ContentDay): boolean {
    // get start time of schedule (current day)
    const startTime = this.getTimeScheduleToCompare(contentDay, true);
    if (startTime == null) {
      return true;
    }
    const index = this.oldCalendars.findIndex(day => day.fullDate.getTime() == contentDay.fullDate.getTime());
    if (index == -1) {
      return true;
    }
    const checkValidatePreviousDay = this.checkDuplicateDataBetweenTwoDays(startTime, contentDay, index, -1);
    const checkValidateNextDay = this.checkDuplicateDataBetweenTwoDays(startTime, contentDay, index, 1);
    const dateOfContentDay = this.oldCalendars[index - 1].fullDate;
    const fullDate = Helper.getDateByDay(dateOfContentDay.getFullYear(), dateOfContentDay.getMonth() + 1, dateOfContentDay.getDate());
    const currentDate = Helper.getDateByDay(new Date().getFullYear(), new Date().getMonth() + 1, new Date().getDate());
    return fullDate.getTime() < currentDate.getTime() ? checkValidateNextDay : checkValidateNextDay && checkValidatePreviousDay;
  }

  /**
   * check duplicate data between two days
   * @param startTime
   * @param contentDay
   * @param index
   * @param offsetDay
   * @returns
   */
  private checkDuplicateDataBetweenTwoDays(startTime: number, contentDay: ContentDay, index: number, offsetDay: number): boolean {
    if (this.data.screen == ScreenNameEnum.TIME_TABLE && !this.oldCalendars[index + offsetDay]?.timetable) {
      return true;
    }
    let maxTime = null;
    if (offsetDay == -1) {
      maxTime = this.getTimeScheduleToCompare(this.oldCalendars[index - 1]);
    } else {
      startTime = this.getTimeScheduleToCompare(this.oldCalendars[index + 1], true);
      maxTime = this.getTimeScheduleToCompare(contentDay);
    }
    if (maxTime == null || startTime == null) {
      return true;
    }
    // if time limit is over => return
    return startTime + Constant.SECONDS_OF_ONE_DAY > maxTime;
  }

  /**
   * get time schedule to compare
   *
   * @param contentDay
   * @param isMinTime
   * @returns
   */
  private getTimeScheduleToCompare(contentDay: ContentDay, isMinTime?: boolean): number {
    let times: number[] = [];
    let timetableSchedule: TimetableSchedule = null;
    let index = 0;
    index = this.timetables.findIndex(timetable => timetable.id == contentDay.timetable?.id);
    timetableSchedule = this.timetables[index]?.timetableSchedule;
    if (!timetableSchedule || timetableSchedule.headers[0] == null) {
      return null;
    }
    if (timetableSchedule.itemDetails?.some(itemDetail => itemDetail.isInValidFormat)) {
      return null;
    }
    // get times by seconds
    times = Helper.convertTimesToSeconds(timetableSchedule.itemDetails?.map(detail => detail.items[0]));
    if (!times?.length) {
      return null;
    }
    times = times.sort(function(number1, number2) {
      return number1 - number2;
    });
    // return time (seconds)
    return isMinTime ? times[0] : times[times.length - 1];
  }

  /**
   * change timetable
   * @param {number} timetableId id of selected timetable
   */
  public changeTimetable(timetableId: number): void {
    this.data.contentDay.timetable = this.timetables.find(timetable => timetable.id == timetableId);
    this.data.contentDay.timetableId = timetableId;
  }

  /**
   * handle error
   */
  private handleError(): void {
    this.dialogService.showDialog(DialogMessageComponent, {
      data: {
        title: this.translateService.instant('dialog-error.title'),
        text: this.translateService.instant('dialog-error.msg')
      }
    });
  }
  /**
   * Change validity period
   *
   * @param isValidityPeriod
   */
  public changeValidityPeriod(isValidityPeriod: boolean): void {
    if (this.data.contentDay.repeatMode == RepeatModeEnum.EVERY_WEEK) {
      return;
    }
    this.isUnlimited = isValidityPeriod;
  }

  /**
   * change schedules registration
   * @param {number} schedule id of selected playlist
   */
  public changeScheduleResigstration(scheduleId: number): void {
    this.data.contentDay.scheduleRegistration = this.timetablesScheduleRegistration.find(schedule => schedule.id == scheduleId);
    this.data.contentDay.scheduleRegistrationId = scheduleId;
  }

  /**
   * validate for Schedule Registration screen
   *
   * @returns
   */
  private async validateScheduleRegistration(): Promise<boolean> {
    if (!this.data.contentDay.scheduleRegistration) {
      return true;
    }
    // validate schedule include time invalid
    if (this.data.contentDay.scheduleRegistration.schedules?.some(itemDetail => itemDetail?.inValidRow)) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-error.title'),
          text: this.translateService.instant('dialog-playlist-recurrence.time-invalid')
        }
      });
      this.dialogRef.close(false);
      return false;
    }
    // check network
    let errorText = await this.commonService
      .checkNetWorkBeforeSave()
      .toPromise()
      .catch(error => {
        if (error.status == Constant.NETWORK_ERROR_CODE) {
          return Constant.NETWORK_ERROR_CODE;
        }
      });
    if (errorText == Constant.NETWORK_ERROR_CODE) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-error.title'),
          text: this.translateService.instant('dialog-error.error-network-api')
        }
      });
      return false;
    }
    return true;
  }

  /**
   * validate duplicate schedule registration
   *
   * @returns
   */
  private validateDuplicateScheduleRegistration(): boolean {
    // content day no data
    if (!this.data.contentDay.isRepeated) {
      return this.validateDuplicateScheduleRegistrationByContentDay(this.data.contentDay);
    } else {
      // validate every day
      if (this.data.contentDay.repeatMode == RepeatModeEnum.EVERY_DAY) {
        return this.validateDuplicateScheduleRegistrationEveryDay(this.data.contentDay);
      }
      return this.validateDuplicateScheduleRegistrationEveryWeek(this.data.contentDay);
    }
  }

  /**
   * validate duplicate schedule registration by content day
   *
   * @param contentDay
   * @returns
   */
  private validateDuplicateScheduleRegistrationByContentDay(contentDay: ContentDay): boolean {
    // get start time of schedule (current day)
    const startTime = this.getTimeScheduleRegistrationToCompare(contentDay, true);
    if (startTime == null) {
      return true;
    }
    const index = this.oldCalendars.findIndex(day => day.fullDate.getTime() == contentDay.fullDate.getTime());
    if (index == -1) {
      return true;
    }
    const checkValidatePreviousDay = this.checkDuplicateDataScheduleRegistrationBetweenTwoDays(startTime, contentDay, index, -1);
    const checkValidateNextDay = this.checkDuplicateDataScheduleRegistrationBetweenTwoDays(startTime, contentDay, index, 1);
    const dateOfContentDay = this.oldCalendars[index - 1].fullDate;
    const fullDate = Helper.getDateByDay(dateOfContentDay.getFullYear(), dateOfContentDay.getMonth() + 1, dateOfContentDay.getDate());
    const currentDate = Helper.getDateByDay(new Date().getFullYear(), new Date().getMonth() + 1, new Date().getDate());
    return fullDate.getTime() < currentDate.getTime() ? checkValidateNextDay : checkValidateNextDay && checkValidatePreviousDay;
  }

  /**
   * validate duplicate schedule registration every day
   * @param contentDay
   */
  private validateDuplicateScheduleRegistrationEveryDay(contentDay: ContentDay): boolean {
    const indexStart = this.oldCalendars.findIndex(item => item.fullDate.getTime() == contentDay.startDate.getTime());
    // SCHEDULE REGiSTRATION
    // check validate content day at index start
    this.oldCalendars[indexStart].scheduleRegistration = _.cloneDeep(contentDay.scheduleRegistration);
    if (!this.validateDuplicateScheduleRegistrationByContentDay(this.oldCalendars[indexStart])) {
      return false;
    }
    // check validate content day at index start + 1
    this.oldCalendars[indexStart + 1].scheduleRegistration = _.cloneDeep(contentDay.scheduleRegistration);
    if (!this.validateDuplicateScheduleRegistrationByContentDay(this.oldCalendars[indexStart + 1])) {
      return false;
    }
    // check validate content day at index end
    const indexEnd = this.oldCalendars.findIndex(item => item.fullDate.getTime() == contentDay.finishDate.getTime());
    this.oldCalendars[indexEnd].scheduleRegistration = _.cloneDeep(contentDay.scheduleRegistration);
    return this.validateDuplicateScheduleRegistrationByContentDay(this.oldCalendars[indexEnd]);
  }

  /**
   * validate duplicate schedule every week
   * @param contentDay
   */
  private validateDuplicateScheduleRegistrationEveryWeek(contentDay: ContentDay): boolean {
    let days = this.listDay?.filter(day => day[1])?.map(item => item[3]);
    const indexStart = this.oldCalendars.findIndex(item => item.fullDate.getTime() == contentDay.startDate.getTime());
    const indexEnd = this.oldCalendars.findIndex(item => item.fullDate.getTime() == contentDay.finishDate.getTime());

    let isDuplicatedSchedule = false;
    for (let i = indexStart; i <= indexEnd; i++) {
      if (days.includes(this.oldCalendars[i].day)) {
        this.oldCalendars[i].scheduleRegistration = _.cloneDeep(contentDay.scheduleRegistration);
        isDuplicatedSchedule = !this.validateDuplicateScheduleRegistrationByContentDay(this.oldCalendars[i]);
        if (isDuplicatedSchedule) {
          break;
        }
      }
    }
    return !isDuplicatedSchedule;
  }

  /**
   * get time schedule to compare
   *
   * @param contentDay
   * @param isMinTime
   * @returns
   */
  private getTimeScheduleRegistrationToCompare(contentDay: ContentDay, isMinTime?: boolean): number {
    let times: number[] = [];
    // let scheduleRegistration: ScheduleRegistration = null;
    let index = 0;
    index = this.timetablesScheduleRegistration.findIndex(timetable => timetable.id == contentDay.scheduleRegistration?.id);
    if (!this.timetablesScheduleRegistration[index]?.schedules?.length) {
      return null;
    }
    // get times by seconds
    times = Helper.convertTimesToSeconds(this.timetablesScheduleRegistration[index].schedules?.map(detail => detail.columnData[0]));
    if (!times?.length) {
      return null;
    }
    times = times.sort(function(number1, number2) {
      return number1 - number2;
    });
    // return time (seconds)
    return isMinTime ? times[0] : times[times.length - 1];
  }

  /**
   * check duplicate data between two days
   * @param startTime
   * @param contentDay
   * @param index
   * @param offsetDay
   * @returns
   */
  private checkDuplicateDataScheduleRegistrationBetweenTwoDays(
    startTime: number,
    contentDay: ContentDay,
    index: number,
    offsetDay: number
  ): boolean {
    if (this.data.screen == ScreenNameEnum.SCHEDULE_REGISTRATION && !this.oldCalendars[index + offsetDay]?.scheduleRegistration) {
      return true;
    }
    let maxTime = null;
    if (offsetDay == -1) {
      maxTime = this.getTimeScheduleRegistrationToCompare(this.oldCalendars[index - 1]);
    } else {
      startTime = this.getTimeScheduleRegistrationToCompare(this.oldCalendars[index + 1], true);
      maxTime = this.getTimeScheduleRegistrationToCompare(contentDay);
    }
    if (maxTime == null || startTime == null) {
      return true;
    }
    // if time limit is over => return
    return startTime + Constant.SECONDS_OF_ONE_DAY > maxTime;
  }
}

/**
 * interface DialogData
 */
export interface DialogData {
  contentDay: ContentDay;
  startDate: Date;
  channel: Channel;
  playlist: DailySchedule;
  isChangeDailySchedule: boolean;
  device: DeviceCalendar;
  calendars: Array<ContentDay>;
  screen: string;
  isEdit: boolean;
  timeDateLine: string;
}
