import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Constant } from 'app/config/constants';
import { PublishSetting } from 'app/model/entity/publish-setting';
import { DialogService } from 'app/service/dialog.service';
import { PublishTimetableService } from 'app/service/publish-timetable.service';
import { DialogMessageComponent } from '../dialog-message/dialog-message.component';
import * as fileSaver from 'file-saver';
@Component({
  selector: 'dialog-download-data',
  templateUrl: './dialog-download-data.component.html',
  styleUrls: ['./dialog-download-data.component.scss']
})
export class DialogDownloadDataComponent implements OnInit {
  /**
   * loading
   */
  loading: boolean;

  constructor(
    public dialogRef: MatDialogRef<DialogDownloadDataComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public dialogService: DialogService,
    private publishTimetableService: PublishTimetableService,
    private translateService: TranslateService
  ) {
    this.loading = true;
  }

  ngOnInit() {
    this.handleDownloadData();
  }

  /**
   * handle Download Data
   */
  private handleDownloadData(): void {
    if (!this.data.isSimple) {
      this.publishTimetableService.downloadDataPublishTimetable(this.data.dataSettings, this.data.timeDateLine).subscribe(
        data => {
          const fileNameResponse = data.headers.get('content-disposition');
          const file = new File([data.body], fileNameResponse);
          fileSaver.saveAs(file);
          this.publishTimetableService.deleteFileZipAfterDownload(fileNameResponse).toPromise();
          this.loading = false;
          this.dialogRef.close();
        },
        error => {
          this.dialogRef.close();
          this.dialogService.showDialog(DialogMessageComponent, {
            data: {
              title: this.translateService.instant('dialog-error.title'),
              text:
                error.status == Constant.NETWORK_ERROR_CODE
                  ? this.translateService.instant('dialog-error.error-network')
                  : this.translateService.instant('dialog-delivery-timetable.download-publish-failed')
            }
          });
        }
      );
    } else {
      this.publishTimetableService
        .downloadDataPublishSimpleSignage(
          this.data.dataSettings,
          this.data.isSimpleSyncSetting,
          this.data.isCheckedExpiration,
          this.data.isCheckedPlaybackTime
        )
        .subscribe(
          data => {
            const fileNameResponse = data.headers.get('content-disposition');
            const file = new File([data.body], fileNameResponse);
            fileSaver.saveAs(file);
            this.publishTimetableService.deleteFileZipAfterDownload(fileNameResponse).toPromise();
            this.loading = false;
            this.dialogRef.close();
          },
          error => {
            this.dialogRef.close();
            this.dialogService.showDialog(DialogMessageComponent, {
              data: {
                title: this.translateService.instant('dialog-error.title'),
                text:
                  error.status == Constant.NETWORK_ERROR_CODE
                    ? this.translateService.instant('dialog-error.error-network')
                    : this.translateService.instant('dialog-delivery-simple.download-publish-failed')
              }
            });
          }
        );
    }
  }
}

/**
 * export dialog data
 */
export interface DialogData {
  dataSettings: PublishSetting[];
  timeDateLine: any;
  isSimple: boolean;
  isSimpleSyncSetting: boolean;
  isCheckedExpiration: boolean;
  isCheckedPlaybackTime: boolean;
  isMultipleTimetables: boolean;
}
