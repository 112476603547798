import { CdkDrag, CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ScheduleOperationManagerService } from 'app/service/schedule-operation-maganer.service';

@Component({
  selector: 'app-dialog-show-hidden-item-setting',
  templateUrl: './dialog-show-hidden-item-setting.component.html',
  styleUrls: ['./dialog-show-hidden-item-setting.component.scss']
})
/**
 * Dialog Pick color
 */
export class DialogShowHiddenItemSettingSettingComponent implements OnInit {
  listData: any[] = [];
  dataApi: any[];
  headerLabelsFromAPI: any[];
  constructor(
    private scheduleOperationManagerService: ScheduleOperationManagerService,
    public dialogRef: MatDialogRef<DialogShowHiddenItemSettingSettingComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}

  ngOnInit() {
    this.initData();
  }
  checkDataS3WithDB() {
    const headers = [];
    const headerRows = JSON.parse(JSON.stringify(this.data[0]));
    const listReject = [
      'calculatedTime',
      'hold',
      'priority',
      'suspension',
      'color',
      'index',
      'key',
      'itemEdit',
      'checkTime',
      'notIn35Min',
      'blink',
      'isDelete',
      'isDeleteRowInsert',
      'isEdit',
      'isEditHold',
      'isEditPriority',
      'isEditSuspension'
    ];
    let index = 0;
    if (!this.data || !this.data[0]) {
      return;
    }
    for (const header in headerRows) {
      const re = listReject.findIndex(e => e == header);
      if (re == -1) {
        const i = headers.findIndex(e => e.field == header);
        if (i == -1) {
          let label = header;
          if (this.headerLabelsFromAPI) {
            label = this.headerLabelsFromAPI[headers.length];
          }
          headers.push({ label: label, field: header, isActive: true, sortOrder: index++ });
          for (const dataTmpApi of this.dataApi) {
            if (dataTmpApi.field == header && dataTmpApi.label == label) {
              headers[headers.length - 1].checkApi = true;
              headers[headers.length - 1].sortOrder = dataTmpApi.sortOrder;
              headers[headers.length - 1].isActive = dataTmpApi.isActive;
            }
          }
        }
      }
    }
    let checkApi = true;
    if (this.dataApi.length != headers.length) {
      checkApi = false;
    }
    for (const header of headers) {
      if (!header.checkApi) {
        //convert sort order. call api save item;
        checkApi = false;
      }
    }
    if (!checkApi) {
      for (const header of headers) {
        header.isActive = true;
      }
      this.onSaveFirst(headers);
    }
    if (checkApi) {
      headers.sort((a, b) => (a.sortOrder > b.sortOrder ? 1 : -1));
    }
    this.listData = headers;
  }

  initData() {
    this.headerLabelsFromAPI = this.scheduleOperationManagerService.listScheduleOperationFromAPI.headers
      ? JSON.parse(this.scheduleOperationManagerService.listScheduleOperationFromAPI.headers)
      : null;
    this.scheduleOperationManagerService.getShowHiddenItem().subscribe(
      e => {
        this.dataApi = e;
        if (this.dataApi && this.dataApi.length > 0) {
          this.checkDataS3WithDB();
        } else {
          this.addHeader();
        }
      },
      err => {
        this.addHeader();
      }
    );

    //load from service.
    //if data not found in BE
    // if (this.scheduleOperationManagerService.headerScheduleOperations) {
    //   this.listData = this.scheduleOperationManagerService.headerScheduleOperations;
    // } else {
    //   this.addHeader();
    // }
  }
  drop(event: CdkDragDrop<string[]>) {
    if (event.currentIndex == 0) {
      return;
    }
    moveItemInArray(this.listData, event.previousIndex, event.currentIndex);
  }
  onSaveFirst(headers) {
    for (const [index, value] of headers.entries()) {
      value.sortOrder = index;
      value.isActive = true;
      delete value.checkApi;
    }
    this.scheduleOperationManagerService.saveShowHiddenItem(JSON.stringify(headers)).subscribe(e => {});
  }
  onSave() {
    for (const [index, value] of this.listData.entries()) {
      value.sortOrder = index;
      delete value.checkApi;
    }
    this.scheduleOperationManagerService.headerScheduleOperations = this.listData;
    //save data to database data change.
    this.scheduleOperationManagerService.saveShowHiddenItem(JSON.stringify(this.listData)).subscribe(e => {
      this.dialogRef.close(this.listData);
    });
  }

  addHeader() {
    const headers = [];
    const listReject = [
      'calculatedTime',
      'hold',
      'priority',
      'suspension',
      'color',
      'index',
      'key',
      'itemEdit',
      'checkTime',
      'notIn35Min',
      'blink',
      'isDelete',
      'isDeleteRowInsert',
      'isEdit',
      'isEditHold',
      'isEditPriority',
      'isEditSuspension'
    ];
    let index = 0;
    if (!this.data || !this.data[0]) {
      return;
    }
    const headerRows = JSON.parse(JSON.stringify(this.data[0]));
    for (const header in headerRows) {
      const re = listReject.findIndex(e => e == header);
      if (re == -1) {
        const i = headers.findIndex(e => e.field == header);
        if (i == -1) {
          let label = header;
          if (this.headerLabelsFromAPI) {
            label = this.headerLabelsFromAPI[headers.length];
          }
          headers.push({ label: label, field: header, isActive: true, sortOrder: index++ });
        }
      }
    }
    this.listData = headers;
    this.onSaveFirst(headers);
  }
}

/**
 * Dialog data
 */
export interface DialogData {
  title: string;
  text: string;
  texts: Array<string>;
}
