import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { DialogService } from 'app/service/dialog.service';
import { Timetable } from 'app/model/entity/schedule/timetable';
import { ScheduleMergeService } from 'app/service/schedule-merge.service';
import { Helper } from 'app/common/helper';
import { Constant, FIELD_COMPONENT, MODULE_NAME, PreviewToolEnum, SortTypeEnum, SettingType } from 'app/config/constants';
@Component({
  selector: 'dialog-create-daily-schedule',
  templateUrl: './dialog-create-daily-schedule.component.html',
  styleUrls: ['./dialog-create-daily-schedule.component.scss']
})
export class DialogCreateDailyScheduleComponent implements OnInit {
  /**
   * loading
   */
  loading: boolean;

  constructor(
    public dialogRef: MatDialogRef<DialogCreateDailyScheduleComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public dialogService: DialogService,
    private scheduleMergeService: ScheduleMergeService,
    private translateService: TranslateService
  ) {
    this.loading = true;
  }

  ngOnInit() {
    this.handleCreateData();
  }

  /**
   * handle Download Data
   */
  private handleCreateData(): void {
    if (!this.data.timetableSelected) {
      return;
    }
    let timetablesDaily;
    this.scheduleMergeService.createDailySchedule(this.data.timetableSelected.id).subscribe(data => {
      timetablesDaily = Helper.convertTimetableDaily(data, this.data.timeDateLine);
      this.setInvalidToTimetablesDaily(timetablesDaily);
      this.loading = false;
      this.dialogRef.close(timetablesDaily);
    });
  }
  /**
   * setInvalidToTimetablesDaily
   */
  private setInvalidToTimetablesDaily(timetablesDaily: any) {
    timetablesDaily.forEach(timetable => {
      const times = timetable?.schedules?.map(schedule => schedule.columnsData[0]);
      const timesTeml = [];
      const timesInvalid = [];
      if (times && times.length > 0) {
        for (const time of times) {
          const index = timesTeml.findIndex(e => e == time);
          if (index != -1) {
            timesInvalid.push(time);
          } else {
            timesTeml.push(time);
          }
        }
      }
      timetable?.schedules?.forEach(schedule => {
        if (timesInvalid.length && timesInvalid.includes(schedule.columnsData[0])) {
          const schedulesTempl = timetable.schedules.filter(
            data =>
              data.columnsData[0] == schedule.columnsData[0] &&
              data.columnsData[6].trim() != Constant.SUSPENSION_JP &&
              data.columnsData[6].trim().toLowerCase() != Constant.SUSPENSION_EN
          );
          if (schedulesTempl.length > 1) {
            schedulesTempl.forEach(item => {
              item.inValidRowDulicate = true;
              timetable.inValidRow = true;
            });
          }
        }
      });
    });
  }
}

/**
 * export dialog data
 */
export interface DialogData {
  timetableSelected: Timetable;
  timeDateLine: any;
}
