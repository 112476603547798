import { Component, Inject, OnInit } from '@angular/core';
import { TextHighlightingSetting } from './../../model/entity/text-highlighting-setting';
import { TextHighlightSettingService } from './../../service/text-highlight-setting-service';
import { Helper } from 'app/common/helper';
import { ScheduleMergeService } from 'app/service/schedule-merge.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogService } from 'app/service/dialog.service';
import { DialogConfirmComponent } from '../dialog-confirm/dialog-confirm.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-dialog-text-highlighting-setting',
  templateUrl: './dialog-text-highlighting-setting.component.html',
  styleUrls: ['./dialog-text-highlighting-setting.component.scss']
})
/**
 * Dialog Pick color
 */
export class DialogTextHighlightingSettingComponent implements OnInit {
  fontColor: any = '#000000';
  listData: TextHighlightingSetting[] = [
    {
      id: 1,
      titleName: 'highlight-delay',
      name: 'Delay',
      blinking: false,
      object: 'row',
      color: '#c30c22',
      isActive: false,
      readOnlyName: true,
      opacity: 1
    },
    {
      id: 2,
      titleName: 'highlight-priority',
      name: 'Priority',
      blinking: true,
      object: 'time',
      color: '#8cc318',
      isActive: false,
      readOnlyName: true,
      opacity: 1
    },
    {
      id: 3,
      titleName: 'highlight-1',
      name: '空港線用',
      blinking: true,
      object: 'row',
      color: '#00a0e9',
      isActive: false,
      readOnlyName: false,
      opacity: 1
    },
    {
      id: 4,
      titleName: 'highlight-2',
      name: '臨時便用',
      blinking: true,
      object: 'row',
      color: '#faee00',
      isActive: false,
      readOnlyName: false,
      opacity: 1
    },
    {
      id: 5,
      titleName: 'highlight-3',
      name: '運休用',
      blinking: false,
      object: 'row',
      color: '#ea5514',
      isActive: false,
      readOnlyName: false,
      opacity: 1
    }
  ];
  listDataSave: TextHighlightingSetting[] = [];
  constructor(
    public dialogRef: MatDialogRef<DialogTextHighlightingSettingComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private scheduleMergeService: ScheduleMergeService,
    private dialogService: DialogService,
    private translateService: TranslateService,
    private textHighlightSettingService: TextHighlightSettingService
  ) {}

  ngOnInit() {
    this.textHighlightSettingService.getAllTextHighLightSetting().subscribe(data => {
      if (!data || !data.length) {
        let listSave = this.listData.map(dataSave => Helper.convertTextHighlightToDB(dataSave));
        this.scheduleMergeService.saveTextHighlight(listSave).subscribe();
        return;
      }
      this.listData = data.map(d => Helper.convertTextHighlightFromDB(d));
    });
  }

  onChangeData(data) {
    const i = this.listData.findIndex(element => element.titleName == data.titleName);
    // if (i == -1) {
    //   this.listDataSave.push(data);
    // } else {
    this.listData[i] = data;
    // }
  }

  onSave() {
    if (!this.checkBlinkingOpacity()) {
      this.dialogService.showDialog(
        DialogConfirmComponent,
        {
          data: {
            text: this.translateService.instant(`dialog-text-highlighting-setting.confirmConflictBlinkingOpacity`),
            button1: this.translateService.instant('timetable-editor.yes'),
            button2: this.translateService.instant('timetable-editor.btn-no')
          }
        },
        result => {
          if (!result) {
            return;
          } else {
            //convert blinking to OFF
            this.convertOffBlinking();
            let listSave = this.listData.map(dataSave => Helper.convertTextHighlightToDB(dataSave));
            this.scheduleMergeService.saveTextHighlight(listSave).subscribe(e => {
              this.dialogRef.close(<Array<TextHighlightingSetting>>e);
            });
          }
        }
      );
    } else {
      let listSave = this.listData.map(dataSave => Helper.convertTextHighlightToDB(dataSave));
      this.scheduleMergeService.saveTextHighlight(listSave).subscribe(e => {
        this.dialogRef.close(<Array<TextHighlightingSetting>>e);
      });
    }
  }
  /**
   * Check Blinking Opacity
   */
  checkBlinkingOpacity(): Boolean {
    let isNotConflictBlinkingOpacity = true;
    for (const data of this.listData) {
      if (data.blinking && data.opacity != 1) {
        isNotConflictBlinkingOpacity = false;
      }
    }
    return isNotConflictBlinkingOpacity;
  }
  convertOffBlinking() {
    for (const data of this.listData) {
      if (data.blinking && data.opacity != 1) {
        data.blinking = false;
      }
    }
  }
}

/**
 * Dialog data
 */
export interface DialogData {
  title: string;
  text: string;
  texts: Array<string>;
}
