import { Component, Inject, OnInit } from '@angular/core';
import { CustomTag } from '../../model/entity/custom-tag';
import { CustomTagService } from '../../service/custom-tag.service';
import { DialogEditGroupComponent } from '../dialog-edit-group/dialog-edit-group.component';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogService } from '../../service/dialog.service';
import { DialogMessageComponent } from '../dialog-message/dialog-message.component';
import { Constant } from 'app/config/constants';
import { Device } from 'app/model/entity/device';
import { APICustomerService } from 'app/service/api-customer.service';
import { Helper } from 'app/common/helper';
import { DialogSimpleSignageMessageComponent } from '../dialog-simple-signage-message/dialog-simple-signage-message.component';

@Component({
  selector: 'app-dialog-select-group',
  templateUrl: './dialog-select-group.component.html',
  styleUrls: ['./dialog-select-group.component.scss']
})
export class DialogSelectGroupComponent implements OnInit {
  /**
   * list custom tag
   */
  listCustomTag: Array<CustomTag> = new Array<CustomTag>();
  /**
   * custom tag selected
   */
  customTagSelected: CustomTag;
  /**
   * true if selected custom tag
   */
  isShowElement: boolean;
  /**
   * element selected
   */
  elementSelected: string;
  /**
   * devices selected
   */
  private devicesSelected: Array<Device>;
  /**
   * deviceIds
   */
  private deviceIds: Array<Number> = new Array<Number>();
  /**
   * errors
   */
  private errors: Array<any> = new Array<any>();
  /**
   * group id
   */
  private readonly GROUP_ID = 'groupId';

  constructor(
    private customTagSevice: CustomTagService,
    private dialogRef: MatDialogRef<DialogEditGroupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private dialogService: DialogService,
    private apiCustomerService: APICustomerService
  ) {}

  ngOnInit(): void {
    this.fetchGroupDeviceData();
    this.isShowElement = false;
    this.devicesSelected = this.data.devicesSelected;
  }

  /**
   * fetch group data
   */
  private fetchGroupDeviceData(): void {
    this.customTagSevice.getCustomTag().subscribe(
      data => {
        this.listCustomTag = data;
      },
      error => {
        this.dialogRef.close();
        this.dialogService.showDialog(DialogMessageComponent, {
          data: {
            title: `Error`,
            text: `An error has occurred. Please try again.`
          }
        });
      }
    );
  }

  /**
   * show element
   */
  public showElement(): void {
    if (this.customTagSelected) {
      this.isShowElement = true;
    } else {
      this.isShowElement = false;
    }
  }

  /**
   * save selected custom tag
   */
  public async save(): Promise<void> {
    if (!this.customTagSelected) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: `Error`,
          text: `Please select option.`
        }
      });
      return;
    }

    if (this.customTagSelected.name != Constant.SIGNAGE_GROUP) {
      this.dialogRef.close([this.customTagSelected, this.elementSelected, this.devicesSelected.map(device => device.id)]);
      return;
    }
    for (let i = 0; i < this.devicesSelected.length; i++) {
      let device = this.devicesSelected[i];
      // device has a custom tag setting
      if (device.customTag0) {
        await this.groupDeviceRemove(device);
        // add new element for device
      } else if (this.elementSelected) {
        await this.groupDeviceAdd(device);
      }
    }

    if (this.errors.length) {
      this.dialogService.showDialog(DialogSimpleSignageMessageComponent, {
        data: {
          title: 'Error',
          texts: this.errors
        }
      });
    }
    this.dialogRef.close([this.customTagSelected, this.elementSelected, this.deviceIds]);
  }

  /**
   * call API group_device_add
   * @param device
   * @returns
   */
  private async groupDeviceAdd(device: Device): Promise<void> {
    return new Promise<void>(resolve => {
      this.apiCustomerService
        .groupDeviceAdd(Helper.createPayloadGroupDevice(this.elementSelected[this.GROUP_ID], [device.registrationId]))
        .toPromise()
        .then(
          () => {
            this.deviceIds.push(device.id);
            resolve();
          },
          () => {
            this.errors.push(`${device.registrationId}: ${'Group device add failed.'}`);
            resolve();
          }
        );
    });
  }

  /**
   * call API group_device_remove
   * @param payloadRemove
   * @param device
   * @param payloadDeviceAdd
   * @returns
   */
  private async groupDeviceRemove(device: Device): Promise<void> {
    let payloadRemove = Helper.createPayloadGroupDevice(device.customTag0.groupId, [device.registrationId]);
    return new Promise<void>(resolve => {
      if (this.elementSelected && this.elementSelected['id'] == device.customTag0.id) {
        resolve();
        return;
      }
      this.apiCustomerService
        .groupDeviceRemove(payloadRemove)
        .toPromise()
        .then(
          () => {
            // change element to another element
            if (this.elementSelected && this.elementSelected['id'] != device.customTag0.id) {
              let payloadDeviceAdd = Helper.createPayloadGroupDevice(this.elementSelected[this.GROUP_ID], [device.registrationId]);
              this.apiCustomerService.groupDeviceAdd(payloadDeviceAdd).subscribe(
                () => {
                  this.deviceIds.push(device.id);
                  resolve();
                },
                () => {
                  this.apiCustomerService.groupDeviceAdd(payloadRemove).toPromise();
                  this.errors.push(`${device.registrationId}: ${'Group device add failed.'}`);
                  resolve();
                }
              );
            } else {
              this.deviceIds.push(device.id);
              resolve();
            }
          },
          () => {
            this.errors.push(`${device.registrationId}: ${'Group device remove failed.'}`);
            resolve();
          }
        );
    });
  }
}
/**
 * export dialog data
 */
export interface DialogData {
  devicesSelected: Array<Device>;
}
